import axios from 'axios';
import { useCallback } from 'react';
import { useState } from 'react';

const useCancelToken = () => {
  const [, setCancelToken] = useState(null);

  const getCancelToken = useCallback(() => {
    const token = axios.CancelToken.source();
    setCancelToken((prev) => {
      if (prev) prev.cancel();
      return token;
    })
    return token.token;
  }, []);

  return ({ getCancelToken });
}

export default useCancelToken;