import React from "react";

const TBody = React.forwardRef(({ className = "min-w-full divide-y divide-gray-200", children, style, isLoading = false, props}, ref) => (
  <tbody
    ref={ref}
    style={style}
    className={`${isLoading ? 'opacity-50' : ''} ${className}`}
    {...props}
  >
    {children}
  </tbody>
));

export default TBody