import {
  React, Fragment, PureComponent,
} from 'react';
import _ from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withAPI from '../../hocs/with-api';
import ValidationError from '../notifications/validation-errors';

class ModalDeleteItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errors: {},
    };
  }

  onDelete = async () => {
    const {
      httpRequest, history, setOpen, itemId, refreshItems, deleteURL, listName, defaultRedirectURL,
    } = this.props;

    this.setState({ isLoading: true });

    const response = await httpRequest({
      method: 'delete',
      url: `${deleteURL}/${itemId}`,
    }).catch((error) => {
      const customErrors = _.get(error, 'response.data.data', {});
      if (!_.isEmpty(customErrors)) {
        this.setState({
          errors: customErrors,
          isLoading: false,
        });
        return;
      }
      this.setState({
        errors: error.response.data,
        isLoading: false,
      });
    });
    if (response) {
      await refreshItems();
      if (defaultRedirectURL) {
        history.push({
          pathname: defaultRedirectURL,
          flash: {
            message: `${listName} was deleted successfully`,
          },
        });
      }
      setOpen(false);
    }
  }

  render() {
    const {
      open, setOpen, listName,
    } = this.props;
    const {
      errors, isLoading,
    } = this.state;

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
              overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg
              sm:w-full sm:p-6"
              >
                <div>
                  <button type="button" className="float-right" onClick={() => setOpen(false)}>
                    <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-black-400" aria-hidden="true" />
                  </button>
                  <div className="text-left">
                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                      {`Delete ${listName || ''}`}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  {
                    !_.isEmpty(errors) && (
                      <ValidationError errors={errors} addClass="sm:col-span-6" />
                    )
                  }

                  <div className="sm:col-span-6 flex justify-start items-center">
                    <label htmlFor="name" className="block text-lg pb-2 font-medium text-gray-700">
                      {`Are you sure you want to delete this ${listName?.toLowerCase()}?`}
                    </label>
                  </div>

                  <div className="sm:col-span-6">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={this.onDelete}
                        type="button"
                        disabled={isLoading}
                        className="inline-flex justify-center py-2 px-4 mt-3 sm:mt-0 border border-transparent
                            shadow-sm text-sm font-bold rounded-md text-red-400 bg-white hover:bg-indigo-600
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {isLoading && (<div className="animate-spin border-b-2 rounded-full h-5 w-5 mr-3" />)}
                        Yes, Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

ModalDeleteItem.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  itemId: PropTypes.number.isRequired,
  listName: PropTypes.string.isRequired,
  deleteURL: PropTypes.string.isRequired,
  defaultRedirectURL: PropTypes.string,
  refreshItems: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
};

ModalDeleteItem.defaultProps = {
  open: false,
  setOpen: () => {},
  defaultRedirectURL: '',
};

export default compose(
  withAPI,
  withRouter,
)(ModalDeleteItem);
