import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const MenuSelectList = ({ children, ...other }) => {
  const isFullWidth = other?.selectProps?.selectProps?.isFullOptionWidth;

  return (
    <components.MenuList {...other}>
      <p className="px-3 pb-1 text-gray-600">Choose your options</p>
      <div className={`${isFullWidth ? 'w-full' : 'w-max'} shadow-sm`}>
        {children}
      </div>
    </components.MenuList>
  );
};

MenuSelectList.propTypes = {
  children: PropTypes.array,
};

MenuSelectList.defaultProps = {
  children: [],
};

export default MenuSelectList;
