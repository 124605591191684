import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ModalWrapper from 'components/modal/modal-wrapper';
import { ValidationsErrors } from 'components/notifications';
import { withAPI } from 'hocs';
import { FormHandlersWrapper, FormInput, FormSave, FormTextarea } from 'components/form';

const CreateEditItems = ({ httpRequest, open, setOpen, action, category, item, refreshData }) => {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (action === 'Create' && open) {
      setName('');
      setDescription('');
    } else if (action === 'Edit' && open) {
      const { name, description } = item;
      setName(name);
      setDescription(description);
    }
    setErrors({});
  }, [action, open, item])


  const onSave = async () => {

    setIsLoading(true);
    setErrors({});

    const data = {
      name,
      description,
      category_id: category?.id,
    }

    let id = "";
    if (action === 'Edit') {
      id = item?.id;
    }

    const response = await httpRequest({
      method: action === 'Create' ? 'post' : 'patch',
      url: `dvir-items/${id}`,
      data,
    })
      .catch((error) => setErrors(error?.response?.data ))
      .finally(() => setIsLoading(false));

    if (response) {
      refreshData();
      setOpen(false);
    }

  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={`${action} Items`}
      customWidth="sm:max-w-xl sm:w-full"
    >
      {!_.isEmpty(errors) && <ValidationsErrors errors={errors} />}
      <div className="flex flex-col gap-2">
        <FormInput
          label="Name"
          value={name}
          handleValue={(id, value) => setName(value)}
          placeholder="Name"
        />
        <FormTextarea
          label="Description"
          value={description}
          handleValue={setDescription}
          placeholder="Description"
        />
        <FormHandlersWrapper>
          <FormSave isLoading={isLoading} onClick={onSave} />
        </FormHandlersWrapper>
      </div>
    </ModalWrapper>
  )
}

export default _.flowRight([withAPI])(CreateEditItems);