import _ from "lodash"

const checkIsRequired = (value, field, message = '') => {
  if (typeof value === 'number') return '';
  if (_.isEmpty(value) || _.isNil(value)) {
    return message || `${field} is required.`
  }
  return '';
}

export default checkIsRequired;