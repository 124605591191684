import _ from 'lodash';
import { FormLabel } from 'modules/dispatches/modalCreateDispatch';
import React from 'react'
import AsyncSelect from 'react-select/async';
import { DVIRItemsList } from '..';
import PropTypes from 'prop-types';

const FirstStep = ({
  truckNumber,
  setTruckList,
  loadTruckList,
  setTruckNumber,
  DVIRRules,
  onSelectDVIRItem,
  passedItems,
}) => {

  const debouncedTruckList = _.debounce(async (search, callback) => {
    if (search?.length >= 3) {
      const data = await loadTruckList(search);
      const formattedData = data?.map((i) => ({ value: i, label: i }));
      return callback(formattedData)
    }
    setTruckList([]);
    return callback([]);
  }, 500)
  
  return (
    <>
      <FormLabel label="Truck number" />
      <AsyncSelect
        value={truckNumber ? ({ value: truckNumber, label: truckNumber }) : ''}
        loadOptions={debouncedTruckList}
        isClearable
        onInputChange={(e, action) => {
          if (action.action === 'menu-close' && action.prevInputValue) {
            setTruckNumber(action.prevInputValue);
          }
        }}
        onChange={(e) => setTruckNumber(e?.value || '')}
        maxMenuHeight={120}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
        block w-full sm:text-sm border-gray-300 rounded-md"
        name="truck_no"
        classNamePrefix="select"
        placeholder="Truck Number"
      />
      <DVIRItemsList DVIRItems={DVIRRules} onSelectDVIRItem={onSelectDVIRItem} passedItems={passedItems} />
    </>
  )
}

FirstStep.propTypes = {
  setTruckList: PropTypes.func.isRequired,
  loadTruckList: PropTypes.func.isRequired,
  setTruckNumber: PropTypes.func.isRequired,
  DVIRRules: PropTypes.array.isRequired,
  onSelectDVIRItem: PropTypes.func.isRequired,
  passedItems: PropTypes.array.isRequired,
  truckNumber: PropTypes.string,
}

FirstStep.defaultProps = {
  truckNumber: "",
}

export default FirstStep