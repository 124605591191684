import RadioGroup from 'components/radio-group'
import React, { useEffect } from 'react'
import { FormLabel } from './modalCreateDispatch'

const options = [
  {id: 'pass', label: 'Pass'},
  {id: 'fail', label: 'Fail'},
]

const DispatchRulePassFail = ({ name, description, onSelect, value, isRequired = false }) => {

  useEffect(() => {
    if (!value) {
      onSelect('pass')
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div className="mb-4">
      <FormLabel label={name} isRequired={isRequired} />
      {description?.length > 0 && description?.map((item) => (
        <div className="text-sm font-medium text-gray-700" key={item}>{item}</div>
      ))}
      <RadioGroup
        onChange={onSelect}
        items={options}
        className="flex flex-col gap-2"
        defaultSelected={value || "pass"}
      />
    </div>
  )
}

export default DispatchRulePassFail