import { createContext, useEffect, useState } from "react";

export const TokenContext = createContext({ isTokenLoading: false, setToken: () => {} });


export const TokenProvider = TokenContext.Provider;
export const TokenConsumer = TokenContext.Consumer;

export const TokenContextProvider = ({ children }) => {

  const setTokenState = (isTokenLoading) => {
    setState((prev) => ({ ...prev, isTokenLoading }))
  }

  const initState = {
    isTokenLoading: false,
    setTokenState,
  }
  
  
  const [state, setState] = useState(initState);
  
  useEffect(() => {
    console.log()
  }, [state.isTokenLoading])


  return (
    <TokenProvider value={state}>
      {children}
    </TokenProvider>
  )
}