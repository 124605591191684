import axios from 'axios';

const getApiClient = (token, timeout = 30000, cancelToken = null, responseType = 'json') => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout,
    headers,
    cancelToken,
    responseType,
  });
};

export default getApiClient;
