import React from 'react';
import { components } from 'react-select';
import { compose } from 'recompose';
import { PlusIcon } from '@heroicons/react/solid';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <PlusIcon className="w-5 h-5" />
  </components.DropdownIndicator>
);

export default compose(
)(DropdownIndicator);
