import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withUserInfo from '../../hocs/with-user-info';

const defaultLogoPath = '/assets/logo-company.png';

const Logo = ({ brand_logo, user }) => {
  const [imgSrc, setImgSrc] = useState(defaultLogoPath);

  const logoRef = useRef(null);
  const logoUrl = brand_logo || user?.data?.company_brand?.logo;

  useMemo(() => {
    if (logoUrl) setImgSrc(logoUrl);
  }, [logoUrl]);

  const onImageError = () => {
    logoRef.current.src = defaultLogoPath;
  }

  return (
    <div className="relative flex flex-col justify-center items-center">
      <img ref={logoRef} id="brand-logo" className="sidebar-logo" src={imgSrc} alt="logo" onError={onImageError} />
      <span className="text-sm text-center font-medium">
        <span className="italic text-xs">
          Powered by
        </span>
        {' '}
        <span className="font-bold text-green-regular">
          GroundTeq
        </span>
      </span>
    </div>
  );
};

Logo.propTypes = {
  user: PropTypes.object.isRequired,
  brand_logo: PropTypes.string,
};

Logo.defaultProps = { brand_logo: '' };

export default compose(
  withUserInfo,
)(Logo);
