import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const DVIRItemsList = ({ DVIRItems, onSelectDVIRItem, passedItems }) => {

  const passedItemsIds = useMemo(() => passedItems?.map((item) => item?.id), [passedItems]);

  const getPassedItemsCount = useCallback((items) => {
    let counter = 0;

    items?.forEach((subItem) => {
      if (passedItemsIds?.includes(subItem?.id)) counter += 1;
    })
    return counter;
  }, [passedItemsIds])

  return (
    <div className="flex flex-col gap-3 mt-3">
      {_.isArray(DVIRItems) && DVIRItems?.map((DVIRItem, index) => (
        <button
          key={DVIRItem?.id}
          type="button"
          onClick={() => onSelectDVIRItem({...DVIRItem, itemIndex: index, isLastItem: index + 1 === DVIRItems?.length})}
          className="flex p-2 justify-between border rounded-md"
        >
          <div>{DVIRItem?.name}</div>
          <div>{getPassedItemsCount(DVIRItem?.items)} / {DVIRItem?.items?.length}</div>
        </button>
      ))}
    </div>
  )
}

DVIRItemsList.propTypes = {
  DVIRItems: PropTypes.array.isRequired,
  onSelectDVIRItem: PropTypes.func.isRequired,
  passedItems: PropTypes.array.isRequired,
}

export default DVIRItemsList