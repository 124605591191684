/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import withUserInfo from '../../hocs/with-user-info';
import { checkNavPermission, hasPermission, isKeepCompanyPlan } from '../../utils';
import { navigation } from 'components/navigation';

const WithPermission = ({
  permissions,
  permissionsCheck,
  children,
  onRoute,
  companyAcceptPermissions,
  companyPermissions,
}) => {
  const intentAllowed = _.isEmpty(permissionsCheck) || hasPermission(permissionsCheck, permissions);
  const keepCompanyPlan = isKeepCompanyPlan(companyAcceptPermissions, companyPermissions)
  const filteredNavigation = navigation.filter((nav) => checkNavPermission(nav, permissions));
  const firstTabRef = _.get(filteredNavigation, '[0].href', '/home');
  if (!intentAllowed || !keepCompanyPlan) return onRoute ? <Redirect to={firstTabRef}/> : null;
  return children;
};

WithPermission.propTypes = {
  permissions: PropTypes.array.isRequired,
  permissionsCheck: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  onRoute: PropTypes.bool,
};

WithPermission.defaultProps = {
  onRoute: false,
};

export default _.flowRight([
  withUserInfo,
])(WithPermission);
