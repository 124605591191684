import React from 'react';
import moment from 'moment';
import getApiClient from '../../services/api';

function withUser(WrappedComponent) {
  return class extends React.Component {
        login = async ({ email, password }) => {
          const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET;
          const clientId = process.env.REACT_APP_PASSPORT_CLIENT_ID;
          const apiClient = getApiClient();
          return apiClient
            .post('oauth/token', {
              grant_type: 'password',
              client_id: clientId,
              client_secret: clientSecret,
              username: email,
              password,
            })
            .then((response) => {
              const { data } = response;
              return data;
            });
        };

        getProfile = async () => {
          const tokens = JSON.parse(localStorage.getItem('evl-tokens'));
          const apiClient = getApiClient(tokens.access_token);
          return apiClient.get('users/my-profile').then((response) => {
            const { data } = response;
            return data;
          });
        };

        user = () => {
          const json = localStorage.getItem('evl-profile');
          return json ? { ...JSON.parse(json).data } : {};
        };

        logout = () => {
          localStorage.removeItem('evl-profile');
          localStorage.removeItem('evl-tokens');
          localStorage.removeItem('evl-is-authenticated');
        };

        storeProfile = (profile) => localStorage.setItem('evl-profile', JSON.stringify(profile));

        storeTokens = (tokens) => localStorage.setItem(
          'evl-tokens',
          JSON.stringify({
            created_at: moment.now(),
            ...tokens,
          }),
        );

        setIsAuthenticated = (isAuthenticated) => localStorage.setItem('evl-is-authenticated', isAuthenticated);

        isUserAuthenticated = () => Boolean(localStorage.getItem('evl-is-authenticated'));

        render() {
          const rest = {
            logout: this.logout,
            login: this.login,
            storeTokens: this.storeTokens,
            getProfile: this.getProfile,
            storeProfile: this.storeProfile,
            setIsAuthenticated: this.setIsAuthenticated,
            isUserAuthenticated: this.isUserAuthenticated(),
            user: this.user(),
          };
          return <WrappedComponent {...this.props} {...rest} />;
        }
  };
}

export default withUser;
