import { MultipleSelector } from 'components/multiple-selector';
import { PageHeaderDataFilter, PageHeaderItemWrapper, PageHeaderWrapper, PageTitle, PageWrapper } from 'components/page';
import RegularDropdown from 'components/regular-dropdown';
import { TBody, Table, TableWrapper, Thead } from 'components/table';
import { withAPI } from 'hocs';
import useLoadCompanies from 'hooks/api/useLoadCompanies';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { getFormattedCompArr, isNotMobile } from 'utils';
import { useLoadMisconducts } from './hooks';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { NoResults } from 'components/notifications';
import { ExportButton } from 'components/buttons';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import useLoadFuelMeta from 'hooks/api/useLoadMeta';
import DetailsModal from './details-modal';

const MisconductReport = ({ httpRequest }) => {
  const [selectedStatus, setSelectedStatus] = useState('ACTIVE');

  const [selectedFrom, setSelectedFrom] = useState(moment().subtract(1, 'M'));
  const [selectedTo, setSelectedTo] = useState(moment());

  const { data: companies } = useLoadCompanies(httpRequest);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});

  const [hoveredId, setHoveredId] = useState('');

  const tableWrapperRef = React.createRef();

  const formattedCompanies = useMemo(() => {
    const formattedVal =  getFormattedCompArr(companies);
    setSelectedCompanies(formattedVal);
    return formattedVal;
  }, [companies]);

  const { data, isLoading, loadData, setData, isExportDataLoading, exportData } = useLoadMisconducts(
    httpRequest, {
      companies: selectedCompanies,
      from: selectedFrom,
      to: selectedTo,
      status: selectedStatus,
    },
  );

  const { meta: driverMeta } = useLoadFuelMeta(httpRequest, {
    url: '/drivers/meta',
    withAutoLoading: true,
  })

  const debouncedCompanySelect = _.debounce((company) => {
    setSelectedCompanies(company);
  }, 500)

  const fromDateChanged = (date) => {
    setSelectedFrom(moment(date).format('YYYY-MM-DD'));
  }

  const toDateChanged = (date) => {
    setSelectedTo(moment(date).format('YYYY-MM-DD'));
  }

  const getDate = (date) => {
    return moment(date).toDate();
  }

  const onStatClick = (type, key, item) => {
    let allowedKeys = ['1', '2', '3', 'INCIDENTS', 'VEDR'];

    if (!allowedKeys.includes(key)) {
      return;
    }

    var misconducts = [];

    if (['1', '2', '3'].includes(key)) {
      misconducts = item.misconducts.filter(m => parseInt(m.type) === parseInt(key)).map(m => {
        return {
          date: m.date,
          description: m.comments,
        };
      });
    } else if (key === 'INCIDENTS' || key === 'VEDR') {
      let incidentKey = key === 'INCIDENTS' ? 'INCIDENT_INCIDENT' : 'VEDR_EVENT';
      misconducts = item.incidents.filter(m => m.incident === incidentKey).map(m => {
        return {
          date: m.incident_date,
          description: m.notes,
        };
      });
    }

    if (misconducts.length === 0) {
      return;
    }

    setDetailsModalData({
      title: type,
      user: item.user,
      items: misconducts,
    });
    setIsDetailsModalOpen(true);
  }

  const getPercentLabel = (label) => {
    let parts = label.split('/');
    let total = parseFloat(parts[0]);
    let current = parseFloat(parts[1]);
    let percentage = current !== 0 && total !== 0 ? Math.round((current / total) * 100) : 0;
    return '<b>' + percentage + '%</b>';
  }

  useEffect(() => {
      loadData();
  }, [loadData, setData])

  return (
    <PageWrapper>
      {isDetailsModalOpen && (
        <DetailsModal
          open={isDetailsModalOpen}
          setOpen={setIsDetailsModalOpen}
          data={detailsModalData}
        />
      )}
      <PageHeaderWrapper>
        <PageTitle title="Driver Misconduct" />
        <ExportButton onClick={exportData} isLoading={isExportDataLoading} />
      </PageHeaderWrapper>
      <PageHeaderDataFilter>
        <PageHeaderItemWrapper>
          <RegularDropdown
            items={[{id: null, name: 'All'}, ...Object.keys(driverMeta.statuses ?? {}).map((key) => {
              const obj = {};
              obj.id = key;
              obj.name = driverMeta.statuses[key];
              return obj;
            })]}
            onChange={setSelectedStatus}
            selectedValue={selectedStatus}
            />
        </PageHeaderItemWrapper>

        <PageHeaderItemWrapper>
          <ReactDatePicker
            selected={getDate(selectedFrom)}
            onChange={fromDateChanged}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md"
            placeholderText="from"
            disabled={isLoading}
          />
        </PageHeaderItemWrapper>
        <PageHeaderItemWrapper>
          <ReactDatePicker
            selected={getDate(selectedTo)}
            onChange={toDateChanged}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md"
            placeholderText="from"
            disabled={isLoading}
          />
        </PageHeaderItemWrapper>
        <PageHeaderItemWrapper>
          <MultipleSelector
            options={formattedCompanies}
            defaultSelectedOptions={formattedCompanies}
            onChangeSelectedOptions={debouncedCompanySelect}
            isObserveDefaultOptions
            className="z-auto"
          />
        </PageHeaderItemWrapper>
      </PageHeaderDataFilter>
      <TableWrapper ref={tableWrapperRef}>
        <PageLoadingIndicator loading={isLoading} />
        <NoResults centerContent isVisible={!isLoading && !_.isEmpty(companies) && _.isEmpty(data.items)} />
        <Table>
          {data.types && <Thead columnList={['Driver Name', ...Object.values(data.types)]} />}
          <TBody isLoading={isLoading}>
            {_.map(data.items, (item) => (
              <tr key={item?.driver_no}>
                <td>{`${item.user.first_name} ${item.user.last_name} - ${item.driver_no}`}</td>
                {_.map(data.types, (type, key) => (
                  <td key={`${item?.driver_no}-${type}`}>
                    <span
                      className={['1', '2', '3', 'INCIDENTS', 'VEDR'].includes(key) && item.misconducts_count[key] > 0 ? 'cursor-pointer text-blue-500 px-2' : 'px-2'}
                      onClick={() => onStatClick(type, key, item)}
                    >

                      {['POST_PRE', 'DISPATCH'].includes(key) && <span onMouseEnter={() => setHoveredId(item?.driver_no + '-' + type)}
                        onMouseLeave={() => setHoveredId('')}>
                        {item.misconducts_count[key]}
                        {isNotMobile() && item?.driver_no + '-' + type === hoveredId && (
                          <span className="relative">
                            <div
                              style={{width: '200px'}}
                              className="text-xs absolute z-50 left-2 top-3 z-2 py-2 px-5 bg-gray-100 inline-block
                                          border border-black-100 rounded-2xl text-left
                                          font-medium transform -translate-x-1 -translate-y-9 text-gray-900"
                              dangerouslySetInnerHTML={{__html: 
                                key === 'POST_PRE'
                                ? getPercentLabel(item.misconducts_count[key]) + ' - The ratio of pre/post trip inspection days to trip days'
                                : getPercentLabel(item.misconducts_count[key]) + ' - The ratio of dispatches to trips'
                              }}
                            />
                          </span>
                        )}
                      </span>}

                      {!['POST_PRE', 'DISPATCH'].includes(key) && <>{item.misconducts_count[key]}</>}

                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </TBody>
        </Table>
      </TableWrapper>
    </PageWrapper>
  )
}

export default _.flowRight([withAPI])(MisconductReport);
