import { useCancelToken } from 'hooks';
import { useCallback, useState } from 'react'

const useMarkAsRead = (httpRequest, { id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken();

  const readNotification = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);

    await httpRequest({
      method: 'post',
      url: `safety-alerts/my/${id}/mark-read`,
      cancelToken: getCancelToken(),
    }).finally(() => setIsLoading(false));

  }, [httpRequest, getCancelToken, id])

  return ({ isLoading, readNotification });
}

export default useMarkAsRead;