import React from 'react';
import _ from 'lodash';
import { hasPermission } from 'utils';

function withCompanyPermissions(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isCompanyPermissionsLoading: false,
        companyPermissions: [],
        formattedCompanyPermissions: [],
        selectedCompanyPermissions: [],
        shouldSetDefaultPermissions: true,
      };
    }

    async componentDidMount () {
      const { permissions } = this.props;
      if (hasPermission(['AnyResource'], permissions)) {
        await this.loadPermissions();
      }
    }

    loadPermissions = async () => {
      const { httpRequest } = this.props;

      this.setState({ isCompanyPermissionsLoading: true });

      const response = await httpRequest({
        method: 'get',
        url: 'companies/permissions',
      })

      if (response) {
        const data = _.get(response, 'data.data', []);
        this.setState({ companyPermissions: data }, this.formatCompanyPermissions);
      }
    }

    formatCompanyPermissions = () => {
      const { companyPermissions, shouldSetDefaultPermissions } = this.state;

      const formattedCompanyPermissions = companyPermissions
        ?.map(({ id, is_default, name }) => ({ label: name, value: id, isDefault: is_default }))

      this.setState({ formattedCompanyPermissions }, () => {
        if (shouldSetDefaultPermissions) this.setDefaultPermissions();
      });
    }

    setDefaultPermissions = () => {
      const { formattedCompanyPermissions } = this.state;

      const selectedCompanyPermissions = formattedCompanyPermissions
      ?.filter(({ isDefault }) => isDefault);

      this.setState({ selectedCompanyPermissions, isCompanyPermissionsLoading: false });
    }

    setSelectedCompanyPermissions = (permissions) => {
      this.setState({ selectedCompanyPermissions: permissions, isCompanyPermissionsLoading: false });
    }

    setShouldSetDefaultPermissions = (val) => {
      this.setState({ shouldSetDefaultPermissions: val });
    }


    render() {
      const {
        isCompanyPermissionsLoading,
        companyPermissions,
        selectedCompanyPermissions,
        formattedCompanyPermissions,
      } = this.state;
      const rest = {
        isCompanyPermissionsLoading,
        companyPermissions,
        selectedCompanyPermissions,
        formattedCompanyPermissions,
        setSelectedCompanyPermissions: this.setSelectedCompanyPermissions,
        setShouldSetDefaultPermissions: this.setShouldSetDefaultPermissions,
      };
      return <WrappedComponent {...this.props} {...rest} />;
    }
  };
}

export default withCompanyPermissions;
