import _ from "lodash";
import { useCallback, useState } from "react";
import qs from 'query-string';
import { formatMultiSelectedList } from "utils/formatted-query";

const useLoadHubs = (httpRequest, { search = '', selectedCompanies = null, } = {}) => {
  const [hubs, setHubs] = useState([]);

  const loadHubs = useCallback(async (asyncSearch = '') => {

    const query = qs.stringify({
      search: asyncSearch || search,
      [selectedCompanies ? 'companies' : 'company_id']: selectedCompanies ? formatMultiSelectedList(selectedCompanies) : 0,
    }, { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true });

    const response = await httpRequest({
      method: 'get',
      url: `hubs?${query}`,
    });
    if (response) {
      const data = _.get(response, 'data.data', []);
      setHubs(data);
      return data;
    }
  }, [httpRequest, search, selectedCompanies])

  return ({ hubs, loadHubs })
}

export default useLoadHubs