import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadCompanies = (httpRequest) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadCompanies = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/companies?is_suspended=0`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken])

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies])

  return ({ data, isLoading, loadCompanies });
}

export default useLoadCompanies;