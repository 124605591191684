import React from 'react';
import PropTypes from 'prop-types';

const PageHeaderWrapper = ({ children }) => (
  <div className="flex md:flex-row flex-col sm:items-center sm:justify-between mb-5 w-full sm:flex-wrap">
    {children}
  </div>
);

PageHeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageHeaderWrapper;
