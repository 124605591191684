import { DefaultButton } from 'components/buttons';
import { withAPI } from 'hocs';
import { useDidMountEffect } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { ClockOutConfirmDate } from '.';
import { useLoadClocks } from './hooks';

const buildKey = (isIn, key) => `${isIn ? 'in' : 'out'}_${key}`;

const ClockInOut = ({ httpRequest, setError, refreshData }) => {
  const [isClockInDisabled, setIsClockInDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isClockOutConfirmDateOpen, setIsClockOutConfirmDateOpen] = useState(false);

  const {
    isLoading: isClocksLoading,
    loadClocks,
    data,
  } = useLoadClocks(httpRequest, { open: 1, companies: null });

  const getCoordinate = (action) => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition((success, error) => {
        if (success) {
          const coords = success.coords;
          postAction(action, coords);
        };
        if (error) {
          const message = _.get(error, 'positionError.message', '') ;
          if (message) setError({ message })
          setIsLoading(false);
        }
      });
    }
  }

  const getData = (action, coords) => {
    const isIn = action === 'clockIn';
    try {
      return ({
        [buildKey(isIn, 'at')]: moment(isIn ? new Date() : date).format('yyyy-MM-DD HH:mm:ss'),
        [buildKey(isIn, 'lat')]: coords?.latitude || '',
        [buildKey(isIn, 'lng')]: coords?.longitude || '',
      })
    } catch {
      return ({});
    }
  }

  useDidMountEffect(() => {
    setIsClockInDisabled(data?.length > 0);
  }, [data])

  const postAction = async (action, coords) => {
    setError({});
    setIsLoading(true);

    const id = action === 'clockOut' ? data?.[0]?.id : '';    
    const postData = getData(action, coords);

    const response = await httpRequest({
      method: action === 'clockIn' ? 'post' : 'patch',
      url: action === 'clockIn' ? '/clocks' : `/clocks/${id}`,
      data: postData,
    })
      .catch((error) => setError(error?.response?.data))
      .finally(() => {
        setIsLoading(false);
        setIsClockOutConfirmDateOpen(false);
      });

    if (response) {
      if (action === 'clockOut') setIsClockInDisabled(false);
      loadClocks();
      refreshData();
    }
  }

  return (
    <>
      {isClockOutConfirmDateOpen && (
        <ClockOutConfirmDate
          open={isClockOutConfirmDateOpen}
          setOpen={setIsClockOutConfirmDateOpen}
          setDate={setDate}
          selectedDate={date}
          onConfirm={() => getCoordinate('clockOut')}
          isLoading={isLoading}
        />
      )}
      <DefaultButton
        disabled={isClockInDisabled || isClocksLoading}
        isLoading={isLoading}
        label="Clock In"
        onClick={() => getCoordinate('clockIn')}
        customStyles="text-sm"
      />
      <DefaultButton
        disabled={!isClockInDisabled || isClocksLoading}
        isLoading={isLoading}
        onClick={() => setIsClockOutConfirmDateOpen(true)}
        label="Clock Out"
        customStyles="text-sm"
      />
    </>
  )
}

export default _.flowRight([withAPI])(ClockInOut);