import moment from 'moment'
import React, { useEffect } from 'react'

const RTText = ({ section, item, onChange, candidate, value = null, wrapperClassName = "col-span-6 justify-between grid grid-cols-6 my-3 items-end" }) => {

  const getArrayValue = () => {
    if (item.defined && item.defined_type === 'current_date') {
      return [moment().format('MM'), moment().format('DD'), moment().format('YYYY'),]
    } else {
      return Array.isArray(value) ? value : []
    }
  }

  const getPlainValue = () => {
    if (Array.isArray(value)) {
      return '';
    }

    if(item.defined_type === 'driver_name') {
      return value ? value : candidate.first_name + ' ' + candidate.last_name
    } else if(item.defined_type === 'driver_no') {
      return value ? value : candidate.external_id
    } else if(item.defined_type === 'admin_name') {
      return value ? value : candidate.road_test_data.name + ' ' + candidate.road_test_data.surname
    } else if(item.defined_type === 'fedex_certificate_number') {
      return value ? value : candidate.road_test_data.fedex_certificate_number
    } else if(item.defined_type === 'company_city') {
      return value ? value : candidate.company.city
    } else if(item.defined_type === 'company_state') {
      return value ? value : candidate.company.state
    } else if(item.defined_type === 'company_zip') {
      return value ? value : candidate.company.zip
    } else if(item.defined_type === 'company_address') {
      return value ? value : candidate.company.address
    }else if(item.defined_type === 'company_name') {
      return value ? value : candidate.company.name
    } else {
      return value
    }
  }

  const [arrayValue, setArrayValue] = React.useState(getArrayValue)
  const [plainValue, setPlainValue] = React.useState(getPlainValue)

  useEffect(() => {
      if (item.subtype && item.subtype === 'array') {
        if (arrayValue.length === item.array_count) {
          onChange(section.id, item.id, arrayValue)
        }
      } else {
        onChange(section.id, item.id, plainValue)
      }
  }, [arrayValue, item.array_count, item.id, item.subtype, onChange, plainValue, section.id, value])

  const handleChange = (value, index = 0, isArray = false) => {
      if (isArray) {
        let arr = [...arrayValue]
        arr[index] = value
        setArrayValue(arr)
      } else {
        setPlainValue(value)
        onChange(section.id, item.id, value)
      }
  }

  return (
    <div className={wrapperClassName}>
      <p className='col-span-3 border-b mr-4'>{item.question}</p>

      {item.subtype && item.subtype === 'array' && (
        Array.from({ length: item.array_count }, (val, index) => {
          return <input
            type="text"
            value={arrayValue[index] ?? ''}
            key={section.id + '-' + item.id + '-' + index}
            name={section.id + '-' + item.id + '-' + index}
            id={section.id + '-' + item.id + '-' + index}
            onChange={(e) => handleChange(e.target.value, index, true)}
            disabled={item.defined}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-center
                sm:text-sm border-gray-300 rounded-md col-span-1 mr-2 h-10 p-0"
          />
        })
      )}

      {item.subtype === undefined && (
          <input
            type="text"
            value={plainValue ?? ''}
            name={section.id + '-' + item.id}
            id={section.id + '-' + item.id}
            onChange={(e) => handleChange(e.target.value)}
            disabled={item.defined}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                sm:text-sm border-gray-300 rounded-md col-span-3 h-10"
          />
      )}

    </div>
  )
}

export default RTText