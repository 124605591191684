import axios from 'axios';
import { useCancelToken, useDownloadFile } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import { useCallback, useState } from 'react';
import { formatMultiSelectedList } from 'utils/formatted-query';

const useLoadMisconducts = (httpRequest, { companies, from, to, status }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { getCancelToken } = useCancelToken();
  const { downloadFile, isLoading: isExportDataLoading } = useDownloadFile(httpRequest);

  const getFormattedQuery = useCallback((isExport = false) => {
    const formattedCompanies = formatMultiSelectedList(companies);
    
    const checkValidDates = () => {
      return moment(from).format('YYYY-MM-DD') <= moment(to).format('YYYY-MM-DD');
    }

    if (_.isEmpty(formattedCompanies) || !checkValidDates()) return null;

    const query = qs.stringify({
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      status,
      companies: formattedCompanies,
      export: _.toNumber(isExport),
    }, { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' });
    return query;
  }, [companies, from, to, status])

  const loadData = useCallback(async () => {
    const query = getFormattedQuery();

    if (!query) return;
    setIsLoading(true);

    const response = await httpRequest({
      method: 'get',
      url: `/reporting/misconduct?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      const isCancel = axios.isCancel(error)
      setIsLoading(isCancel);
      if (!isCancel) {
        setErrors(error?.response.data);
      }
    });
    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken, getFormattedQuery])

  const exportData = async () => {
    const query = getFormattedQuery(true);
    await downloadFile({
      url: `/reporting/misconduct?${query}`,
      fileName: `Driver Misconduct ${moment(from).format('YYYY-MM-DD')} to ${moment(to).format('YYYY-MM-DD')}`,
    })
  }

  return ({ data, isLoading, loadData, exportData, isExportDataLoading, setData, errors });
}
export default useLoadMisconducts;