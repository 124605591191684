import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';

import getAPIClient from '../../services/api';
import withUser from '../with-user';
import { TokenContext } from 'context/TokenContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

// in ms
const checkTokenDelay = 200;
const maxWaitTime = 5000;

/* eslint-disable react/prop-types */

function withAPI(WrappedComponent) {
  return compose(withUser, withRouter)(class extends React.Component {
    static contextType = TokenContext
    constructor(props) {
      super(props);
      this.state = {
        httpGetLoading: false,
        httpGetDone: false,
        httpGetFailed: false,
        authorized: true,
      };
    }

    reLogin = async () => {
      const { user, history } = this.props;
      const isRememberMe = JSON.parse(localStorage.getItem('isRememberMe'));
      const canReLogin = localStorage.getItem('canReLogin');
      const isTokenLoading = this.context?.isTokenLoading;
      if (canReLogin === '1' && isRememberMe && !isTokenLoading) {
        localStorage.setItem('canReLogin', '0');
        const tokens = JSON.parse(localStorage.getItem('evl-tokens'));
        const { refresh_token } = tokens || '';
        const { email } = user;
        if (refresh_token?.length > 0) {
          this.context.setTokenState(true);
          const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET;
          const clientId = process.env.REACT_APP_PASSPORT_CLIENT_ID;
          const apiClient = getAPIClient();
          await apiClient
            .post('oauth/token', {
              grant_type: 'refresh_token',
              client_id: clientId,
              client_secret: clientSecret,
              refresh_token,
              username: email,
            })
            .catch(() => this.setState({ authorized: false }))
            .then(async (response) => {
              const { data } = response;
              if (data) {
                const {
                  storeTokens, getProfile, storeProfile, setIsAuthenticated,
                } = this.props;
                storeTokens(data);
                const profile = await getProfile();
                storeProfile(profile);
                setIsAuthenticated(true);
                localStorage.setItem('canReLogin', '1');
              }
            })
            .finally(() => this.context.setTokenState(false));
        } else {
          this.setState({ authorized: false });
          history.push('/logout');
          localStorage.setItem('canReLogin', '1');
          throw Error();
        }
      } else if (!isRememberMe) {
        this.setState({ authorized: false });
        history.push('/logout');
        throw Error();
      }
    };

    async waitTokenLoading () {
      return await new Promise((success, onError) => {
        let startTime = checkTokenDelay;
        const interval = setInterval(() => {
          if (!this.context.isTokenLoading) {
            clearInterval(interval);
            success();
          } else if (startTime >= maxWaitTime) {
            clearInterval(interval);
            onError();
          }
          startTime += checkTokenDelay;
        }, checkTokenDelay)
      })
    }

    httpRequest = async ({
      method: httpMethod,
      url,
      data,
      tag: mTag = '',
      noToken,
      cancelToken = null,
      responseType,
    }) => {
      const tokens = JSON.parse(localStorage.getItem('evl-tokens')) || {};
      const timeout = url === 'payrolls/import-multiple' ? 60000 : 30000;
      const fn = _.get(
        getAPIClient((!noToken && tokens && tokens.access_token)
          ? tokens.access_token
          : undefined, timeout, cancelToken, responseType),
        httpMethod,
      );
      const method = _.startCase(httpMethod);
      const tag = _.startCase(mTag);
      this.setState({
        [`http${method}Loading${tag}`]: true,
      });
      const result = await fn(url, data).catch(async (error) => {
        const code = _.get(error, 'response.status', 404);
        if (code === 401 && this.state.authorized) {
          try {
            if (this.context?.isTokenLoading) {
              await this.waitTokenLoading();
            } else {
              await this.reLogin()
            }
            return await this.httpRequest({ method: httpMethod, url, data, tag: mTag, noToken, cancelToken, responseType });
          } catch {
            throw error;
          }
        } else {
          this.setState({
            [`http${method}Loading${tag}`]: false,
            [`http${method}Done${tag}`]: true,
            [`http${method}Failed${tag}`]: true,
            [`http${method}FailReason${tag}`]: {
              code,
              errors: _.get(error, 'response.data.errors'),
              message: _.get(error, 'response.data.message'),
            },
          });
          throw error;
        }
      });
      if (result) {
        this.setState({
          [`http${method}Loading${tag}`]: false,
          [`http${method}Done${tag}`]: true,
          [`http${method}Failed${tag}`]: false,
          [`http${method}FailReason${tag}`]: undefined,
        });
        return result;
      }
      return null;
    }

    render() {
      const rest = {
        httpRequest: this.httpRequest,
      };
      const { authorized } = this.state;
      return authorized ? <WrappedComponent {...this.props} {...rest} {...this.state} /> : <Redirect to="/logout" />;
    }
  });
}

export default withAPI;
