import { useState, useCallback, useEffect } from 'react';
import queryString from 'query-string';
import { useCancelToken } from 'hooks';
import axios from 'axios';
import _ from 'lodash';
import { formatPaginationMeta } from 'utils';

const useLoadDispatches = (
  httpRequest,
  page = 1,
  selectedCompanies = null,
  search = '',
  sort = '',
  user_id = null,
) => {
  const [dispatches, setDispatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const { getCancelToken } = useCancelToken();

  const loadDispatches = useCallback(async () => {
    setIsLoading(true);
    const query = queryString.stringify({
      search,
      page,
      sort,
      companies: selectedCompanies?.map((item) => item.value),
      user_id,
    }, { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' });
    
    const response = await httpRequest({
      method: 'get',
      url: `/dispatches?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });
    if (response) {
      const data = _.get(response, 'data.data', []);
      const pagination = _.get(response, 'data.meta', {});
      setIsLoading(false);
      setDispatches(data);
      setPagination(formatPaginationMeta(pagination, true));
    }
  }, [httpRequest, page, sort, selectedCompanies, search, getCancelToken, user_id]);

  useEffect(() => {
    loadDispatches();
  }, [loadDispatches])

  return ({ dispatches, isLoading, user_id, pagination, loadDispatches });
}

export default useLoadDispatches