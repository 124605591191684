import { useState, useEffect } from "react";

const useItOnce = ({ condition, callback }) => {
  const [isUsed, setIsUsed] = useState(false);

  const reset = () => {
    setIsUsed(false);
  }

  useEffect(() => {
    setIsUsed((prev) => {
      if (!condition && !prev) return false;
      if (prev) return true;

      if (condition && !prev) {
        callback();
        return true;
      }
    });
  }, [condition, callback])

  return { isUsed, reset};
}

export default useItOnce;