import React from 'react';
import PropTypes from 'prop-types';

const PageHeaderBtn = ({ label, onClick, isDisabled }) => (
  <div className="flex">
    <button
      onClick={onClick}
      disabled={isDisabled}
      type="button"
      className="inline-flex items-center px-4 py-2 border border-transparent
            shadow-sm text-sm font-medium rounded-md text-white bg-green-regular
            hover:bg-green-hover focus:outline-none sm:order-1
            w-full md:w-max justify-center"
    >
      {label}
    </button>
  </div>
);

PageHeaderBtn.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PageHeaderBtn;
