import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadPayrollChangeMeta = (httpRequest) => {
  const [changeTypes, setChangeTypes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadPayrollChangeMeta = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: 'payroll-adjustments/meta',
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', {});
      const { change_types } = data;

      setIsLoading(false);
      setChangeTypes(change_types);
    }
  }, [httpRequest, getCancelToken])

  useEffect(() => {
    loadPayrollChangeMeta();
  }, [loadPayrollChangeMeta])

  return ({ changeTypes, isLoading, loadPayrollChangeMeta });
}

export default useLoadPayrollChangeMeta;