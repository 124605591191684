import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadCandidatesMeta = (httpRequest) => {
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadMeta = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: 'candidates/meta',
    }).finally(() => setIsLoading(false));

    if (response) {
      const meta = _.get(response, 'data.data', {});
      setMeta(meta);
    }
  }, [httpRequest])

  useEffect(() => {
    loadMeta();
  }, [loadMeta])

  return ({ meta, isLoading });
}

export default useLoadCandidatesMeta;