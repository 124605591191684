import React from 'react';
import PropTypes from 'prop-types';

const FormHandlersWrapper = ({ children }) => (
  <div className="pt-5">
    <div className="flex justify-end gap-3">
      {children}
    </div>
  </div>
);

FormHandlersWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormHandlersWrapper;
