import moment from 'moment';

export const DayOfTheWeek = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}

export const getFormattedDate = (date) => {
  const formattedDate = date instanceof Date
    ? moment(date).format('MM/DD/YYYY')
    : moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getFormattedDateDay = (date) => {
  const formattedDate = date instanceof Date
    ? moment(date).format('ddd, MM/DD/YYYY')
    : moment(date, 'YYYY-MM-DD').format('ddd, MM/DD/YYYY');
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getFormattedDateAPI = (date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getFormattedFullDate = (date, format = 'MM/DD/YYYY hh:mm A') => {
  try {
    const formattedDate = moment(date).format(format);
    return formattedDate !== 'Invalid date' ? formattedDate : '';
  } catch {
    return '';
  }
};

export const getDate = (date, defaultResponse = moment().toDate()) => {
  if (date === '0000-00-00') return null;
  const formattedDate = moment(date).toDate();
  return date && (formattedDate.toLocaleDateString() !== 'Invalid Date') ? formattedDate : defaultResponse;
};

export const subtractWeek = (date) => {
  if (date === '0000-00-00') return null;
  return (date ? moment(date).subtract(1, 'w').toDate() : new Date());
};

export const getDateFromYear = (year) => (year ? moment(year, 'YYYY').toDate() : new Date());

export const setDate = (date) => moment(date).format('YYYY-MM-DD');

// weekDay (0 to 6) means Sunday(0) to Saturday(6)
export const nextWeekDay = (date, weekDay) => (new Date(
  date.setDate(date.getDate() + ((weekDay + (7 - date.getDay())) % 7)),
));

export const isFriday = (date) => date?.getDay() === 5;

export const isWednesday = (date) => date?.getDay() === DayOfTheWeek.Wednesday;

export const isPreferredDay = (date, dayOfTheWeek) => date?.getDay() === dayOfTheWeek;

export const lastDayOfTheWeekRelativeCurrentDate = (dayOfTheWeekNumber) => {
  return moment().day(dayOfTheWeekNumber);
}

export const getDayDiff = (startDate, endDate = new Date()) => {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs((endDate.getTime() - getDate(startDate).getTime())) / msInDay);
};

export const getFirstMonthDay = () => moment().startOf('month').toDate();

export const getLastMonthDay = () => moment().endOf('month').toDate();

export const getListOfYears = (minYear) => {
  let currentYear = moment().year();
  const years = [];
  while (currentYear >= minYear) {
    years.push(currentYear);
    currentYear--;
  }
  return years;
}

export const getPayrollDateRange = () => {
  const weekDay = moment().weekday();
  if (weekDay === DayOfTheWeek.Saturday || weekDay === DayOfTheWeek.Sunday) {
    const from = moment().day(DayOfTheWeek.Saturday);
    const to = moment(from).add(6, 'days');
    return ({ from, to });
  } else {
    const from = moment().subtract(1, 'week').day(DayOfTheWeek.Saturday);
    const to = moment(from).add(6, 'days');
    return ({ from, to });
  }
}

export const isCurrentDateLessThanMaximumDate = (date, { amount, type }) => {
  try {
    const passedDate = moment(date);
    return passedDate < moment().add(amount, type)
  } catch {
    return false
  }
}