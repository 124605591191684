import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import RadioButton from './radio-button';

const RadioGroup = ({ items, defaultSelected, onChange, className = "flex flex-col gap-2" }) => {
  const [selected, setSelected] = useState(defaultSelected || items[0]?.id);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected])

  const onSelectRadioButton = (value) => {
    if (value.target.disabled) return;
    const updValue = value.target.value;
    setSelected(updValue);
    onChange(updValue);
  }

  if (items?.length === 0) return null;

  return (
    <div className={className}>
      {items?.map((item) => (
        <RadioButton
          isDisabled={item?.isDisabled}
          label={item?.label}
          key={item?.id}
          value={item?.id}
          isChecked={selected === item?.id}
          onChange={onSelectRadioButton}
        />
      ))}
    </div>
  );
}

RadioGroup.propTypes = {
  items: PropTypes.array.isRequired,
  defaultSelected: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

RadioGroup.defaultProps = {
  defaultSelected: '',
  className: 'flex flex-col gap-2',
};

export default RadioGroup;
