import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";
import queryString from 'query-string';
import { formatPaginationMeta } from "utils";

const useLoadClocks = (httpRequest, { page = 1, companies = [], open = null, search = '', from = '', to = '' }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const { getCancelToken } = useCancelToken();

  const loadClocks = useCallback(async () => {

    const query = queryString.stringify({
      page,
      companies: companies?.map((item) => item?.value),
      open,
      search,
      from,
      to,
    }, { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' })

    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/clocks?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      const pagination = _.get(response, 'data.meta', {});
      setIsLoading(false);
      setData(data);
      setPagination(formatPaginationMeta(pagination, true));
    }
  }, [httpRequest, getCancelToken, page, companies, open, search, from, to])

  useEffect(() => {
    if ((_.isArray(companies) && companies.length > 0) || companies === null) {
      loadClocks();
    }
  }, [loadClocks, companies])

  return ({ data, isLoading, loadClocks, pagination });
}

export default useLoadClocks;