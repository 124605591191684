import { React, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import WithPermission from '../../modules/with-permission';
import { classNames, isNotMobile } from '../../utils';

export default function DotsDropdown(props) {
  const { id, menuItems } = props;
  const [hoveredLabel, setHoveredLabel] = useState('');
  const anyOfPermissions = menuItems.flatMap((item) => item.permissionCheck);
  return (
    <WithPermission permissionsCheck={anyOfPermissions}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
            data-testid="more-btn"
          >
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-50"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right custom-last-drop-item absolute z-50 right-0 mt-2 w-56 rounded-md
          shadow-lg bg-white ring-1 ring-black-100 focus:outline-none"
          >
            <div className="py-1" onMouseLeave={() => setHoveredLabel('')} data-testid="more-btn-list">
              {
              menuItems.filter((i) => (typeof i.condition !== 'function') || i.condition(id) || i.hoverLabel).map(
                (item) => (
                  <WithPermission permissionsCheck={item.permissionCheck} key={item?.label}>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm relative',
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            if ((typeof item.condition !== 'function') || item.condition(id)) {
                              item?.callback(id);
                            }
                          }}
                          onMouseEnter={() => {
                            if (typeof item.condition === 'function' && !item.condition(id) && item.hoverLabel) {
                              setHoveredLabel(item.label);
                            }
                          }}
                        >
                          {item?.label}
                          {isNotMobile() && item.hoverLabel && hoveredLabel === item.label && (
                            <div
                              className="text-xs absolute left-0 z-10 py-2 px-4 bg-gray-100
                              border border-black-100 rounded-2xl text-left font-medium
                              block transform -translate-x-1/2 break-all"
                              style={{ width: '100%' }}
                            >
                              {item.hoverLabel}
                            </div>
                          )}
                        </Link>
                      )}
                    </Menu.Item>
                  </WithPermission>
                ),
              )
            }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </WithPermission>
  );
}

DotsDropdown.defaultProps = {
  menuItems: [
    {
      label: 'Account details',
      callback: () => '',
    },
    {
      label: 'Edit',
      callback: () => '',
    },
    {
      label: 'Delete',
      callback: () => '',
    },
  ],
};

DotsDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired,
  menuItems: PropTypes.array,
};
