import { ChevronLeftIcon, ChevronRightIcon, DocumentTextIcon } from '@heroicons/react/outline'
import { useCarouselSettings } from 'hooks';
import React, { useEffect, useMemo } from 'react'
import { isFileExt, isVideoExt } from 'utils';

const PreviewList = ({ files, selectedUrl, maxViewElements, setSelectedFile }) => {

  const {
    carouselSettings,
    onClickBack: carouselOnClickBack,
    onClickNext: carouselOnClickNext,
    calcCarouselSettings,
  } = useCarouselSettings({ maxViewElements, files });

  useEffect(() => {
    calcCarouselSettings(selectedUrl);
    // eslint-disable-next-line
  }, [selectedUrl])

  const colsCount = useMemo(() => {
    return maxViewElements < files?.length ? maxViewElements: files?.length
  }, [files, maxViewElements])

  return (
    files?.length > 0 && (
      <div style={{'gridTemplateColumns': `repeat(${colsCount}, minmax(0, 1fr))`}} className="md:gap-4 gap-2 grid grid-cols-3 relative mt-4 px-2 pb-2">
        {files?.length > maxViewElements && (
          <>
            <button onClick={carouselOnClickBack} className="absolute -left-7 top-1/2 transform -translate-y-1/2 hover:opacity-50">
              <ChevronLeftIcon className="w-10 h-10 text-green-regular" />
            </button>
            <button onClick={carouselOnClickNext} className="absolute -right-7 top-1/2 transform -translate-y-1/2 hover:opacity-50">
              <ChevronRightIcon className="w-10 h-10 text-green-regular" />
            </button>
          </>
        )}
        {files?.slice(carouselSettings?.currentSlideStart, carouselSettings.endSlideStart)?.map((item) => (
          <button
            type="button"
            onClick={() => setSelectedFile(item)}
            key={item?.url}
            style={{ height: "104px", width: "120px"}}
          >
            {isVideoExt(item?.url) || isFileExt(item?.url) ?
                (
                  <div
                    className={`w-full h-full flex items-center justify-center rounded-xl
                    object-cover border-2 ${selectedUrl === item?.url ? "border-green-regular" : 'border-transparent'}`}
                  >
                    <DocumentTextIcon className='w-1/2 h-1/2' />
                  </div>
                )
              : 
                <img
                  className={`w-full h-full rounded-xl object-cover border-2 ${selectedUrl === item?.url ? "border-green-regular" : 'border-transparent'}`}
                  src={item?.thumbnail_url || item?.url}
                  alt="Preview"
                />
            }
          </button>
        ))}
      </div>
    )
  )
}

export default PreviewList