import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadFuelMeta = (httpRequest, { url = "", withAutoLoading = false }) => {
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadMeta = useCallback(async () => {
    if (!url) return;
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url,
    }).finally(() => setIsLoading(false));

    if (response) {
      const meta = _.get(response, 'data.data', {});
      setMeta(meta);
    }
  }, [httpRequest, url])

  useEffect(() => {
    if (withAutoLoading) {
      loadMeta();
    }
  }, [loadMeta, withAutoLoading])

  return ({ meta, isLoading });
}

export default useLoadFuelMeta;