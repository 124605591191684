import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';

const defaultClasses = 'w-full flex gap-3 items-center justify-center h-5 w-5 rounded-full';

const FormCheckBtn = ({ color, label, isLoading }) => (
  <div className={`${defaultClasses} text-${color}-600 ${isLoading ? 'opacity-50 cursor-default' : 'cursor-pointer'}`}>
    <div className={`flex items-center justify-center h-5 w-5 rounded-full bg-${color}-100`}>
      <CheckIcon
        className="h-3 w-3"
        aria-hidden="true"
      />
    </div>
    <span className="text-sm whitespace-nowrap">{label}</span>
  </div>
);

FormCheckBtn.propTypes = {
  color: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

FormCheckBtn.defaultProps = {
  label: '',
  isLoading: false,
};

export default FormCheckBtn;
