import React, { useState } from 'react';

const SubRowDropDown = ({ id, onExtend = () => {}, onCollapse = () => {}, children, labelStyle, isOpen = false }) => {
  const [checked, setChecked] = useState(isOpen);

  const onCheck = () => {
    setChecked((prev) => {
      prev ? onCollapse() : onExtend();
      return (!prev)
    })
  }

  return (
    <>
      <tr className="w-full">
        <td colSpan="200" className="p-0">
        <div className="accordion flex flex-col justify-center">
          <input
            type="checkbox"
            name="panel"
            id={`panel-${id}`}
            className="hidden"
            checked={checked}
            onChange={onCheck}
          />
          <label
            htmlFor={`panel-${id}`}
            className={`table-accordion-arrow relative block w-3/4 ${checked ? 'top-2 left-0.5' : 'top-1.5'}`}
            style={labelStyle}
          >
            &nbsp;
          </label>
          </div>
        </td>
      </tr>
      {checked && (
        children
      )}
    </>
  )
};

export default SubRowDropDown;
