import _ from "lodash";
import { useEffect, useState } from "react";

const useCarouselSettings = ({ maxViewElements, files }) => {

  const [carouselSettings, setCarouselSettings] = useState({
    currentSlideStart: 0,
    endSlideStart: 0,
  })

  const onClickNext = () => {
    const nextSlideSlice = carouselSettings.currentSlideStart + maxViewElements;
    if (nextSlideSlice >= files?.length) {
      setCarouselSettings({
        currentSlideStart: 0,
        endSlideStart: maxViewElements,
      })
      return;
    };
    setCarouselSettings({
      currentSlideStart: nextSlideSlice,
      endSlideStart: carouselSettings.endSlideStart + maxViewElements,
    })
  }

  const onClickBack = () => {
    const nextSlideSlice = carouselSettings.currentSlideStart - maxViewElements;
    if (nextSlideSlice < 0) {
      const remainingFiles = files?.length % maxViewElements;
      setCarouselSettings({
        currentSlideStart: !remainingFiles ? remainingFiles - maxViewElements : files?.length - remainingFiles,
        endSlideStart: files?.length,
      })
      return;
    }
    setCarouselSettings({
      currentSlideStart: nextSlideSlice,
      endSlideStart: nextSlideSlice + maxViewElements,
    })
  }

  const calcCarouselSettings = (url) => {
    const selectedFileIndex = _.findIndex(_.map(files, 'url'), (i) => i === url);
    if (selectedFileIndex === -1) return;
    if ((selectedFileIndex >= carouselSettings.currentSlideStart) && (selectedFileIndex < carouselSettings.endSlideStart)) return;

    let start = 0;
    let end = maxViewElements;

    const maxIteration = Math.ceil(files?.length / maxViewElements);

    for (let index = 0; index < maxIteration; index++) {
      const slice = files?.slice(start, end);
      const isIncludesUrl = _.includes(_.map(slice, 'url'), url);

      if (isIncludesUrl) {
        setCarouselSettings({ currentSlideStart: start, endSlideStart: end });
        break;
      }

      start += maxViewElements;
      end   += maxViewElements;
    }
  }

  useEffect(() => {
    setCarouselSettings({
      currentSlideStart: 0,
      endSlideStart: maxViewElements,
    });
  }, [files, maxViewElements])

  return { carouselSettings, onClickBack, onClickNext, calcCarouselSettings }
}

export default useCarouselSettings;