import ClearIndicator from 'components/clear-interval-indicator/ClearIndicator';
import DropdownIndicator from 'components/drop-down-indicator/DropdownIndicator';
import MenuSelectList from 'components/menu-select-list/MenuSelectList';
import selectContainer from 'components/select-container';
import { defaultStyles } from 'components/select-custom-styles';
import selectOptionsCheckbox from 'components/select-options-checkbox';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onChangeMultipleSelect } from 'utils';
import Select from 'react-select';

const MultipleSelector = ({
  options,
  defaultSelectedOptions,
  onChangeSelectedOptions,
  withAllOption = true,
  isAllSelected = true,
  maxMenuHeight = 325,
  placeholder = "Select company...",
  menuPlacement = "bottom",
  isLimit = false,
  className = "block w-full sm:text-sm rounded-md",
  isObserveDefaultOptions = false,
  isFullOptionWidth = false,
}) => {
  const [selectedData, setSelectedData] = useState(defaultSelectedOptions);
  const [isAllData, setIsAllData] = useState(isAllSelected);

  const onChangeSelectWithAllOption = (opts) => {
    const { selectedCompanies, isAllComp } = onChangeMultipleSelect(opts, isAllData, options);
    setIsAllData(isAllComp);
    setSelectedData(selectedCompanies);
    onChangeSelectedOptions(selectedCompanies);
  }

  const onChangeSelectWithoutAllOption = (opts) => {
    setSelectedData(opts);
    onChangeSelectedOptions(opts);
  }

  useEffect(() => {
    if (isObserveDefaultOptions) {
      setSelectedData(defaultSelectedOptions);
      setIsAllData(defaultSelectedOptions?.length === options?.length)
    }
  }, [defaultSelectedOptions, isObserveDefaultOptions, options])

  return (
    <Select
      value={selectedData}
      options={options}
      onChange={(opts) => withAllOption ? onChangeSelectWithAllOption(opts) : onChangeSelectWithoutAllOption(opts)}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      selectProps={{ isLimit, isFullOptionWidth }}
      components={{
        Option: selectOptionsCheckbox,
        ValueContainer: selectContainer,
        MenuList: MenuSelectList,
        DropdownIndicator,
        ClearIndicator,
      }}
      maxMenuHeight={maxMenuHeight}
      className={className}
      name="totals_companies"
      placeholder={placeholder}
      classNamePrefix="select"
      menuPlacement={menuPlacement}
      styles={defaultStyles}
    />
  )
}

MultipleSelector.propTypes = {
  options: PropTypes.array.isRequired,
  defaultSelectedOptions: PropTypes.array.isRequired,
  onChangeSelectedOptions: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
};

MultipleSelector.defaultProps = {
  maxMenuHeight: 325,
};

export default MultipleSelector;