import React from 'react'

const RTPlaceholder = ({ label, wrapperClassName = "col-span-6 flex flex-col justify-between" }) => {
  return (
    <div className={wrapperClassName}>
      <p className='mt-3'>{label}</p>
    </div>
  )
}

export default RTPlaceholder