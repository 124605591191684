import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

import { withAPI } from 'hocs';
import handleDroppedFileInput from 'components/drag-and-dpop';
import { ValidationsErrors, SuccessMessage } from 'components/notifications'
import { getCompanyRequest, getDriverLabel, getFormattedCompArr, scrollToTop } from 'utils';
import selectOptionsCheckbox from 'components/select-options-checkbox';
import selectContainer from 'components/select-container';
import DropdownIndicator from 'components/drop-down-indicator/DropdownIndicator';
import ClearIndicator from 'components/clear-interval-indicator/ClearIndicator';
import { defaultStyles } from 'components/select-custom-styles';
import Upload from 'components/upload';
import UploadWrapperDND from 'components/upload/upload-wrapper-dnd';
import ModalWrapper from './modal-wrapper';
import MenuSelectList from 'components/menu-select-list/MenuSelectList';
import FormRadioCheckBox from 'components/form/form-radio-checkbox';
import FormHandlersWrapper from 'modules/companies/form-components/form-handlers-wrapper';
import { FormCancel, FormSave } from 'components/form';

export const teamTypes = [
  { value: 0, label: 'All' },
  { value: 'SOLO', label: 'Solo' },
  { value: 'TEAM', label: 'Team' },
];

class ModalDriverAddSafety extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocations: [{ value: '', label: 'All Locations' }],
      locations: [],
      
      driversList: [],
      selectedDrivers: [{ value: '', label: 'All Drivers' }],

      isAllCompanies: true,
      isAllLocations: true,
      isAllDrivers: true,

      selectedCompanies: [{ value: '', label: 'All Companies' }],
      companies: [],

      types: [],

      selectIndividualDriver: false,

      values: {
        team_type: { value: 0, label: 'All' },
      },
      file: null,

      errors: {},
      isLoading: false,
      itemCreatedOk: false,
    };
    this.modalRef = React.createRef();
  }

  async componentDidMount() {
    const { selectIndividualDriver } = this.state;
    this.loadMeta();
    this.loadCompanies();

    if (selectIndividualDriver) {
      this.loadDrivers();
    } else this.loadLocations();
  }

  loadMeta = async () => {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: 'safety-alerts/meta',
    });
    if (response) {
      const {
        data: {
          data: {
            types,
          },
        },
      } = response;

      this.setState({ types: Object.keys(types)?.map((key) => ({ value: key, label: types[key], disabled: false })) });
    }
  }

  loadCompanies = async () => {
    const { httpRequest } = this.props;
    const { selectIndividualDriver } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: 'companies?is_suspended=0',
    });

    if (response) {
      const companies = _.get(response, 'data.data', []);

      if (companies?.length === 0) {
        this.validateMessageType();
      }

      if (_.isArray(companies) && companies.length > 1) {
        const composedCompanies = getFormattedCompArr(companies);
        this.setState({
          companies: composedCompanies,
          selectedCompanies: composedCompanies,
        }, this.validateMessageType);
        return null;
      }
      if (companies?.length === 1) {
        const selectedCompany = { ...companies[0], value: companies[0]?.id, label: companies[0]?.name };
        this.setState({
          selectedCompanies: [selectedCompany],
          companies: [selectedCompany],
        }, () => {
          selectIndividualDriver ? this.loadDrivers() : this.loadLocations()
          this.validateMessageType();
        });
      }
    }
    return null;
  }

  loadLocations = async () => {
    const { httpRequest } = this.props;
    const {
      values: {
        team_type: { value: team_type },
      },
      selectedCompanies,
    } = this.state;
    this.setState({ isLoading: true });
    const companyQuery = getCompanyRequest(selectedCompanies, '&');
    const response = await httpRequest({
      method: 'get',
      url: `hubs?in_my_companies=1${companyQuery}&team_type=${team_type}&with_own_drivers=1&status=ACTIVE`,
    });
    if (response) {
      const locations = _.get(response, 'data.data', []);

      const concatLocations = [{ value: '', label: 'All Locations' }]
        .concat(locations?.map((i) => ({ value: i.id, label: i.name })));
      this.setState({
        locations: concatLocations,
        selectedLocations: concatLocations,
        isAllLocations: true,
        isLoading: false,
      });
    }
  }

  loadDrivers = async () => {
    const { httpRequest } = this.props;
    const {
      values: {
        team_type: { value: team_type },
      },
      selectedCompanies,
    } = this.state;
    this.setState({ isLoading: true });
    const companyQuery = getCompanyRequest(selectedCompanies, '&');
    const response = await httpRequest({
      method: 'get',
      url: `drivers?status=ACTIVE${companyQuery}&team_type=${team_type}`,
    });
    if (response) {
      const {
        data: {
          data: drivers,
        },
      } = response;
      const concatDrivers = [{ value: '', label: 'All Drivers' }]
        .concat(drivers?.map((i) => ({ value: i.id, label: getDriverLabel(i) })));
      this.setState({
        driversList: concatDrivers,
        selectedDrivers: concatDrivers,
        isAllDrivers: true,
        isLoading: false,
      });
    }
  }

  handleValue = (field, value, callback) => {
    const { values } = this.state;
    const updatedValues = { ...values, [field]: value };
    this.setState({
      values: updatedValues,
      errors: {},
      isLoading: callback !== undefined,
    }, () => { if (callback !== undefined) callback(); });
  }

  handleFileInput = (files) => {
    const file = _.last(files);
    if (file) this.setState({ file });
  }

  toggleSelectIndividualDrivers = () => {
    const { selectIndividualDriver, values } = this.state;
    this.setState({
      selectIndividualDriver: !selectIndividualDriver,
      values: {
        ...values,
      },
      selectedLocations: [{ value: '', label: 'All Locations' }],
      selectedDrivers: [{ value: '', label: 'All drivers' }],
    }, selectIndividualDriver ? this.loadLocations : this.loadDrivers);
  }

  onSubmit = async () => {
    const {
      httpRequest, refreshData, setOpen,
    } = this.props;
    const {
      selectIndividualDriver, file,
      values: {
        type, message, team_type,
      },
      selectedCompanies,
      selectedLocations,
      selectedDrivers,
    } = this.state;

    this.setState({ errors: {}, itemCreatedOk: false, isLoading: true });

    const data = new FormData();
    _.forEach(selectedCompanies, (item) => {
      if (item?.value !== '') {
        data.append('companies[]', item?.value)}
      }
    );
    if (message) data.append('message', message);
    if (team_type) data.append('team_type', team_type?.value);
    if (file) data.append('file', file);

    const formattedType = type?.map((i) => i.value)?.join() || '';
    if (formattedType) data.append('type', formattedType);

    if (selectIndividualDriver) {
      _.forEach(selectedDrivers, (item) => {
        if (item?.value !== '') {
          data.append('drivers[]', item?.value)}
        }
      );
    } else {
      _.forEach(selectedLocations, (item) => {
        if (item?.value !== '') {
          data.append('hubs[]', item?.value)}
        }
      );
    }

    const response = await httpRequest({
      method: 'post',
      url: 'safety-alerts',
      data,
    })
    .catch((error) => {
      scrollToTop(this.modalRef);
      this.setState({
        errors: error?.response?.data,
      });
    })
    .finally(() => this.setState({ isLoading: false }))
    if (response) {
      refreshData(false);
      setOpen(false);
    }
  }

  onDropFile = (file) => {
    const newFile = handleDroppedFileInput(file, 'image');
    if (newFile) this.setState({ file: newFile, errors: {} });
  }

  onChangeSelect = (opts, field) => {
    const {
      companies, isAllCompanies, locations, isAllLocations, driversList, isAllDrivers,
    } = this.state;
    const fields = {
      Company: () => this.onSelect(opts, companies, isAllCompanies,
        'selectedCompanies', 'isAllCompanies', () => {
          this.validateMessageType();
          this.handleCompany()
        }),
      Locations: () => this.onSelect(opts, locations, isAllLocations,
        'selectedLocations', 'isAllLocations'),
      Drivers: () => this.onSelect(opts, driversList, isAllDrivers,
        'selectedDrivers', 'isAllDrivers'),
    }
    fields[field]();
  }

  onSelect = (opts, items, isAllItems, selectedFields, isAllField, func) => {
    const find = opts.filter((i) => i.value === '');
    if (isAllItems && _.isEmpty(find)) {
      this.setState({
        [selectedFields]: [],
        [isAllField]: false,
      }, () => func?.());
    } else if (!_.isEmpty(find) && !isAllItems) {
      this.setState({
        [selectedFields]: items,
        [isAllField]: true,
      }, () => func?.());
    } else if (opts.length + 1 === items.length && _.isEmpty(find) && !isAllItems) {
      this.setState({
        [selectedFields]: items,
        [isAllField]: true,
      }, () => func?.());
    } else if ((opts.length < items.length) && !_.isEmpty(find)) {
      this.setState({
        [selectedFields]: opts.filter((i) => i.value !== ''),
        [isAllField]: false,
      }, () => func?.());
    } else {
      this.setState({
        [selectedFields]: opts,
        [isAllField]: false,
      }, () => func?.());
    }
  }

  validateMessageType = () => {
    const { values: { type }, selectedCompanies, types } = this.state;

    const selectedCompaniesProviders = _.map(selectedCompanies, 'settings.sms_provider');
    const isContainsWholeProviderSettings = _.some(selectedCompaniesProviders,
      ({ provider, from, sid, token } = { }) => (provider && from && sid && token))

    const updTypes = _.cloneDeep(types);
    const smsTypeIndex = _.findIndex(updTypes, (type) => type?.value === 'SMS');

    if (smsTypeIndex !== '-1') {
      updTypes[smsTypeIndex].disabled = !isContainsWholeProviderSettings;
      this.setState({ types: updTypes });
    }

    if (!isContainsWholeProviderSettings) {
      const updSelectedTypes = _.cloneDeep(type);
      const smsIndex = _.findIndex(updSelectedTypes, (type) => type?.value === 'SMS');

      if (smsIndex !== -1) {
        updSelectedTypes?.splice(smsIndex, 1);
        this.handleValue('type', updSelectedTypes);
      }
    }
  }

  handleCompany = _.debounce(async () => {
    const { selectIndividualDriver } = this.state;
    if (selectIndividualDriver) this.loadDrivers()
    else this.loadLocations();
  }, 1000);

  render() {
    const {
      open, setOpen,
    } = this.props;

    const {
      errors, itemCreatedOk, values, isLoading, file,
      locations, driversList, types,
      selectIndividualDriver, selectedCompanies, companies, selectedLocations,
      selectedDrivers,
    } = this.state;

    const {
      message, team_type, type,
    } = values;

    return (
      <ModalWrapper
        title="New Safety Alert"
        open={open}
        setOpen={setOpen}
        ref={this.modalRef}
      >
        <form id="entity-form" onSubmit={(e) => { e.preventDefault(); this.onSubmit(); }}>
          <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            {
              !_.isEmpty(errors) && (
                <ValidationsErrors errors={errors} addClass="sm:col-span-6" />
              )
            }
            {
              itemCreatedOk && (
                <SuccessMessage
                  message="Safety alert was created successfully"
                  addClass="sm:col-span-6"
                />
              )
            }

            <div className="sm:col-span-6">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Alert message
              </label>
              <div className="mt-1">
                <textarea
                  value={message || ''}
                  id="message"
                  name="message"
                  rows="4"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block
                          w-full sm:text-sm border border-gray-300 rounded-md p-2"
                  onChange={(e) => this.handleValue('message', e.target.value)}
                  placeholder="Enter your safety alert message here."
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Message type
              </label>
              <div className="mt-1">
                <Select
                  styles={defaultStyles}
                  value={type || []}
                  options={types}
                  onChange={(val) => this.handleValue('type', val)}
                  maxMenuHeight={300}
                  isMulti
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  isDisabled={isLoading}
                  components={{
                    Option: selectOptionsCheckbox,
                    ValueContainer: selectContainer,
                    DropdownIndicator,
                    ClearIndicator,
                  }}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                  block w-full sm:text-sm border-gray-300 rounded-md"
                  name="type"
                  classNamePrefix="select"
                  placeholder="Select type..."
                  isOptionDisabled={(opt) => opt.disabled}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="team_type" className="block text-sm font-medium text-gray-700">
                Team type
              </label>
              <div className="mt-1">
                <Select
                  value={team_type || ''}
                  options={teamTypes}
                  onChange={(val) => this.handleValue('team_type', val,
                    selectIndividualDriver ? this.loadDrivers : this.loadLocations)}
                  maxMenuHeight={200}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                  block w-full sm:text-sm border-gray-300 rounded-md"
                  name="team_type"
                  classNamePrefix="select"
                  isDisabled={isLoading}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                Company
              </label>
              <div className="mt-1">
                {_.isArray(companies) && companies.length > 1 && (
                  <Select
                    value={selectedCompanies}
                    options={companies}
                    onChange={(opts) => this.onChangeSelect(opts, 'Company')}
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    selectProps={{ isLimit: false }}
                    components={{
                      Option: selectOptionsCheckbox,
                      ValueContainer: selectContainer,
                      MenuList: MenuSelectList,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                    maxMenuHeight={300}
                    className="block w-full sm:text-sm rounded-md"
                    name="totals_companies"
                    placeholder="Select company..."
                    classNamePrefix="select"
                    styles={defaultStyles}
                  />
                )}
              </div>
            </div>

            <div className="sm:col-span-6 mt-2 -mb-2">
              <FormRadioCheckBox
                label="Select individual driver(s)"
                checked={selectIndividualDriver}
                isDisabled={isLoading}
                onCheck={this.toggleSelectIndividualDrivers}
              />
            </div>

            {selectIndividualDriver
              ? (
                <div className="sm:col-span-6">
                  <label htmlFor="drivers" className="block text-sm font-medium text-gray-700">
                    Drivers
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedDrivers}
                      options={driversList}
                      onChange={(opts) => this.onChangeSelect(opts, 'Drivers')}
                      maxMenuHeight={300}
                      isMulti
                      styles={defaultStyles}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      menuPlacement="top"
                      isDisabled={isLoading}
                      components={{
                        Option: selectOptionsCheckbox,
                        ValueContainer: selectContainer,
                        DropdownIndicator,
                        ClearIndicator,
                      }}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                      block w-full sm:text-sm border-gray-300 rounded-md"
                      name="drivers"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              )
              : (
                <div className="sm:col-span-6">
                  <label htmlFor="hubs" className="block text-sm font-medium text-gray-700">
                    Locations
                  </label>
                  <div className="mt-1">
                    <Select
                      value={selectedLocations || []}
                      options={locations}
                      onChange={(opts) => this.onChangeSelect(opts, 'Locations')}
                      maxMenuHeight={300}
                      isMulti
                      styles={defaultStyles}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      menuPlacement="top"
                      isDisabled={isLoading}
                      components={{
                        Option: selectOptionsCheckbox,
                        ValueContainer: selectContainer,
                        DropdownIndicator,
                        ClearIndicator,
                      }}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                      block w-full sm:text-sm border-gray-300 rounded-md"
                      name="locations"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              )}

            <UploadWrapperDND customClass="sm:col-span-6" onDrop={this.onDropFile}>
              <Upload extension="image" file={file} handleFileInput={this.handleFileInput} />
            </UploadWrapperDND>

          </div>

          <div className="pt-5">
            <FormHandlersWrapper>
              <FormCancel onCancel={() => setOpen(false)} />
              <FormSave isLoading={isLoading} />
            </FormHandlersWrapper>
          </div>
        </form>
      </ModalWrapper>
    );
  }
}

ModalDriverAddSafety.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  httpRequest: PropTypes.func.isRequired,
};

ModalDriverAddSafety.defaultProps = {
  open: false,
  setOpen: () => {},
};

export default _.flowRight([
  withAPI,
  withRouter,
])(ModalDriverAddSafety);
