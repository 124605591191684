import _ from 'lodash';
import React from 'react';

function withMultiSelector(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAllCompanies: true,
        companies: [],
        selectedCompanies: [],
      };
    }

    initSelector = (isAllCompanies, companies, selectedCompanies, handleChanges) => {
      this.setState({
        isAllCompanies,
        companies,
        selectedCompanies,
      });
      if (typeof handleChanges === 'function') {
        this.handleChanges = handleChanges;
      }
    }

    onChangeSelect = (opts) => {
      const { companies, isAllCompanies } = this.state;
      const find = opts.filter((i) => i.value === '');
      if (isAllCompanies && _.isEmpty(find)) {
        this.setState({
          selectedCompanies: [],
          isAllCompanies: false,
        }, () => this.handleChanges?.());
      } else if (!_.isEmpty(find) && !isAllCompanies) {
        this.setState({
          selectedCompanies: companies,
          isAllCompanies: true,
        }, () => this.handleChanges?.());
      } else if (opts.length + 1 === companies.length && _.isEmpty(find) && !isAllCompanies) {
        this.setState({
          selectedCompanies: companies,
          isAllCompanies: true,
        }, () => this.handleChanges?.());
      } else if ((opts.length < companies.length) && !_.isEmpty(find)) {
        this.setState({
          selectedCompanies: opts.filter((i) => i.value !== ''),
          isAllCompanies: false,
        }, () => this.handleChanges?.());
      } else {
        this.setState({
          selectedCompanies: opts,
          isAllCompanies: false,
        }, () => this.handleChanges?.());
      }
    }

    getSelectorData = () => {
      const { isAllCompanies, companies, selectedCompanies } = this.state;
      return ({
        isAllCompanies,
        companies,
        selectedCompanies,
      });
    }

    getProps = () => ({
      initSelector: this.initSelector,
      onChangeSelect: this.onChangeSelect,
      getSelectorData: this.getSelectorData,
    })

    render() {
      return <WrappedComponent {...this.props} {...this.getProps()} />;
    }
  };
}

export default withMultiSelector;
