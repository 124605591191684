import { CheckIcon, TrashIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'

const RTSignature = ({ label, section, item, onDone, value, wrapperClassName = "col-span-6 justify-between grid grid-cols-6 my-3 items-end" }) => {

  const signatureRef = useRef(null);

  const [className, setClassName] = React.useState('border-gray-300');

  useEffect(() => {
    if (value === null) {
      onDone(section.id, item.id, '')
    }
  }, [item.id, onDone, section.id, value])

  const handleChange = async () => {
    const image = signatureRef.current?.getTrimmedCanvas().toDataURL('image/png');

    if (image && !_.isEmpty(signatureRef.current?.toData())) {
      const base64Response = await fetch(image);
      const blob = await base64Response.blob();
      setClassName('border-green-regular');
      onDone(section.id, item.id, blob)
    } else {
      setClassName('border-red-regular');
    }
  }

  const clear = () => {
    signatureRef.current.clear();
    setClassName('border-gray-300');
    onDone(section.id, item.id, '')
  }
  
  return (
    <div className={wrapperClassName}>
      <p className='col-span-2 border-b'>{label}</p>

      {value === null && <>
        <div className='col-span-1 border-b mr-4 grid grid-cols-1 items-center h-full justify-items-end'>
          <button type='button' onClick={clear} className="w-8 h-8">
            <TrashIcon className="w-8 h-8 text-red-regular" />
          </button>
          <button type='button' onClick={handleChange} className="w-8 h-8">
            <CheckIcon className="w-8 h-8 text-green-regular" />
          </button>
        </div>
        <div className='col-span-3'>
          <ReactSignatureCanvas
            ref={signatureRef}
            penColor="#000F55"
            canvasProps={{ className: `col-span-6 w-full border-2 border-dashed rounded-md ${className}`, style: { height: '100px' }}}
          />
        </div>
      </>}

      {value && <>
        <div className='col-span-1 border-b mr-4'></div>
        <div className='col-span-3 w-full border-2 border-dashed rounded-md border-green-regular flex items-center justify-center' style={{height: '100px'}}>
          <img src={value.url} alt="signature" className="h-full p-3" />
        </div>
      </>}

    </div>
  )
}

export default RTSignature