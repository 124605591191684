import React from 'react';
import PropTypes from 'prop-types';

const PageWrapper = ({ children, className }) => (
  <main className="h-full relative z-0 focus:outline-none lg:pl-0 p-5">
    <div
      className={`rounded-2xl p-4 lg:p-6 h-full relative flex flex-col ${className}`}
    >
      {children}
    </div>
  </main>
);

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

PageWrapper.defaultProps = {
  className: 'bg-white border border-black-100',
};

export default PageWrapper;
