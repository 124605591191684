import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";
import { formatPaginationMeta } from "utils";

const useLoadPayrolls = (httpRequest, { page, driverId, payrollDate }) => {
  const [isPayrollLoading, setIsPayrollLoading ] = useState(false);
  const [payrolls, setPayrolls] = useState([]);
  const [pagination, setPagination] = useState({});
  const [errors, setErrors] = useState({});
  const { getCancelToken } = useCancelToken();

  const loadPayrolls = useCallback(async () => {
    if (!payrollDate || !driverId) return;
    setIsPayrollLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `payrolls?page=${page}&date=${payrollDate}&driver_id=${driverId}`,
      getCancelToken: getCancelToken(),
    })
    .catch((e) => {
      if (axios.isCancel(e)) setIsPayrollLoading(true);
      else setErrors(e.response.data);
    })
    if (response) {
      const data = _.get(response, 'data.data', []);
      const paginationMeta = _.get(response, 'data.meta', {});
      setPayrolls(data);
      setPagination(formatPaginationMeta(paginationMeta, true));
      setIsPayrollLoading(false);
    }
  }, [httpRequest, page, getCancelToken, driverId, payrollDate])

  return { payrolls, isPayrollLoading, setIsPayrollLoading, loadPayrolls, pagination, errors, setPayrolls }
  
}

export default useLoadPayrolls