import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadVacationMeta = (httpRequest) => {
  const [holidayTypes, setHolidayTypes] = useState({});
  const [holidayStatuses, setHolidayStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadVacationsMeta = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: 'vacations/meta',
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', {});
      const { holiday_types: holidayTypes, holiday_statuses: holidayStatuses } = data;

      setIsLoading(false);
      setHolidayStatuses(holidayStatuses);
      setHolidayTypes(holidayTypes);
    }
  }, [httpRequest, getCancelToken])

  useEffect(() => {
    loadVacationsMeta();
  }, [loadVacationsMeta])

  return ({ holidayStatuses, holidayTypes, isLoading, loadVacationsMeta });
}

export default useLoadVacationMeta;