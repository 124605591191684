import { useCancelToken } from "hooks";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

const useLoadFAQ = (httpRequest, faqId) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { getCancelToken } = useCancelToken();

  const loadFAQ = useCallback(async () => {
    setIsLoading(true);

    const response = await httpRequest({
      method: 'get',
      url: `/faq/${faqId}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      const isCancel = axios.isCancel(error)
      setIsLoading(isCancel);
      if (!isCancel) {
        setErrors(error?.response.data);
      }
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken, faqId])

  useEffect(() => {
    loadFAQ();
  }, [loadFAQ])

  return ({ data, isLoading, loadFAQ, setData, errors, setErrors });
}

export default useLoadFAQ;