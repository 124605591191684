import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const labelStyles = {
  'display'         : '-webkit-box',
  'WebkitLineClamp' : '2',
  'WebkitBoxOrient' : 'vertical',
}

const SelectOptionsCheckbox = ({ label, isSelected, overflowLabel, ...other }) => {
  const clampedElement = useRef(null);
  const isTextClamped = clampedElement.current?.scrollHeight > clampedElement.current?.clientHeight;

  return (
    <div data-testid="select-option">
      <components.Option
        {...other}
        className={`${isSelected ? 'bg-green-50' : 'bg-gray-100'} mt-2.5 relative w-full`}
        >
        <div
          className={`${isSelected ? 'bg-green-500' : 'bg-gray-200'} w-0.5 h-full absolute left-0 top-0`}
        />
        <label
          className="focus:ring-indigo-500 h-4 w-4
        text-indigo-600 border-gray-300 rounded-full relative"
          htmlFor="label"
        >
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => null}
              className={`rounded-full w-5 h-5 border-0 ${isSelected ? 'text-green-500' : ''}`}
            />
            <div
              className={`ml-2 text-sm font-medium ${other?.data?.disabled ? 'text-gray-400' : 'text-gray-900'}`}
              id="label"
            >
              <div
                className="flex items-end"
              >
                <div
                  className="break-words overflow-y-hidden"                 
                  style={labelStyles}
                  ref={clampedElement}
                >
                  {label}
                </div>
                {isTextClamped && (other?.data?.overflowLabel ?? '')}
              </div>
            </div>
          </div>
        </label>
      </components.Option>
    </div>
  );
};

SelectOptionsCheckbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

SelectOptionsCheckbox.defaultProps = {
  label: '',
};

export default SelectOptionsCheckbox;
