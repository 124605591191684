import React from 'react';
import Echo from 'laravel-echo';
import Socketio from 'socket.io-client';

function withEcho(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.echoClient = new Echo({
        broadcaster: 'socket.io',
        client: Socketio,
        host: `${process.env.REACT_APP_SOCKET_BASE_URL}:${process.env.REACT_APP_LARAVEL_ECHO_SERVER_PORT}`,
      });
    }

    componentWillUnmount() {
      this.echoClient.leave();
    }

    render() {
      const rest = {
        echoClient: this.echoClient,
      };
      return <WrappedComponent {...this.props} {...rest} />;
    }
  };
}

export default withEcho;
