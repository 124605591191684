import { ChevronRightIcon, XIcon } from '@heroicons/react/outline';
import { getFormattedDate } from 'components/dateHandle';
import { PreviewLayout } from 'components/layouts';
import _ from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { NotificationView } from '.';
import { NoResults } from '..';
import { useMarkAsRead } from '../hooks';

const NotificationListBar = ({ httpRequest, notifications, setNotifications, open, setOpen }) => {

  const [selectedNotification, setSelectedNotification] = useState(null);
  const { readNotification, isLoading } = useMarkAsRead(httpRequest, { id: selectedNotification?.id })

  useEffect(() => {
    return () => setSelectedNotification(null);
  }, [open])

  const onClickNext = async () => {
    const selectedNotificationIndex = _.findIndex(notifications, selectedNotification);
    readNotification().then(() => {
      const arr = _.cloneDeep(notifications);
      arr?.splice(selectedNotificationIndex, 1)
      setNotifications(arr);
      if (arr?.length > selectedNotificationIndex) { setSelectedNotification(arr[selectedNotificationIndex]); return; };
      if (arr?.length > 0) { setSelectedNotification(arr[0]); return; }
      if (arr?.length === 0) setSelectedNotification(null);
    })
  }

  return (
    <PreviewLayout open={open} setOpen={setOpen}>
      <div onClick={(e) => e.stopPropagation() } className="bg-white h-full xl:w-1/3 md:w-1/2 w-full ml-auto relative py-3 overflow-auto">
        {selectedNotification ? (
          <NotificationView
            notification={selectedNotification}
            onClose={() => setSelectedNotification(null)}
            onClickNext={onClickNext}
            isLoading={isLoading}
          />
        )
          : (
            <>
              <div className="flex justify-between items-center px-3">
                <span className="font-semibold">Notifications</span>
                <XIcon
                  className="w-5 h-5 cursor-pointer text-gray-dark"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="flex flex-col relative">
                <NoResults isVisible={notifications?.length === 0} centerContent />
                {notifications?.map((notification) => (
                  <Notification
                    key={`${notification?.created_at} ${notification?.id} ${notification?.text}`}
                    notification={notification}
                    onClick={() => setSelectedNotification(notification)}
                  />
                ))}
              </div>
            </>
          )
        }
      </div>
    </PreviewLayout>
  )
}

export default NotificationListBar;

const notificationPreviewStyles = {
  'display'         : '-webkit-box',
  'WebkitLineClamp' : '3',
  'WebkitBoxOrient' : 'vertical',
  'paddingBottom'   : '1px'  
}


const Notification = ({ notification, onClick }) => (
  <button onClick={onClick} className="flex flex-col py-4 gap-1 border-b px-3 hover:bg-black-200">
    <div className="flex items-center justify-between w-full pl-4">
      <div className="flex flex-col gap-1 mr-auto items-start w-full">
        <span className="text-sm">
          {getFormattedDate(notification?.created_at)}
        </span>
        <div className="relative w-full pr-2.5">
          <div className="w-2 h-2 rounded-full bg-green-regular top-0.5 -left-4 absolute" />
          <div
            style={notificationPreviewStyles}
            className="text-left overflow-y-hidden leading-none"
          >
            {notification?.message}
          </div>
          <ChevronRightIcon className="w-5 h-5 min-w-max absolute top-1/2 transform -translate-y-1/2 -right-2" />
        </div>
      </div>
    </div>
  </button>
)