import { ChevronLeftIcon } from '@heroicons/react/outline'
import { getFormattedDate } from 'components/dateHandle'
import { BtnLoadingIndicator } from 'components/loading-indicator'
import ImagePreview from 'components/modal/image-preview'
import React, { useState } from 'react'

const NotificationView = ({ notification, onClose, onClickNext, isLoading }) => {
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  return (
    <div className="px-3">
      {notification?.url && (
        <ImagePreview
          open={isImagePreviewOpen}
          setOpen={setIsImagePreviewOpen}
          url={notification?.url}
        />
      )}
      <div className="flex justify-between items-center px-3">
        <span className="font-semibold">Notifications</span>
        <button type="button" className="flex" onClick={() => onClose(false)}>
          <ChevronLeftIcon className="w-6 h-6 cursor-pointer"/>
          <span>Back</span>
        </button>
      </div>
      <div className="flex flex-col mt-6 gap-1 mr-auto px-3 items-start">
        <span className="text-sm">
          {getFormattedDate(notification?.created_at)}
        </span>
        {notification?.message}
        {notification?.url && (
          <img
            src={notification?.url}
            alt="attached"
            onClick={() => setIsImagePreviewOpen(true)}
            className="object-cover w-full max-h-full cursor-pointer"
          />
        )}
        <button
          onClick={onClickNext}
          className="bg-green-regular mt-1 rounded-md text-white px-12 py-1.5 flex ml-auto"
          disabled={isLoading}
        >
          <BtnLoadingIndicator isLoading={isLoading} />
          Next
        </button>
      </div>
    </div>
  )
}

export default NotificationView