import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class FormTextarea extends PureComponent {
  render() {
    const {
      label, value, handleValue, placeholder, hint, rows,
      isRequired, isHighlighted = false,
    } = this.props;
    return (
      <div className="sm:col-span-6 flex flex-col justify-between">
        <label htmlFor={label} className="block text-sm font-medium text-gray-700">
          {label}
          {isRequired && (
            <sup className="text-red-regular">*</sup>
          )}
        </label>
        <div className="mt-1">
          <textarea
            type="text"
            name={label}
            id={label}
            value={value || ''}
            placeholder={placeholder}
            rows={rows}
            className={`shadow-sm focus:ring-indigo-500
                block w-full sm:text-sm ${isHighlighted ? "border-red-300 focus:border-red-300"
                : "border-gray-300 focus:border-indigo-500"} rounded-md`}
            onChange={(e) => handleValue(e.target.value)}
          />
        </div>
        {hint && (
          <p className="mt-2 text-sm text-gray-500">{hint}</p>
        )}
      </div>
    );
  }
}
FormTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
};

FormTextarea.defaultProps = {
  rows: 2,
  hint: '',
  placeholder: '',
  isRequired: false,
};

export default FormTextarea;
