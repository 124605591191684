import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import withUser from '../../hocs/with-user';

class Logout extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedOut: false,
    };
  }

  componentDidMount() {
    const { logout } = this.props;
    logout();
    this.setState({
      isLoggedOut: true,
    });
  }

  render() {
    const { isLoggedOut } = this.state;
    if (isLoggedOut) {
      return <Redirect to="/login" />;
    }
    return null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default compose(
  withUser,
)(Logout);
