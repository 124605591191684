import { InformationCircleIcon } from '@heroicons/react/outline';
import WithPermission from 'modules/with-permission';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FAQInformationTooltip = ({ tooltip = "View more info about this section in our Knowledge Base", hash, className = "w-5 h-5 mt-1" }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <WithPermission permissionsCheck={['CompaniesResources']}>
      <Link to={`/knowledge-base#${hash}`} >
        <InformationCircleIcon
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={className}
          data-testid="information-icon"
        />
        {isHovered && tooltip && (
          <div
            style={{ zIndex: '100' }}
            data-testid="faqs-tooltip"
            className="text-sm w-max absolute left-0 top-6 p-3 bg-gray-100
              border border-black-100 rounded-2xl text-left"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {tooltip}
          </div>
        )}
      </Link>
    </WithPermission>
  )
}

export default FAQInformationTooltip;