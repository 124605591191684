import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";
import qs from 'query-string';

const useLoadTimeLogged = (httpRequest, { payrolls }) => {
  const [timeLogged, setTimeLogged] = useState([]);
  const [isTimeLoggedLoading, setIsTimeLoggedLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadTimeLogged = useCallback(async () => {

    if (!payrolls || _.isEmpty(payrolls)) return;

    setIsTimeLoggedLoading(true);
    const query = qs.stringify({
      payrolls: _.map(payrolls, 'id'),
    }, { arrayFormat: 'bracket' });

    const response = await httpRequest({
      method: 'get',
      url: `payrolls/time-logged?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsTimeLoggedLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsTimeLoggedLoading(false);
      setTimeLogged(data);
    }
  }, [httpRequest, getCancelToken, payrolls])

  return ({ timeLogged, isTimeLoggedLoading, loadTimeLogged });
}

export default useLoadTimeLogged