import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

const PreviewLayout = ({ open, setOpen, children }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
        data-testid="approve-reject-modal"
      >
        <div
          className="w-full h-full flex bg-black-600 bg-opacity-75"
          onClick={() => setOpen(false) }
        >
          {children}
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PreviewLayout;