import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import withAPI from 'hocs/with-api';
import ValidationError from 'components/notifications/validation-errors';
import RTPlaceholder from './road-test-components/placeholder';
import RTText from './road-test-components/text';
import RTSignature from './road-test-components/signature';
import RTRadio from './road-test-components/radio';
import RTynna from './road-test-components/ynna';
import RTCheckbox from './road-test-components/checkbox';
import { FormHandlersWrapper } from 'components/form';
import { DefaultButton } from 'components/buttons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RejectButton from 'components/buttons/reject-button';

class DriverRoadTestDataForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      candidate: {},
      errors: {},
      isLoading: true,
      isCompleted: false,
      values: {},
      files: [],
      pass: false,
      page: 1,
      lastPage: null,
    };
  }

  async componentDidMount() {
    document.title = `Driver road test data - ${process.env.REACT_APP_NAME}`;
    window.onpopstate = this.onBackButtonClick;

    this.loadCandidate()
    .then(() => {this.setState({ isLoading: false })});
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  getSecret = () => {
    const { match: { params: { secret } } } = this.props;
    return secret;
  }

  loadCandidate = async () => {
    const { httpRequest, history } = this.props;
    const secret = this.getSecret();
    const response = await httpRequest({
      method: 'get',
      url: `candidates/data-road-test/${secret}`,
      noToken: true,
    }).catch(() => history.push('/'));
    if (response) {
      const {
        data: {
          data,
        },
      } = response;

      const {
        candidate, form,
      } = data;

      let isCompleted = candidate.road_test_data?.is_completed || false

      this.setState({
        form,
        isCompleted,
        values: candidate.road_test_data?.data?.form || {},
        candidate,
        isLoading: false,
        lastPage: parseInt(form?.pages[form?.pages.length - 1]['page'] || 0),
      });

      if (isCompleted) {
        history.push({pathname: '/login'});
      }
    }
  }

  onSubmit = async () => {
    const { values } = this.state
    const { httpRequest } = this.props
    const secret = this.getSecret()

    this.setState({
      isLoading: true,
    });

    const response = await httpRequest({
      method: 'post',
      url: `candidates/data-road-test/${secret}`,
      data: values,
    }).catch((error) => {
      this.notify(error.response.data.message)
      this.setState({
        errors: error.response.data,
        isLoading: false,
      })
    })

    if (response) {
      this.onSubmitFiles()
    }
  }

  onSubmitFiles = async () => {
    const { files, pass } = this.state
    const { httpRequest, history } = this.props
    const secret = this.getSecret()

    const values = new FormData();
    values.append('pass', pass ? 1 : 0)
    Object.keys(files).forEach(key => {
      values.append(key, files[key])
    })

    const response = await httpRequest({
      method: 'post',
      url: `candidates/data-road-test/files/${secret}`,
      data: values,
    }).catch((error) => {
      this.notify(error.response.data.message)
      this.setState({
        errors: error.response.data,
      })
    }).finally(() => {
      this.setState({
        isLoading: false,
      })
    })

    if (response) {
      history.push({
        pathname: '/login',
        flash: {
          type: 'success',
          message: 'Driver road test data updated successfully',
        },
      });
    }
  }

  notify = (string) => toast.warn(string, {
    position: "bottom-right",
  });

  handleChanges = (sectionId, itemId, value) => {
    const { values } = this.state
    if (values[sectionId] === undefined) {
      values[sectionId] = {}
    }
    values[sectionId][itemId] = value
    this.setState({ values });
  }

  handleSignature = (sectionId, itemId, file) => {
    const { files } = this.state
    files[sectionId + "###" + itemId] = file
    this.setState({ files });
  }

  handlePage = (selectedPage) => {
    const { page } = this.state
    this.setState({ page: page + selectedPage}, window.scrollTo({top: 0, behavior: "smooth"}));
  }

  markAll = (value, section) => {
    const { values } = this.state;
    let ynna = value ? "Y" : null;
    const copyValues = _.cloneDeep(values);
    section.items
      .filter((item) => item.type === "YNNA")
      .forEach((item) => {
        copyValues[section.id][item.id] = ynna;
      });
    this.setState({ values: copyValues });
  };

  render() {
    const {
      errors,
      form,
      candidate,
      values,
      isLoading,
      isCompleted,
      pass,
      page,
      lastPage,
    } = this.state;

    const components = (section, item) => {
      switch(item.type) {
        case "placeholder":
          return <RTPlaceholder label={item.question} key={section.id+item.id}/>;
        case "text":
          return <RTText section={section} item={item} key={section.id+item.id} candidate={candidate}
                  onChange={this.handleChanges} value={values[section.id]?.[item.id] ?? null} />;
        case "signature":
          return <RTSignature section={section} item={item} key={section.id+item.id}
                  label={item.question} onDone={this.handleSignature} value={values[section.id]?.[item.id] ?? null} />;
        case "radio":
          return <RTRadio section={section} item={item} key={section.id+item.id}
                  onChange={this.handleChanges} value={values[section.id]?.[item.id] ?? null} />;
        case "YNNA":
          return <RTynna section={section} item={item} key={section.id+item.id}
                  onChange={this.handleChanges} value={values[section.id]?.[item.id] ?? null} />;
        case "checkbox":
          return <RTCheckbox section={section} item={item} key={section.id+item.id}
                  onChange={this.handleChanges} value={values[section.id]?.[item.id] ?? null} />;
        default: return <></>
      }
    }

    return (
      <main
        className="lg:flex-1 relative z-0 overflow-y-auto focus:outline-none p-5"
      >
        <div className="rounded-2xl bg-white border border-black-100 p-4 lg:p-6 mt-2">
          {!isCompleted && <form id="entity-form">
            <div>
              <div className="sm:flex sm:items-center sm:justify-between mb-5">
                <div className="flex-1 min-w-0 mb-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">{form.name}</h3>
                  <p className="mt-1 text-sm text-gray-500 text-center">
                    Add driver data for {candidate.first_name} {candidate.last_name}
                  </p>
                </div>
              </div>

              <div className="container max-w-3xl">
                <div>
                  {
                    !_.isEmpty(errors) && (
                      <ValidationError errors={errors} addClass="sm:col-span-6" />
                    )
                  }
                  
                  {_.isArray(form.pages) && form.pages.map((pageData) => (
                    <div key={pageData.page}>
                      {page === parseInt(pageData.page) && <div key={pageData.page} className="mt-6 grid gap-y-4 gap-x-4 grid-cols-6">
                        {_.isArray(pageData.sections) && pageData.sections.map((section) => (
                          <div className="col-span-6 mb-6" key={section.id}>
                            <h3 className='font-bold'>{section.title}</h3>
                            {section.description.length > 0 && <p className='whitespace-pre-line'>{section.description}</p>}

                            {section.bulk && <div className='col-span-6 justify-between grid grid-cols-6 my-3 items-end'>
                              <p className='col-span-5 border-b mr-4 whitespace-pre-line font-bold'>{section.bulk.label}</p>
                              <p className='col-span-1 grid grid-cols-3'>
                                <label className="block text-sm font-medium text-gray-700">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => this.markAll(e.target.checked, section)}
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mb-1" />
                                  <span className="ml-1 text-sm">Y</span>
                                </label>
                              </p>
                            </div>}

                            {_.isArray(section.items) && <div className='mt-4'>
                              {section.items.map((item) => (
                                components(section, item)
                              ))}
                            </div>}
                          </div>
                        ))}
                      </div>}
                    </div>
                  ))}

                  {page < lastPage && (
                    <div className='mb-6 text-right'>
                      {page > 1 && <DefaultButton customStyles='mr-4' onClick={() => this.handlePage(-1)} label="Previous" />}
                      <DefaultButton onClick={() => this.handlePage(1)} label="Next" />
                    </div>
                  )}

                  {page === lastPage && (
                    <FormHandlersWrapper className='text-sm col-span-6 mt-1 mb-6'>
                      <div class="text-left w-full">
                        <DefaultButton disabled={isLoading} onClick={() => this.setState({ page: page - 1 })} label="Previous" />
                      </div>
                      <RejectButton disabled={isLoading} onClick={() => { this.setState({ pass: false }, this.onSubmit) }} label="Fail" />
                      <DefaultButton disabled={isLoading} onClick={() => { this.setState({ pass: true }, this.onSubmit) }} label="Pass" />
                    </FormHandlersWrapper>
                  )}

                </div>
              </div>
            </div>
            <ToastContainer />
          </form>}
        </div>
      </main>
    );
  }
}

DriverRoadTestDataForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
};

export default _.flowRight([withAPI, withRouter])(DriverRoadTestDataForm);