import React from 'react';
import PropTypes from 'prop-types';

const TableWrapper = React.forwardRef(({ children }, ref) => (
  <div className="h-full overflow-auto relative" ref={ref}>
    {children}
  </div>
));

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableWrapper;
