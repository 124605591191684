import React, { PureComponent } from 'react';

export default class PrivacyPolicy extends PureComponent {
  render() {
    return (
      <>
        <main className="lg:flex-1 relative z-0 overflow-y-auto focus:outline-none pl-5 lg:pl-0 pr-5 py-5">
          <div className="rounded-2xl bg-white border border-black-100 p-4 lg:p-6 ">
            <div className="overflow-auto break-words lg:p-0 p-2 md:p-4">
              <h1 className="text-center font-bold sm:text-4xl mt-2 text-2xl">Privacy Policy</h1>
              <div className="lg:w-2/4 m-auto sm:text-lg text-md">
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">Introduction</h2>
                  <p className="mt-2">
                    GroundTeq LLC
                    <span className="font-bold">
                      (&quot;Company,&quot;&quot;we&quot;
                    </span>
                    or
                    <span className="font-bold">
                      &quot;us&quot;)
                    </span>
                    respects your privacy and
                    is committed to protecting it through our compliance with this Policy.
                  </p>
                  <p className="mt-2">
                    This Policy describes the types of information we may collect from you or that
                    you may provide when you visit our Website
                    {' '}
                    <a href="https://www.groundteq.com" rel="noreferrer" target="_blank">
                      https://www.groundteq.com
                    </a>
                    (&quot;Website&quot;) and our practices for collecting, using,
                    maintaining, protecting, and disclosing that information.
                  </p>
                  <p className="mt-2">
                    This Policy applies to information we collect:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">On and/or through our Website.</li>
                    <li
                      className="list-disc"
                    >
                      In email, text, and other electronic
                      messages between you and our Website.
                    </li>
                    <li className="list-disc">
                      Through mobile and desktop applications you download
                      from our Website, which provide dedicated non-browser-based
                      interaction between you and our Website.
                    </li>
                    <li className="list-disc">
                      When you interact with our advertising and applications on
                      third-party websites and services if those applications or advertising
                      include links to this Policy.
                    </li>
                  </ul>
                  <p className="mt-2">
                    It does not apply to information collected by:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      Us offline or through any other means, including on
                      any other website operated by us or any third party
                      (including our affiliates and subsidiaries);
                    </li>
                    <li className="list-disc">
                      Any third party (including our affiliates and subsidiaries), including
                      through any application or content
                      (including advertising) that may link to or be accessible
                      from or through our Website.
                    </li>
                  </ul>
                  <p className="mt-2">
                    Please read this Policy carefully to understand our policies
                    and practices regarding your information and how we will treat it.
                    If you do not agree with our policies and practices, your choice is to
                    not use our Website. By accessing or using our Website, you agree to this
                    Privacy Policy. This Policy may change from time to time
                    {' '}
                    <span className="font-bold">
                      (see Changes to Our Privacy Policy).
                    </span>
                    {' '}
                    Your continued use of our
                    Website after we make changes is deemed to be acceptance of those changes,
                    so please check this Policy periodically for updates.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Children Under the Age of 18
                  </h2>
                  <p className="mt-2">
                    Our Website is not intended for children under 18 years of age.
                    No one under age 18 may provide any information to or on our Website.
                    We do not knowingly collect personal information from children under 18.
                    If you are under 18, do not use or provide any information on our Website
                    or through any of its features.  If we learn we have collected or received personal
                    information from a child under 18 without verification of parental consent, we will delete
                    that information. If you believe we might have any information from or about a child under 18,
                    please contact us at
                    {' '}
                    <a href="tel:1-239-944-0473">1-239-944-0473</a>
                  </p>
                </section>
                <section className="mt-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Information We Collect About You and How We Collect It
                  </h2>
                  <p className="mt-2">
                    We collect several types of information from and about
                    users of our Website, including information:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      By which you may be personally identified, such as name,
                      postal address, email address, telephone number,
                      and the like
                      <span className="font-bold">
                        (&quot;personal information&quot;);
                      </span>
                    </li>
                    <li className="list-disc">
                      That is about you but individually does not identify you.
                    </li>
                    <li>
                      About your Internet connection, the equipment you use to access our Website, and usage details
                    </li>
                  </ul>
                  <p className="mt-2">
                    We collect this information:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">Directly from you when you provide it to us.</li>
                    <li className="list-disc">
                      Automatically as you navigate through our Website.
                      Information collected automatically may include usage details,
                      IP addresses, and information collected through cookies,
                      web beacons, and other tracking technologies.
                    </li>
                    <li className="list-disc">From third parties, for example, our business partners.</li>
                  </ul>
                  <p className="italic">Information You Provide to Us</p>
                  <p>
                    The information we collect on or through our Website may include:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      Information that you provide by filling
                      in forms on our Website. This includes
                      information provided at the time of registering
                      to use our Website, subscribing to our service, posting material,
                      or requesting further services. We may also ask you for information
                      when you report a problem with our Website.
                    </li>
                    <li className="list-disc">
                      Records and copies of your correspondence (including email addresses) if you contact us.
                    </li>
                    <li className="list-disc">
                      Your responses to surveys that we might ask you to complete for research purposes.
                    </li>
                    <li className="list-disc">
                      Details of transactions you carry out
                      through our Website and of the fulfillment
                      of your orders. You may be required to provide
                      financial information before placing an order through our Website.
                    </li>
                    <li className="list-disc">
                      Your search queries on our Website.
                    </li>
                  </ul>
                  <p className="italic">Information We Collect Through Automatic Data Collection Technologies</p>
                  <p className="mt-2">
                    As you navigate through and interact with our Website, we may use automatic data collection
                    technologies to collect certain information about your
                    equipment, browsing actions, and patterns, including:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      Details of your visits to our Website, including traffic data,
                      location data, logs, and other communication data and the
                      resources that you access and use on our Website.
                    </li>
                    <li className="list-disc">
                      Information about your computer and Internet connection, including your
                      operating system and browser type.
                    </li>
                  </ul>
                  <p className="mt-2">
                    The information we collect automatically is only
                    statistical data and does not include personal information,
                    but we may maintain it or associate it with personal information
                    we collect in other ways or receive from third parties. It helps us to
                    improve our Website and to deliver a better and more personalized
                    service, including by enabling us to:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">Estimate our audience size and usage patterns.</li>
                    <li className="list-disc">
                      Store information about your preferences, allowing us
                      to customize our Website according to your individual interests.
                    </li>
                    <li className="list-disc">
                      Speed up your searches.
                    </li>
                    <li className="list-disc">
                      Recognize you when you return to our Website.
                    </li>
                  </ul>
                  <p className="mt-2">
                    The technologies we use for this automatic data collection may include:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      <span className="font-bold">
                        Cookies (or browser cookies).
                      </span>
                      A cookie is a small file placed on
                      the hard drive of your computer. You may refuse to accept browser
                      cookies by activating the appropriate setting on your browser.
                      However, if you select this setting, you may be unable to access
                      certain parts of our Website. Unless you have adjusted your browser
                      setting so that it will refuse cookies, our system will issue
                      cookies when you direct your browser to our Website.
                    </li>
                    <li className="list-disc">
                      <span className="font-bold">
                        Web Beacons.
                        {' '}
                      </span>
                      Pages of our Website and our emails may contain small electronic
                      files known as web beacons (also referred to as clear gifs, pixel tags,
                      and single-pixel gifs) that permit us, for example, to count users who have
                      visited those pages or opened an email and for other related Website
                      statistics (for example, recording the popularity of certain Website
                      content and verifying system and server integrity).
                    </li>
                    <li className="list-disc">
                      <span className="font-bold">
                        Flash Cookies.
                        {' '}
                      </span>
                      Certain features of our Website may use local stored objects
                      (or Flash cookies) to collect and store information about your
                      preferences and navigation to, from, and on our Website. Flash
                      cookies are not managed by the same browser settings as are
                      used for browser cookies. For information about managing
                      your privacy and security settings for Flash cookies, see
                      {' '}
                      <span className="font-bold underline">
                        Choices About How We Use and Disclose Your Information.
                      </span>
                    </li>
                  </ul>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Third-Party Use of Cookies and Other Tracking Technologies
                  </h2>
                  <p className="mt-2">
                    Some content or applications on our Website are
                    served by third-parties, including content providers,
                    and application providers. These third parties may use
                    cookies alone or in conjunction with web beacons or other
                    tracking technologies to collect information about you
                    when you use our Website. The information they collect may
                    be associated with your personal information or they may
                    collect information, including personal information, about
                    your online activities over time and across different websites
                    and other online services. We do not control these third parties&apos;
                    tracking technologies or how they may be used. If you have any
                    questions, you should contact the responsible provider directly.
                    For information, see
                    {' '}
                  </p>
                  <span className="font-bold underline">
                    Choices About How We Use and Disclose Your Information.
                  </span>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    How We Use Your Information
                  </h2>
                  <p className="mt-2">
                    We use information that we collect about you or that you provide
                    to us, including any personal information:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      To present our Website and its contents to you.
                    </li>
                    <li className="list-disc">
                      To provide you with information, products, or services that you request from us.
                    </li>
                    <li className="list-disc">
                      To fulfill any other purpose for which you provide it.
                    </li>
                    <li className="list-disc">
                      To provide you with notices about your account/subscription, including
                      expiration and renewal notices.
                    </li>
                    <li className="list-disc">
                      To carry out our obligations and enforce our rights arising from any
                      contracts entered into between you and us, including for billing and collection.
                    </li>
                    <li className="list-disc">
                      To notify you about changes to our Website or any
                      products or services we offer or provide though it.
                    </li>
                    <li className="list-disc">
                      To allow you to participate in interactive features on our Website.
                    </li>
                    <li className="list-disc">
                      In any other way we may describe when you provide the information.
                    </li>
                    <li className="list-disc">
                      For any other purpose with your consent.
                    </li>
                  </ul>
                  <p className="mt-2">
                    We may also use your information to contact you about our own and/or
                    third-parties&apos; goods and services that may be of interest
                    to you. If you do not want us to use your information in this way, please see
                    {' '}
                  </p>
                  <span className="font-bold underline">
                    Choices About How We Use and Disclose Your Information.
                  </span>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Disclosure of Your Information
                  </h2>
                  <p className="mt-2">
                    We may disclose aggregated information about our users, and
                    information that does not identify any individual, without restriction.
                  </p>
                  <p className="mt-2">
                    We may disclose personal information that
                    we collect or you provide as described in this Privacy Policy:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">To our subsidiaries and affiliates.</li>
                    <li className="list-disc">
                      To contractors, service providers, and other third parties we
                      use to support our business and who are bound by contractual
                      obligations to keep personal information confidential and use
                      it only for the purposes for which we disclose it to them.
                    </li>
                    <li className="list-disc">
                      To a buyer or other successor in the event of a merger,
                      divestiture, restructuring, reorganization, dissolution,
                      or other sale or transfer of some or all of the our assets,
                      whether as a going concern or as part of bankruptcy,
                      liquidation, or similar proceeding, in which personal
                      information held by us about our Website users
                      is among the assets transferred.
                    </li>
                    <li className="list-disc">
                      To third parties to market their products or
                      services to you if you have consented to these disclosures.
                      We contractually require these third parties to keep personal
                      information confidential and use it only for the purposes for
                      which we disclose it to them. For more information, see
                      {' '}
                      <span className="font-bold underline">
                        Choices About How We Use and Disclose Your Information.
                      </span>
                    </li>
                    <li className="list-disc">
                      To fulfill the purpose for which you provide it.
                    </li>
                    <li className="list-disc">
                      For any other purpose disclosed by us when you provide the information.
                    </li>
                    <li className="list-disc">
                      With your consent.
                    </li>
                  </ul>
                  <p className="mt-2">
                    We may also disclose your personal information:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      To comply with any court order, law, or legal
                      process, including to respond to any government
                      or regulatory request.
                    </li>
                    <li className="list-disc">
                      To enforce or apply our Website Terms of Use
                      {' '}
                      <a href="https://groundteq.com/terms-of-use" target="_blank" rel="noreferrer">
                        https://groundteq.com/terms-of-use
                      </a>
                      {' '}
                      and other agreements, including for billing and collection purposes.
                    </li>
                    <li className="list-disc">
                      If we believe disclosure is necessary or appropriate
                      to protect the rights, property, or safety of
                      the Company, our customers, or others. This includes
                      exchanging information with other companies and organizations
                      for the purposes of fraud protection and credit risk reduction.
                    </li>
                  </ul>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Choices About How We Use and Disclose Your Information
                  </h2>
                  <p className="mt-2">
                    We strive to provide you with choices regarding the
                    personal information you provide to us. We have
                    created mechanisms to provide you with the
                    following control over your information:
                  </p>
                  <ul className="ml-6">
                    <li className="list-disc">
                      <span className="font-bold">
                        Tracking Technologies.
                      </span>
                      You can set your browser to refuse all or some browser cookies,
                      or to alert you when cookies are being sent. To learn how
                      you can manage your Flash cookie settings, visit the Flash
                      player settings page on Adobe&apos;s website. If you disable or
                      refuse cookies, please note that some parts of this site
                      may then be inaccessible or not function properly.
                    </li>
                    <li className="list-disc">
                      <span className="font-bold">
                        Promotional Offers from the Company.
                      </span>
                      If you do not wish to have your contact information used by us
                      to promote our own or third parties&apos; products or services, you
                      can opt-out by sending us an email stating your request to info@groundteq.com.
                      If we have sent you a promotional email, you may send us a return email asking
                      to be omitted from future email distributions. This opt out does
                      not apply to information provided by us a result of a product or
                      service purchase, subscription, and the like, warranty registration,
                      product or service experience, or other transactions.
                    </li>
                  </ul>
                  <p className="mt-2">
                    We do not control third parties&apos; collection or use of your information
                    to serve interest-based advertising. However, these third parties may
                    provide you with ways to choose not to have your information collected
                    or used in this way. You can opt out of receiving targeted ads
                    from members of the Network Advertising Initiative
                    {' '}
                    <span className="font-bold">
                      (&quot;NAI&quot;)
                    </span>
                    {' '}
                    on the NAI&apos;s website.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Accessing and Correcting Your Information
                  </h2>
                  <p className="mt-2">
                    You can review and change your personal information by
                    logging into our Website and visiting your Personal
                    Information and/or Company Settings pages.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Your State Privacy Rights
                  </h2>
                  <p className="mt-2">
                    State consumer privacy laws may provide their residents
                    with additional rights regarding our use of their personal
                    information.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Data Security
                  </h2>
                  <p className="mt-2">
                    We have implemented measures designed to secure your personal information
                    from accidental loss and from unauthorized access, use, alteration,
                    and disclosure. The safety and security of your information also
                    depends on you. Where we have given you (or where you have chosen) a
                    user name and/or a password for access to certain parts of our Website,
                    you are responsible for keeping this user name and/or password confidential.
                    We ask you not to share your user name or
                    6password with anyone. We urge you to be careful about giving out
                    information in public areas of our Website. The information you share
                    in public areas may be viewed by any user of our Website.
                  </p>
                  <p className="mt-2">
                    Unfortunately, the transmission of information via the Internet
                    is not completely secure. Although we do our best to protect your
                    personal information, we cannot guarantee the security of your
                    personal information transmitted to our Website. Any transmission
                    of personal information is at your own risk. We are not responsible
                    for circumvention of any privacy settings or security measures
                    contained on our Website.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Changes to Our Privacy Policy
                  </h2>
                  <p className="mt-2">
                    It is our policy to post any changes we make to this Privacy Policy
                    on this page. The date this Privacy Policy was last revised is identified
                    at the top of the page. You are responsible for ensuring we have
                    an up-to-date, active, and deliverable email address for you,
                    and for periodically visiting our Website and this Privacy Policy to check for any changes.
                  </p>
                </section>
                <section className="my-2">
                  <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                    Contact Information
                  </h2>
                  <p className="mt-2">
                    To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:
                  </p>
                  <span>info@groundteq.com</span>
                  <p>or via our toll-free number: </p>
                  <a href="tel:1-239-944-0473">1-239-944-0473</a>
                </section>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
