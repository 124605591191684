import React, { useEffect } from 'react'

const RTCheckbox= ({ section, item, onChange, value = null, wrapperClassName = "col-span-6 justify-between grid grid-cols-6 my-3 items-end" }) => {

  const getArrayValue = () => {
    return Array.isArray(value) ? value : []
  }

  const [arrayValue, setArrayValue] = React.useState(getArrayValue)

  useEffect(() => {
      onChange(section.id, item.id, arrayValue)
  }, [arrayValue, item.id, onChange, section.id])
  
  const handleChange = (target, id) => {
    let arr = [...arrayValue]
    var filteredArray = []    

    if (target.checked) {
      arr.push(id)
      filteredArray = [...new Set(arr)]
    } else {
      filteredArray = arr.filter(e => e !== id)
    }

    setArrayValue(filteredArray)
  }

  return (
    <div className={wrapperClassName}>
      <p className='col-span-3 border-b mr-4'>{item.question}</p>
      <div className='col-span-3 mr-4'>
        {item.options.map((option, index) => (
          <div key={section.id + '-' + item.id + '-' + index} className="flex items-center">
            <label
              htmlFor={section.id + '-' + item.id + '-' + index}
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              <input
                checked={arrayValue.includes(option.id)}
                name={section.id + '-' + item.id + '-' + index}
                id={section.id + '-' + item.id + '-' + index}
                type="checkbox"
                onChange={(e) => handleChange(e.target, option.id)}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              <span className="ml-3">{option.name}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RTCheckbox