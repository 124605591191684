import { getFormattedFullDate } from 'components/dateHandle';
import ModalFilePreview from 'components/modal/file-preview';
import { Td, Thead, Tr } from 'components/table';
import React, { useState } from 'react'
import { getFullName } from 'utils';

const columnNames = ['Resolution', 'Created at', 'Reviewed by' , 'Photos'];

const ResolutionsListView = ({ data }) => {

  const [isPhotoPreviewOpen, setIsPhotoPreviewOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  return (
    <>
      {isPhotoPreviewOpen && (
        <ModalFilePreview
          open={isPhotoPreviewOpen}
          setOpen={(open) => { setIsPhotoPreviewOpen(open); setPhotos([]);}}
          files={photos}
          withNavigationButtons={photos?.length > 1}
          withFilesPreview
        />
      )}
      <table className="w-full divide-y">
        <Thead columnList={columnNames} />
        <tbody className="w-full divide-y">
          {data?.map((item) => (
            <Tr key={item?.id}>
              <Td>{item?.resolution}</Td>
              <Td className="px-2 py-3 whitespace-nowrap">{getFormattedFullDate(item?.created_at)}</Td>
              <Td className="px-2 py-3 whitespace-nowrap">{item?.user ? getFullName(item?.user) : ''}</Td>
              <Td className="px-2 py-3 text-green-regular">
                {item?.photos?.length > 0 && (
                  <button
                      type="button"
                      onClick={() => { setPhotos(item?.photos); setIsPhotoPreviewOpen(true); }}
                      className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
                    >
                    View
                  </button>
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ResolutionsListView