import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateArea from './modules/private';
import AuthComponent from './modules/auth';
import ForgotComponent from './modules/forgot';
import ResetComponent from './modules/reset';
import Logout from './modules/logout';
import Welcome from './modules/welcome';
import PrivacyPolicy from './modules/privacyPolicy';
import TermsOfUse from './modules/terms-of-use';
import './App.css';
import PageLoading from 'components/page/page-loading';
import 'react-datepicker/dist/react-datepicker.css';
import 'leaflet/dist/leaflet.css';
import UserChangeParentCompany from 'modules/users/user-change-parent-company';
import CandidatesChangeParentCo from 'modules/candidates/candidates-change-parent-co';
import VacationOpenStatus from 'modules/vacation/open-status';
import { TokenContextProvider } from 'context/TokenContext';
import DriverRoadTestData from 'modules/candidates/driver-road-test-data';
const DriverDataForm = React.lazy(() => import('./modules/candidates/driver-data'))

const App = () => (
  <TokenContextProvider>
    <Router>
        <Switch>
          <Route path="/login" exact component={AuthComponent} />
          <Route path="/password/reset/change" exact component={ResetComponent} />
          <Route path="/password/reset" exact component={ForgotComponent} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/candidates/driver/data/:secret" exact>
            <Suspense fallback={<PageLoading isLoading />}>
              <DriverDataForm />
            </Suspense>
          </Route>
          <Route path="/candidates/driver/data-road-test/:secret" exact>
            <Suspense fallback={<PageLoading isLoading />}>
              <DriverRoadTestData />
            </Suspense>
          </Route>
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-of-use" exact component={TermsOfUse} />
          <Route path="/users/change-parent-co" component={UserChangeParentCompany} />
          <Route path="/candidates/:candidate_id/change-parent-co" component={CandidatesChangeParentCo} />
          <Route path="/vacation/:secret/:status/:email" component={VacationOpenStatus} />
          <Route path="/" exact component={Welcome} />
            <Route path="" component={PrivateArea} />
        </Switch>
    </Router>
  </TokenContextProvider>
);

export default App;
