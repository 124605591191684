import React, { PureComponent } from 'react';

export default class TermsOfUse extends PureComponent {
  render() {
    return (
      <main className="lg:flex-1 relative z-0 overflow-y-auto focus:outline-none pl-5 lg:pl-0 pr-5 py-5">
        <div className="rounded-2xl bg-white border border-black-100 p-4 lg:p-6 ">
          <div className="overflow-auto break-words lg:p-0 p-2 md:p-4">
            <h1 className="text-center font-bold sm:text-4xl mt-2 text-2xl">Terms of Use</h1>
            <div className="lg:w-2/4 m-auto sm:text-lg text-md">
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Acceptance of the Terms of Use
                </h2>
                <p className="mt-2">
                  These terms of use are entered into by and between you and Evolve
                  Logistics Group, Inc.
                  {' '}
                  <span className="font-bold">
                    (&quot;Company,&quot;  &quot;we,&quot;
                  </span>
                  {' '}
                  or
                  {' '}
                  <span className="font-bold">
                    &quot;us&quot;).
                  </span>
                  The following terms and conditions, together with any documents they
                  expressly incorporate by reference (collectively,
                  <span className="font-bold">
                    &quot;Terms of Use&quot;
                  </span>
                  ),
                  govern your access to and use of
                  {' '}
                  <a href="https://www.groundteq.com">
                    https://www.groundteq.com
                  </a>
                  {' '}
                  including any
                  content, functionality, and services offered on or through
                  {' '}
                  <a href="https://www.groundteq.com">
                    https://www.groundteq.com
                  </a>
                  {' '}
                  <span className="font-bold">
                    (&quot;Website&quot;)
                  </span>
                  , whether as a guest or a registered user.
                </p>
                <p className="mt-2">
                  Please read these Terms of Use carefully before you start to use the Website.
                  <span className="font-bold">
                    By using the Website or by clicking to accept or agree to the Terms of
                    Use when this option is made available to you, you accept and agree to be bound and abide
                    by these Terms of Use and our Privacy Policy, found at
                    {' '}
                    <a href="https://groundteq/privacy-policy">
                      https://groundteq/privacy-policy,
                    </a>
                    {' '}
                    which is incorporated herein by reference.
                  </span>
                  If you do not want to agree to these Terms of Use
                  or the Privacy Policy, you must not access or use the Website.
                </p>
                <p className="mt-2">
                  This Website is offered and available to users who 18 years of age or older.
                  By using this Website, you represent and warrant that you are of
                  legal age to form a binding contract with the Company and meet
                  all of the foregoing eligibility requirements. If you do not
                  meet all of these requirements, you must not access or use the Website.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Changes to the Terms of Use
                </h2>
                <p className="mt-2">
                  We may revise and update these Terms of Use from time to time
                  in our sole discretion. All changes are effective
                  immediately when we post them and apply to all access
                  to and use of the Website thereafter. Your continued use of
                  the Website following the posting of revised Terms of Use means
                  that you accept and agree to the changes. You are expected to
                  check this page each time you access this Website so you are
                  aware of any changes, as they are binding on you.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Accessing the Website and Account Security
                </h2>
                <p className="mt-2">
                  We reserve the right to withdraw or amend this Website,
                  and any service or material we provide on the Website,
                  in our sole discretion without notice. We will not be
                  liable if for any reason all or any part of the Website is
                  unavailable at any time or for any period. From time to time,
                  we may restrict access to some parts of the Website, or the
                  entire Website, to users, including registered users.
                </p>
                <p className="mt-2">
                  You are responsible for both:
                </p>
                <ul className="ml-6">
                  <li className="list-disc">
                    Making all arrangements necessary for you to have access to the Website.
                  </li>
                  <li className="list-disc">
                    Ensuring that all persons who access the Website through your internet
                    connection are aware of these Terms of Use and comply with them.
                  </li>
                </ul>
                <p className="mt-2">
                  To access the Website or some of the resources it offers, you may
                  be asked to provide certain registration details or other information.
                  It is a condition of your use of the Website that all the information
                  you provide on the Website is correct, current, and complete.
                  You agree that all information you provide to register with
                  this Website or otherwise, including, but not limited to,
                  through the use of any interactive features on the Website, is governed by our
                  <span className="font-bold">
                    Website Privacy Policy
                    {' '}
                    <a href="https://groundteq/privacy-policy">
                      https://groundteq/privacy-policy,
                    </a>
                    {' '}
                  </span>
                  and you consent to all actions we take with respect to your information consistent
                  with our Privacy Policy.
                </p>
                <p className="mt-2">
                  If you choose, or are provided with, a user name,
                  password, or any other piece of information as part of our
                  security procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity. You also
                  acknowledge that your account is personal to you and agree not to provide
                  any other person with access to this Website or portions of it using your
                  user name, password, or other security information. You agree to notify us
                  immediately of any unauthorized access to or use of your user name or password
                  or any other breach of security. You also agree to ensure that you exit from
                  your account at the end of each session. You should use particular caution
                  when accessing your account from a public or shared computer so that others
                  are not able to view or record your password or other personal information.
                </p>
                <p className="mt-2">
                  We have the right to disable any user name, password, or other
                  identifier, whether chosen by you or provided by us, at any time
                  in our sole discretion for any or no reason, including if, in our
                  opinion, you have violated any provision of these Terms of Use.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Intellectual Property Rights
                </h2>
                <p className="mt-2">
                  The Website and its entire contents, features, and functionality
                  (including but not limited to all information, software, text, displays,
                  images, video, and audio, and the design, selection, and arrangement
                  thereof) are owned by the Company, its licensors, or other providers of
                  such material and are protected by United States and international copyright,
                  trademark, patent, trade secret, and other intellectual property or proprietary
                  rights laws.
                </p>
                <p className="mt-2">
                  These Terms of Use permit you to use the Website
                  for your personal, non-commercial use only. You must not
                  reproduce, distribute, modify, create derivative works of,
                  publicly display, publicly perform, republish, download, store,
                  or transmit any of the material on our Website, except as follows:
                </p>
                <ul className="ml-6">
                  <li className="list-disc">
                    Your computer may temporarily store copies of
                    such materials in RAM incidental to your accessing
                    and viewing those materials.
                  </li>
                  <li className="list-disc">
                    You may store files that are automatically cached
                    by your Web browser for display enhancement purposes.
                  </li>
                  <li className="list-disc">
                    You may print or download one copy of a reasonable number of
                    pages of the Website for your own personal, non-commercial
                    use and not for further reproduction, publication, or distribution.
                  </li>
                  <li className="list-disc">
                    If we provide desktop, mobile, or other applications
                    for download, you may download a single copy to your
                    computer or mobile device solely for your own personal,
                    non-commercial use, provided you agree to be bound by our end
                    user license agreement for such applications.
                  </li>
                </ul>
                <p className="mt-2">
                  You must not:
                </p>
                <ul className="ml-6">
                  <li className="list-disc">
                    Modify copies of any materials from this site.
                  </li>
                  <li className="list-disc">
                    Use any illustrations, photographs, video or audio sequences,
                    or any graphics separately from the accompanying text.
                  </li>
                  <li className="list-disc">
                    Delete or alter any copyright, trademark, or other proprietary
                    rights notices from copies of materials from this site.
                  </li>
                </ul>
                <p className="mt-2">
                  You must not access or use for any commercial purposes any part
                  of the Website or any services or materials available through the Website.
                </p>
                <p className="mt-2">
                  If you wish to make any use of material on the Website other than that
                  set out in this section, please address your request to:
                  info@groundteq.com.
                </p>
                <p className="mt-2">
                  If you print, copy, modify, download, or otherwise use or provide any
                  other person with access to any part of the Website
                  in breach of the Terms of Use, your right to use
                  the Website will stop immediately and you must, at our option, return or
                  destroy any copies of the materials you have made. No right, title, or
                  interest in or to the Website or any content on the Website is transferred
                  to you, and all rights not expressly granted are reserved by the Company.
                  Any use of the Website not expressly permitted by these Terms of Use is
                  a breach of these Terms of Use and may violate copyright, trademark, and other laws.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Trademarks
                </h2>
                <p className="mt-2">
                  The Company name, the terms are trademarks of the Company or its affiliates or licensors.
                  You must not use such marks without the prior written permission of the Company.
                  All other names, logos, product and service names, designs, and slogans on this
                  Website are the trademarks of their respective owners.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Prohibited Uses
                </h2>
                <p className="mt-2">
                  You may use the Website only for lawful purposes and in
                  accordance with these Terms of Use. You agree not to use
                  the Website:
                </p>
                <ul className="ml-6">
                  <li className="list-disc">
                    In any way that violates any applicable federal, state, local, or
                    international law or regulation (including, without limitation, any
                    laws regarding the export of data or software to and from the US
                    or other countries).
                  </li>
                  <li className="list-disc">
                    For the purpose of exploiting, harming, or attempting to exploit
                    or harm minors in any way by exposing them to inappropriate content,
                    asking for personally identifiable information, or otherwise.
                  </li>
                  <li className="list-disc">
                    To send, knowingly receive, upload, download, use, or re-use any
                    material that does not comply with these Terms of Use.
                  </li>
                  <li className="list-disc">
                    To transmit, or procure the sending of, any advertising or promotional
                    material without our prior written consent, including any &quot;junk mail,&quot;
                    &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.
                  </li>
                  <li className="list-disc">
                    To impersonate or attempt to impersonate the Company, a Company
                    employee, another user, or any other person or entity (including, without
                    limitation, by using email addresses or screen names associated with any of
                    the foregoing).
                  </li>
                  <li className="list-disc">
                    To engage in any other conduct that restricts or inhibits
                    anyone&apos;s use or enjoyment of the Website, or which, as determined by us,
                    may harm the Company or users of the Website, or expose them to liability.
                  </li>
                </ul>
                <p className="mt-2">
                  Additionally, you agree not to:
                </p>
                <ul className="ml-6">
                  <li className="list-disc">
                    Use the Website in any manner that could disable, overburden,
                    damage, or impair the site or interfere with any other
                    party&apos;s use of the Website, including their ability to
                    engage in real time activities through the Website.
                  </li>
                  <li className="list-disc">
                    Use any robot, spider, or other automatic device, process, or
                    means to access the Website for any purpose, including
                    monitoring or copying any of the material on the Website.
                  </li>
                  <li className="list-disc">
                    Use any manual process to monitor or copy any of the
                    material on the Website, or for any other purpose not
                    expressly authorized in these Terms of Use, without our
                    prior written consent.
                  </li>
                  <li className="list-disc">
                    Use any device, software, or routine that interferes with the proper working of the Website.
                  </li>
                  <li className="list-disc">
                    Introduce any viruses, Trojan horses, worms, logic bombs,
                    or other material that is malicious or technologically harmful.
                  </li>
                  <li className="list-disc">
                    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
                    of the Website, the server on which the Website is stored, or any server,
                    computer, or database connected to the Website.
                  </li>
                  <li className="list-disc">
                    Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
                  </li>
                  <li className="list-disc">
                    Otherwise attempt to interfere with the proper working of the Website.
                  </li>
                </ul>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Reliance on Information Posted
                </h2>
                <p className="mt-2">
                  The information presented on or through the Website is made
                  available solely for general information purposes. We do not
                  warrant the accuracy, completeness, or usefulness of this
                  information. Any reliance you place on such information
                  is strictly at your own risk. We disclaim all liability and
                  responsibility arising from any reliance placed on such materials
                  by you or any other visitor to the Website, or by anyone
                  who may be informed of any of its contents.
                </p>
                <p className="mt-2">
                  This Website may include content provided by
                  third parties, including materials provided by
                  other users, and third-party licensors, syndicators,
                  aggregators, and/or reporting services. All statements
                  and/or opinions expressed in these materials, and all articles
                  and responses to questions and other content, other than
                  the content provided by the Company, are solely the
                  opinions and the responsibility of the person or entity providing those materials.
                  These materials do not necessarily reflect the opinion of the Company.
                  We are not responsible, or liable to you or any third party, for the
                  content or accuracy of any materials provided by any third parties.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Changes to the Website
                </h2>
                <p className="mt-2">
                  All information we collect on this Website is subject to our Privacy
                  Policy
                  {' '}
                  <a href="https://groundteq/privacy-policy">
                    https://groundteq/privacy-policy.
                  </a>
                  {' '}
                  By using the Website, you consent to
                  all actions taken by us with respect to your information in compliance with
                  the Privacy Policy.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Online Purchases and Other Terms and Conditions
                </h2>
                <p className="mt-2">
                  All purchases through our site or other transactions for services
                  formed through the Website, or resulting from visits made by you,
                  are governed by our Software as a Service Agreement, which is hereby
                  incorporated into these Terms of Use.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Disclaimer of Warranties
                </h2>
                <p className="mt-2">
                  You understand that we cannot and do not guarantee or warrant that files
                  available for downloading from the internet or the Website will be free of
                  viruses or other destructive code. You are responsible for
                  implementing sufficient procedures and checkpoints to satisfy your particular
                  requirements for anti-virus protection and accuracy of data input and output,
                  and for maintaining a means external to our site for any reconstruction of any lost data.
                  <span className="uppercase">
                    TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
                    LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES,
                    OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                    COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                    WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING
                    OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                  </span>
                </p>
                <p className="mt-2 uppercase">
                  YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
                  THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
                  SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND
                  &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                  NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                  REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                  OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
                  ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT,
                  OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE,
                  OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES
                  IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY
                  SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </p>
                <p className="mt-2 uppercase">
                  TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                  INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                  AND FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p className="mt-2 uppercase">
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Limitation on Liability
                </h2>
                <p className="mt-2 uppercase">
                  TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
                  ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                  OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                  ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES
                  LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
                  SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                  INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
                  OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
                  (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                </p>
                <p className="mt-2 uppercase">
                  THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Indemnification
                </h2>
                <p className="mt-2">
                  You agree to defend, indemnify, and hold harmless the Company,
                  its affiliates, licensors, and service providers, and its and their
                  respective officers, directors, employees, contractors, agents,
                  licensors, suppliers, successors, and assigns from and against any
                  claims, liabilities, damages, judgments, awards, losses, costs,
                  expenses, or fees (including reasonable attorneys&apos; fees) arising out
                  of or relating to your violation of these Terms of Use or your use of
                  the Website, including, but not limited to, any use of the Website&apos;s content,
                  services, and products other than as expressly authorized in these Terms of
                  Use, or your use of any information obtained from the Website.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Governing Law and Jurisdiction
                </h2>
                <p className="mt-2">
                  All matters relating to the Website and these Terms of Use,
                  and any dispute or claim arising therefrom or related thereto
                  (in each case, including non-contractual disputes or claims),
                  shall be governed by and construed in accordance with the internal
                  laws of the State of Tennessee without giving effect to any choice or
                  conflict of law provision or rule (whether of the State of Tennessee or
                  any other jurisdiction).
                </p>
                <p className="mt-2">
                  Any legal suit, action, or proceeding arising out of, or related to, these
                  Terms of Use or the Website shall be instituted exclusively in the federal
                  courts of the United States or the courts of the State of Tennessee, in each
                  case located in the City of Chattanooga and County of Hamilton, although we
                  retain the right to bring any suit, action, or proceeding against you for breach of
                  these Terms of Use in your country of residence or any other relevant country.
                  You waive any and all objections to the exercise of jurisdiction over you
                  by such courts and to venue in such courts.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Arbitration
                </h2>
                <p className="mt-2">
                  At Company&apos;s sole discretion, it may require you to submit any disputes arising
                  from these Terms of Use or use of the Website, including disputes arising from or
                  concerning their interpretation, violation, invalidity, non-performance, or
                  termination, to final and binding arbitration under the Rules of Arbitration of
                  the American Arbitration Association applying Tennessee law.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Limitation on Time to File Claims
                </h2>
                <p className="mt-2">
                  ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
                  TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
                  YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION
                  OR CLAIM IS PERMANENTLY BARRED.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Waiver and Severability
                </h2>
                <p className="mt-2">
                  No waiver by the Company of any term or condition set out
                  in these Terms of Use shall be deemed a further or continuing waiver of such
                  term or condition or a waiver of any other term or condition, and any failure of the Company
                  to assert a right or provision under these Terms of Use shall not constitute a waiver of such
                  right or provision.
                </p>
                <p className="mt-2">
                  If any provision of these Terms of Use is held by a court or other tribunal
                  of competent jurisdiction to be invalid, illegal, or unenforceable for any reason,
                  such provision shall be eliminated or limited to the minimum extent such that
                  the remaining provisions of the Terms of Use will continue in full force and effect.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Entire Agreement
                </h2>
                <p className="mt-2">
                  These Terms of Use, our Privacy Policy, and the Software as a Service Agreement,
                  constitute the sole and entire agreement between you and the Company regarding the
                  Website and supersede all prior and contemporaneous understandings, agreements,
                  representations, and warranties, both written and oral, regarding the Website.
                </p>
              </section>
              <section className="my-2">
                <h2 className="sm:text-xl md:text-md  font-medium underline text-center">
                  Your Comments and Concerns
                </h2>
                <p className="mt-2">
                  This website is operated by GroundTeq LLC.
                </p>
                <p className="mt-2">
                  All other feedback, comments, requests for technical support, and other communications
                  relating to the Website should be directed to: info@groundteq.com.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
