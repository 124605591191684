import React from 'react';
import PropTypes from 'prop-types';
import { DocumentTextIcon } from '@heroicons/react/outline';
import _ from 'lodash';

import { getAcceptExtensions, viewExtensions } from '../drag-and-dpop';

const Upload = ({
  extension, file, files, handleFileInput, isMultiple, label, dataTestId,
  uniqueId, isHighlighted = false, customExtensionObj = null,
}) => (
  <div className={`mt-1 flex justify-center px-6 py-3 border-2
    ${isHighlighted ? "border-red-300" : "border-gray-300"} border-dashed rounded-md`}
  >
    <div className="space-y-1 text-center flex flex-col">
      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
      <div className="flex justify-center text-sm text-gray-600">
        <label
          htmlFor={`${label} ${uniqueId}`}
          className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600
            hover:text-indigo-500 focus-within:outline-none focus-within:ring-2
            focus-within:ring-offset-2 focus-within:ring-indigo-500"
        >
          <span>{label}</span>
          <input
            id={`${label} ${uniqueId}`}
            name={`${label} ${uniqueId}`}
            type="file"
            accept={customExtensionObj?.accept || getAcceptExtensions(extension)}
            className="sr-only"
            onChange={(e) => handleFileInput(e.target.files)}
            multiple={isMultiple}
            data-testid={dataTestId}
          />
        </label>
        <p className="pl-1">or drag and drop</p>
      </div>
      <p className="text-xs text-gray-500">{customExtensionObj?.view || viewExtensions(extension)}</p>
      {_.isArray(files) && files?.length > 0 && (
        <p className="text-xs text-gray-500">{`${files?.length} files selected`}</p>
      )}
      {file instanceof File && file.size > 0 && (
        <p className="text-xs text-gray-500">{`"${file.name}" selected`}</p>
      )}
    </div>
  </div>
);

Upload.propTypes = {
  handleFileInput: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  file: PropTypes.instanceOf(File),
  files: PropTypes.array,
  isMultiple: PropTypes.bool,
  uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customExtensionObj: PropTypes.object,
  extension: PropTypes.string,
};

Upload.defaultProps = {
  file: new File([], ''),
  label: 'Upload a file',
  dataTestId: '',
  uniqueId: '',
  files: [],
  isMultiple: false,
  customExtensionObj: {},
  extension: "",
};

export default Upload;
