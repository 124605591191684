import { FormHandlersWrapper, FormSave, FormTextarea } from 'components/form';
import ModalWrapper from 'components/modal/modal-wrapper';
import Upload from 'components/upload';
import UploadWrapperDND from 'components/upload/upload-wrapper-dnd';
import { FormLabel } from 'modules/dispatches/modalCreateDispatch';
import handleDroppedFiles from 'components/drag-and-dpop/index';
import React, { useState }  from 'react';
import { ValidationsErrors } from 'components/notifications';
import _ from 'lodash';

const CreateReview = ({ httpRequest, dvirId, open, setOpen, refreshItems }) => {

  const [photos, setPhotos] = useState([]);
  const [comments, setComments] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onFileDrop = (file) => {
    const newFiles = handleDroppedFiles(file, 'image', true);
    if (newFiles) setPhotos(newFiles);
  }
  
  const formatFormData = () => {
    const data = new FormData();
    data.append('resolution', comments);

    photos.forEach((photo) => data.append('photos[]', photo));

    return data;
  }

  const onSaveDVIRReview = async () => {
    
    if (!comments.length) {
      setErrors({ message: 'Comments field is required' });
      return;
    }
  
    setIsLoading(true);
    setErrors({});

    const data  = formatFormData();

    const response = await httpRequest({
      method: 'post',
      url: `/truck-checks/${dvirId}/resolutions`,
      data,
    })
    .catch((error) => setErrors(error.response.data))
    .finally(() => setIsLoading(false))

    if (response) {
      setErrors({});
      refreshItems();
      setOpen(false);
    }
  }

  return (
    <ModalWrapper open={open} setOpen={setOpen} title="Create review">
      {!_.isEmpty(errors) && (
        <ValidationsErrors errors={errors} />
      )}
      <FormLabel className="mt-2" label="Comments" />
      <FormTextarea
        label=""
        value={comments}
        handleValue={setComments}
      />
      <UploadWrapperDND onDrop={onFileDrop}>
        <Upload
          files={photos || null}
          handleFileInput={(e) => setPhotos(Array.from(e))}
          extension="image"
          isMultiple
        />
      </UploadWrapperDND>
      <FormHandlersWrapper className="mt-2">
        <FormSave onClick={onSaveDVIRReview} isLoading={isLoading} />
      </FormHandlersWrapper>
    </ModalWrapper>
  )
}

export default CreateReview