import _ from 'lodash'
import React from 'react'
import DispatchRulePassFail from '../dispatch-rule-pass-fail'
import DispatchRuleUpload from '../dispatch-rule-uplaod'

const SecondStep = ({
  rules,
  selectedRules,
  onSelectRule,
}) => {

  return (
    <>
      {!_.isEmpty(rules) && rules?.map((item) => {
        if (item?.entity === 1) {
          return (
            <DispatchRulePassFail
              key={item?.id}
              name={item?.name}
              description={item?.description}
              onSelect={(val) => onSelectRule(item, 'passOrFail', val)}
              value={selectedRules?.find((i) => i?.id === item?.id)?.passOrFail || ""}
              isRequired={item?.required}
            />
          )
        } else if (item?.entity === 2) {
          return (
            <DispatchRuleUpload
              key={item?.id}
              name={item?.name}
              description={item?.description}
              onSelect={(val) => onSelectRule(item, 'photo', val)}
              value={selectedRules?.find((i) => i?.id === item?.id)?.photo || null}
              isRequired={item?.required}
            />
          )
        }
        return null;
      })}
    </>
  )
}

export default SecondStep