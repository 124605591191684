import {
  React, Fragment, PureComponent, createRef,
} from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  HomeIcon, MenuAlt1Icon, ChevronDoubleLeftIcon, ViewListIcon, XIcon, CashIcon, UsersIcon, LogoutIcon,
  OfficeBuildingIcon, LocationMarkerIcon, TruckIcon, DocumentAddIcon, ChevronDownIcon,
  PencilAltIcon, UserAddIcon, ChartSquareBarIcon, ExclamationCircleIcon, QuestionMarkCircleIcon, CalendarIcon,
  DocumentReportIcon, BellIcon, ClipboardCheckIcon, CreditCardIcon, CogIcon,
  CollectionIcon, NewspaperIcon, PhoneIcon, CurrencyDollarIcon, GlobeAltIcon, ClockIcon,
} from '@heroicons/react/outline';
import { SelectorIcon } from '@heroicons/react/solid';
import _ from 'lodash';

import withUserInfo from '../../hocs/with-user-info';
import withAPI from '../../hocs/with-api';
import Logo from '../logo';
import { classNames, isKeepCompanyPlan } from '../../utils';
import { Chat, ChatBubble, Dispatch, DocumentCheck, FAQ, Rules, Tax } from 'components/icons';
import WithPermission from 'modules/with-permission';
import { withProjectVersions } from 'hocs';
import ReleaseData from './release-data';
import { NotificationIndicator } from 'components/notifications/notification';

export const navigation = [
  {
    name: 'Home',
    href: '/home',
    icon: HomeIcon,
    permissions: ['AnyResource', 'CompaniesResources', 'CanDrive', 'Home'],
    companyPermissions: ['Home'],
  },
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: ChartSquareBarIcon,
    permissions: ['CompaniesResources', 'Dashboard'],
    companyPermissions: ['Dashboard'],
  },
  {
    name: 'Missing Revenue',
    href: '/missing-revenue',
    icon: CurrencyDollarIcon,
    permissions: ['CompaniesResources', 'MissingRevenue'],
    companyPermissions: ['MissingRevenue'],
  },
  {
    name: 'Assignments & SA%',
    href: '/run-assignment',
    icon: ClipboardCheckIcon,
    permissions: ['CompaniesResources', 'AssignmentsSA'],
    companyPermissions: ['AssignmentsSA'],
  },
  {
    name: 'Pending approvals',
    href: '/approvals',
    icon: ClipboardCheckIcon,
    permissions: ['CompaniesResources', 'PendingApproval'],
    companyPermissions: ['PendingApproval'],
  },
  {
    name: 'Trucks',
    href: '/trucks',
    icon: TruckIcon,
    permissions: ['CompaniesResources', 'CanDrive', 'TruckView'],
    companyPermissions: ['Trucks'],
  },
  {
    name: 'Chat',
    href: '/chat',
    icon: Chat,
    permissions: ['CompaniesResources', 'DriverView'],
    companyPermissions: ['Chat'],

  },
  {
    name: 'My Chat',
    href: '/my-chat',
    icon: Chat,
    permissions: ['CanDrive'],
    companyPermissions: ['Chat'],

  },
  {
    name: 'Drivers',
    href: '/drivers',
    icon: ViewListIcon,
    permissions: ['CompaniesResources', 'DriverView'],
    collapse: 'Drivers',
    companyPermissions: ['Drivers'],

  },
  {
    name: 'Vacation',
    href: '/vacation',
    icon: CalendarIcon,
    permissions: ['CompaniesResources', 'VacationListingOwn', 'CanDrive', 'HolidayListing'],
    collapse: 'Drivers',
  },
  {
    name: 'Payroll',
    href: '/payrolls',
    icon: CashIcon,
    permissions: ['CompaniesResources', 'PayrollView'],
    companyPermissions: ['Payrolls'],
  },
  {
    name: 'My Payroll',
    href: '/my-payroll',
    icon: CashIcon,
    permissions: ['CanDrive'],
    companyPermissions: ['Payrolls'],
  },
  {
    name: 'Invoices',
    href: '/invoices',
    icon: PencilAltIcon,
    permissions: ['CompaniesResources', 'Invoice'],
    companyPermissions: ['Invoice'],

  },
  {
    name: 'Recruiting',
    href: '/candidates',
    icon: UserAddIcon,
    permissions: ['CompaniesResources', 'Recruiting'],
    companyPermissions: ['Recruiting'],
  },
  {
    name: 'Incidents',
    href: '/incidents',
    icon: ExclamationCircleIcon,
    permissions: ['CompaniesResources', 'Incident'],
    companyPermissions: ['Incident'],
  },
  {
    name: 'Alerts',
    href: '/alerts',
    icon: BellIcon,
    permissions: ['CompaniesResources', 'Alert'],
    companyPermissions: ['Alert'],
  },
  {
    name: 'Reporting',
    href: '/reporting',
    icon: DocumentReportIcon,
    permissions: [
      'CompaniesResources', 'DriverPayrollAnalysis', 'DriverRevenueAnalysis', 'DriverMilesAnalysis',
      'DriverAnalysis', 'TruckRevenueAnalysis', 'TruckMilesAnalysis', 'TruckAnalysis',
      'LocationAnalysis', 'UnpaidTrips'
    ],
    companyPermissions: ['Reporting'],
  },
  {
    name: 'Fuel Reporting',
    href: '/fuel-reporting',
    icon: CurrencyDollarIcon,
    permissions: [
      'CompaniesResources', 'FuelPrices',
    ],
    companyPermissions: ['FuelReporting'],
  },
  {
    name: 'Dispatches',
    href: '/dispatches',
    icon: Dispatch,
    permissions: ['CompaniesResources', 'Dispatch'],
    companyPermissions: ['Dispatch'],
  },
  {
    name: 'My Dispatches',
    href: '/my-dispatches',
    icon: Dispatch,
    permissions: ['CanDrive'],
    companyPermissions: ['Dispatch'],
  },
  {
    name: 'Driver Documents',
    href: '/driver-documents',
    icon: DocumentAddIcon,
    permissions: ['DriverAddDocs', 'CanDrive'],
    isMultiPermissions: true,
  },
  {
    name: 'FAQs',
    href: '/faqs',
    icon: QuestionMarkCircleIcon,
    permissions: ['CompaniesResources', 'CanDrive'],
    collapse: 'Settings',
  },
  {
    name: 'Pay rates',
    href: '/payrates',
    icon: CreditCardIcon,
    permissions: ['CompaniesResources', 'PayRatesDriverListing'],
    collapse: 'Settings',
  },
  {
    name: 'Locations',
    href: '/locations',
    icon: LocationMarkerIcon,
    permissions: ['CompaniesResources', 'AnyResource'],
    collapse: 'Settings',
  },
  {
    name: 'Companies',
    href: '/companies',
    icon: OfficeBuildingIcon,
    permissions: ['AnyResource', 'CompaniesResources'],
    collapse: 'Settings',
  },
  {
    name: 'Users',
    href: '/users',
    icon: UsersIcon,
    permissions: ['AnyResource', 'CompaniesResources'],
    collapse: 'Settings',
  },
  {
    name: 'Truck documents',
    href: '/truck-documents',
    icon: DocumentAddIcon,
    permissions: ['AnyResource', 'CompaniesResources'],
    collapse: 'Settings',
  },
  {
    name: 'Fuel prices',
    href: '/fuel-prices/upload',
    icon: CashIcon,
    permissions: ['AnyResource'],
    collapse: 'Settings',
  },
  // We hide it for the time being
  // {
  //   name: 'Roles and permissions',
  //   href: '/roles',
  //   icon: AdjustmentsIcon,
  //   permissions: ['RoleListingOwn'],
  //   collapse: 'Settings',
  // },
  {
    name: 'Logs',
    href: '/logs',
    icon: NewspaperIcon,
    permissions: ['AnyResource'],
  },
  {
    name: 'Truck Count',
    href: '/truck-count',
    icon: TruckIcon,
    permissions: ['AnyResource'],
  },
  {
    name: 'Hub contacts',
    href: '/contacts',
    icon: PhoneIcon,
    permissions: ['CompaniesResources', 'HubContact'],
  },
  {
    name: 'Regions',
    href: '/regions',
    icon: GlobeAltIcon,
    permissions: ['CompaniesResources', 'Region'],
  },
  {
    name: 'Trucks',
    href: '/truck-list',
    icon: TruckIcon,
    permissions: ['Lessor'],
  },
  {
    name: 'DVIR List',
    href: '/dvir-list',
    icon: ChatBubble,
    permissions: ['CompaniesResources', 'CompaniesResources', 'CanDrive', 'DVIR'],
    collapse: 'DVIR',
    companyPermissions: ['DVIR'],
  },
  {
    name: 'DVIR Config',
    href: '/dvir-config',
    icon: QuestionMarkCircleIcon,
    permissions: ['CompaniesResources', 'CompaniesResources', 'DVIR'],
    collapse: 'DVIR',
    companyPermissions: ['DVIR'],
  },
  {
    name: 'DVIR Rules',
    href: '/dvir-rules',
    icon: Rules,
    permissions: ['CompaniesResources', 'CompaniesResources', 'DVIR'],
    collapse: 'DVIR',
    companyPermissions: ['DVIR'],
  },
  {
    name: 'Clocks',
    href: '/clocks',
    icon: ClockIcon,
    permissions: ['CompaniesResources', 'Clock', 'CanDrive'],
    companyPermissions: ['Clock'],
  },
  {
    name: 'State Taxes',
    href: '/state-taxes',
    icon: Tax,
    permissions: ['AnyResource'],
  },
  {
    name: 'Docs',
    href: '/docs',
    icon: FAQ,
    permissions: ['AnyResource'],
  },
  {
    name: 'Schedule & Runs',
    href: '/schedule',
    icon: CalendarIcon,
    permissions: ['CompaniesResources', 'TruckView', 'Lessor'],
    companyPermissions: ['TruckRunSchedule'],
  },
];

const labelIcons = {
  Drivers: ViewListIcon,
  Payroll: CashIcon,
  Settings: CogIcon,
  DVIR: DocumentCheck,
};

const getNavLinkClass = 'group flex items-center p-0.5 text-sm font-medium rounded-md custom-icon relative';
const activeGetNavLinkClass = `bg-gray-200 text-green-regular ${getNavLinkClass}`;
const defaultGetNavLinkClass = `text-gray-700 hover:text-gray-900 hover:bg-gray-50 ${getNavLinkClass}`;

const getNavLinkIconClass = 'mr-3 flex-shrink-0 h-6 w-6 custom-icon-item';
const activeGetNavLinkIconClass = `text-green-regular ${getNavLinkIconClass}`;
const defaultGetNavLinkIconClass = `text-gray-400 group-hover:text-gray-500 ${getNavLinkIconClass}`;

const sidebarClass = 'group flex items-center p-2 text-base leading-5 font-medium rounded-md relative';
const activeSidebarClass = `bg-gray-100 text-gray-900 ${sidebarClass}`;
const defaultSidebarClass = `text-gray-600 hover:text-gray-900 hover:bg-gray-50 ${sidebarClass}`;

const activeSidebarIconClass = 'text-gray-500 mr-3 flex-shrink-0 h-6 w-6';
const defaultSidebarIconClass = 'text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6';

class SideNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      activeNavLink: navigation[0].name,
      collapse: new Set(),
      hasHomePendingItems: false,
    };
    this.desktopSideBarRef = createRef();
  }

  componentDidMount = () => {
    const activeLink = this.resetNavLink();
    this.setCollapse(activeLink?.collapse);
  }

  componentWillUnmount = () => {
    this.handlePendingItemsLoading.cancel();
  }

  componentDidUpdate = (prevProps) => {
    const { isClosed } = this.props;
    const { isClosed: prevIsClosed } = prevProps;
    if (isClosed !== prevIsClosed) {
      this.resetCollapse();
    }
    this.resetNavLink();
  }

  setSidebarOpen = (open) => this.setState({ sidebarOpen: open });

  setActiveNavLink = (link) => {
    this.setState({ activeNavLink: link });
    this.handlePendingItemsLoading();
  };

  resetNavLink = () => {
    const { history } = this.props;
    const relativePath = history.location.pathname.split('/')[1] || '';
    const navWithPermissions = navigation.filter(this.checkNavPermission)
    const activeLink = navWithPermissions.filter((link) => link.href === `/${relativePath}`)[0];
    this.setActiveNavLink(activeLink?.name || '/home');
    return activeLink;
  }

  setCollapse = (collapseName) => this.setState({ collapse: new Set(collapseName ? [collapseName] : []) })

  resetCollapse = () => { this.setState({ collapse: new Set() }); };

  initials = (user) => _.first(user.first_name) + _.first(user.last_name);

  getNavLink = (item, isSubMenuItem = false) => {
    const { isClosed } = this.props;
    const { activeNavLink, hasHomePendingItems, hoveredItem } = this.state;
    let navLinkClass = activeNavLink === item.name ? activeGetNavLinkClass : defaultGetNavLinkClass;
    if (isSubMenuItem) navLinkClass += ' pl-5';

    return (
      <NavLink
        key={item.name}
        to={item.href}
        onClick={() => {
          this.setActiveNavLink(item.name);
        }}
        className={navLinkClass}
        onMouseEnter={() => { if (isClosed) this.setState({ hoveredItem: item.name }); }}
        onMouseLeave={() => { if (isClosed) this.setState({ hoveredItem: '' }); }}
      >
        <item.icon
          className={activeNavLink === item.name ? activeGetNavLinkIconClass : defaultGetNavLinkIconClass}
          aria-hidden="true"
        />
        {(item.name === 'Home' || item.name === 'Pending approvals') && hasHomePendingItems && (
          <span className="absolute bg-red-500 h-2.5 w-2.5 rounded-full left-4 top-1" />
        )}
        <div className="collapse-hide">
          {item.name}
        </div>
        {isClosed && hoveredItem === item.name && (
          <div
            className="text-xs absolute left-1/2 z-10 py-2 px-4 bg-gray-100
            border border-black-100 rounded-2xl text-center font-medium
            block transform -translate-x-1/2 whitespace-wrap"
            style={{ maxWidth: '400%' }}
          >
            {hoveredItem}
          </div>
        )}
      </NavLink>
    );
  }

  checkNavPermission = (item) => {
    const { permissions, companyPermissions, user } = this.props;
    if (!_.isEmpty(item.permissions) && item?.isMultiPermissions) {
      const commonPermissions = _.intersection(permissions, item.permissions);
      return commonPermissions.length === item.permissions?.length;
    }

    if (!isKeepCompanyPlan(item?.companyPermissions, companyPermissions)) return false;

    if (item.href === '/truck-list' && !user?.data?.lessor_name) return false;

    return (_.isEmpty(item.permissions)
      ? !_.intersection(permissions, item.skipPermissions).length > 0
      : _.intersection(permissions, item.permissions).length > 0);
  }

  getNavLabel = (name, label) => {
    const { collapse } = this.state;
    return (
      <button
        type="button"
        key={`${name} ${label}`}
        onClick={() => {
          this.setState((prev) => {
            const prevArray = Array.from(prev.collapse);
            let responseArray = [];
            if (prev.collapse.has(name)) {
              responseArray = prevArray?.filter((i) => (i !== name));
            } else {
              responseArray = [...prevArray, name];
            }
            return { collapse: new Set(responseArray) };
          });
        }}
        className="text-gray-700 hover:text-gray-900 hover:bg-gray-50 w-full group
        flex items-center p-0.5 text-sm font-medium rounded-md custom-icon"
      >
        <label.icon
          className="text-gray-400 group-hover:text-gray-500
            mr-3 flex-shrink-0 h-6 w-6 custom-icon-item"
          aria-hidden="true"
        />

        <div className="collapse-hide flex w-full justify-between">
          {name}
          <ChevronDownIcon
            className={`text-gray-400 transform rotate-${collapse.has(name) ? '0 ' : '180 '}
              group-hover:text-gray-500 flex-shrink-0 h-6 w-6 custom-icon-item`}
            aria-hidden="true"
          />
        </div>
      </button>
    );
  }

  getPCNavigation = () => {
    const { isClosed } = this.props;
    const { collapse } = this.state;

    const allCollapse = new Set();

    const filteredNavigation = [];
    if (isClosed) {
      return navigation
        .filter(this.checkNavPermission)
        .map((item) => this.getNavLink(item));
    }

    navigation.forEach((item) => {
      if (item.collapse) {
        if (!allCollapse.has(item.collapse)) {
          allCollapse.add(item.collapse);

          const collapsedItems = navigation
            .filter((collapsedItem) => (collapsedItem.collapse === item.collapse
              && this.checkNavPermission(collapsedItem)));

          if (collapsedItems.length > 1) {
            filteredNavigation.push(this.getNavLabel(item.collapse,
              { icon: labelIcons[item.collapse] || CollectionIcon }));
            if (collapse.has(item.collapse)) {
              collapsedItems.forEach((collapsedItem) => {
                filteredNavigation.push(this.getNavLink(collapsedItem, true));
              });
            }
          }
          if (collapsedItems.length === 1) {
            filteredNavigation.push(this.getNavLink(collapsedItems[0]));
          }
        }
      } else if (this.checkNavPermission(item)) {
        filteredNavigation.push(this.getNavLink(item));
      }
    });

    return filteredNavigation;
  }

  handlePendingItemsLoading = _.throttle(() => { this.loadPendingItems(); }, 120000);

  loadPendingItems = async () => {
    const { httpRequest } = this.props;
    const responsePending = await httpRequest({
      method: 'get',
      url: 'pending/data',
    });
    if (responsePending) {
      const {
        data: {
          data: {
            invoices,
            pays,
            vacations,
            payroll_adjustments,
          },
        },
      } = responsePending;
      this.setState({
        hasHomePendingItems: invoices || pays || vacations || payroll_adjustments,
      });
    }
  }

  render() {
    const {
      isClosed, toggleVisibility, permissions, user, isVersionsLoading, version,
    } = this.props;
    const {
      sidebarOpen, activeNavLink, hasHomePendingItems,
    } = this.state;
    const widthWm = isClosed ? 'w-20' : 'w-64';

    return (
      <>
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none lg:hidden"
            onClick={() => this.setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
            <div className="flex-1 flex items-center justify-end">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <span className="flex items-center my-auto weight-bold_light self-center text-xl
                            w-12 h-12 text-black-50 bg-gray-300 rounded-full text-center justify-center"
                    >
                      {this.initials(user.data) || ''}
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg
                  bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/userprofile"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            Personal information
                          </Link>
                        )}
                      </Menu.Item>
                      {(_.intersection(permissions, ['CompaniesResources']).length > 0) && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/company-settings"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Company settings
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      {(_.intersection(permissions, ['CompaniesResources', 'AnyResource']).length > 0) && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/knowledge-base"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Knowledge Base
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/logout"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            Logout
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <div className="lg:h-full lg:flex">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={this.setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full
                        focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => this.setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4 max-h-40 overflow-hidden relative">
                    <Logo />
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2">
                      <div className="space-y-1">
                        {navigation.filter(this.checkNavPermission).map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              this.setActiveNavLink(item.name);
                              if (activeNavLink !== item.name) this.setSidebarOpen(false);
                            }}
                            className={activeNavLink === item.name ? activeSidebarClass : defaultSidebarClass}
                          >
                            <item.icon
                              className={activeNavLink === item.name
                                ? activeSidebarIconClass
                                : defaultSidebarIconClass}
                              aria-hidden="true"
                            />
                            {(item.name === 'Home' || item.name === 'Pending approvals') && hasHomePendingItems && (
                              <span className="absolute bg-red-500 h-2.5 w-2.5 rounded-full
                                left-6 top-1.5"
                              />
                            )}
                            {item.name}
                          </NavLink>
                        ))}
                      </div>

                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div ref={this.desktopSideBarRef} className="hidden lg:flex lg:flex-shrink-0">
            <div className={`side-desk flex flex-col ${widthWm} pt-5`}>
              <div className="flex justify-center flex-shrink-0 px-6 logo-wrapper">
                <Logo />
              </div>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden scrollbar mr-2">
                {/* User account dropdown */}
                <Menu as="div" className="px-3 relative inline-block text-left">
                  <div className="collapse-hide">
                    <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2
                    text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 relative"
                    >
                      <WithPermission permissionsCheck={['CanDrive']}>
                        <NotificationIndicator userId={user?.data?.id} />
                      </WithPermission>
                      <span className="flex w-full justify-between items-center">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                          {/* todo: implement avatars */}
                          {/* <img
                            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                            src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&
                            ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                            alt=""
                          /> */}
                          <span className="flex items-center my-auto weight-bold_light self-center text-xl
                            w-12 h-12 text-black-50 bg-gray-300 rounded-full text-center justify-center"
                          >
                            {this.initials(user.data) || ''}
                          </span>
                          <span className="flex-1 flex flex-col min-w-0">
                            <span className="text-gray-900 text-sm font-medium truncate">
                              {user?.data?.first_name}
                            </span>
                            <span className="text-gray-500 text-sm truncate flex flex-row gap-1">
                              { _.map(user.data.roles, (role) => (
                                <span key={role.id}>{role.display_name}</span>
                              )) }
                            </span>
                          </span>
                        </span>
                        <SelectorIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md
                    shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/userprofile"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Personal information
                            </Link>
                          )}
                        </Menu.Item>
                        {(_.intersection(permissions, ['CompaniesResources']).length > 0) && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/company-settings"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                )}
                              >
                                Company settings
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/logout"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              Logout
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Navigation */}
                <nav className="px-3 mt-6">
                  <div className="space-y-1">
                    {this.getPCNavigation()}
                  </div>
                </nav>

              </div>
              <div
                className="flex p-4 mr-auto collapse-btn"
              >
                <button
                  className={`${isClosed ? 'pl-1.5' : ''}`}
                  type="button"
                  onClick={toggleVisibility}
                >
                  <ChevronDoubleLeftIcon
                    aria-hidden="true"
                    className={`h-5 w-5 transform rotate-${!isClosed ? '0' : '180'}`}
                  />
                </button>
              </div>
              <WithPermission permissionsCheck={['AnyResource']} permissions={permissions}>
                {!isVersionsLoading && (
                  <div className={`flex-shrink-0 flex flex-col text-gray-700 font-medium text-sm pt-4 ${isClosed ? 'pl-5' : 'pl-4'}`}>
                    <ReleaseData version={version} />
                  </div>
                )}
              </WithPermission>
              <WithPermission permissionsCheck={['CompaniesResources', 'AnyResource']} permissions={permissions}>
                <div className={`flex-shrink-0 flex pt-4 ${isClosed ? 'pl-5' : 'pl-4'}`}>
                  <Link to='/knowledge-base'>
                    <div className="flex items-center">
                      <FAQ
                        className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500 custom-icon-item"
                        aria-hidden="true"
                      />
                      {!isClosed && (
                        <p className="ml-3 text-sm font-medium text-gray-700 group-hover:text-gray-900">Knowledge Base</p>
                      )}
                    </div>
                  </Link>
                </div>
              </WithPermission>
              <div className={`flex-shrink-0 flex py-4 pr-4 ${isClosed ? 'pl-5' : 'pl-4'}`}>
                <Link to="/logout" className="flex-shrink-0 w-full group block custom-icon">
                  <div className="flex items-start">
                    <div>
                      <LogoutIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 custom-icon-item"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 collapse-hide">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Logout</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SideNavigation.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
};

export default _.flowRight([
  withAPI,
  withRouter,
  withUserInfo,
  withProjectVersions,
])(SideNavigation);
