import ModalWrapper from 'components/modal/modal-wrapper';
import { withAPI, withUser } from 'hocs';
import _ from 'lodash';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { FormHandlersWrapper } from 'components/form';
import { scrollToTop } from 'utils';
import { checkIsRequired } from 'validations';
import { ValidationsErrors } from 'components/notifications';
import { useCancelToken } from 'hooks';
import { useLoadDispatches, useLoadDispatchRules } from './hooks';
import { PageLoadingIndicator } from 'components/loading-indicator';
import PropTypes from 'prop-types';
import { DefaultButton } from 'components/buttons';
import { FirstStep, SecondStep } from './steps';

export const FormLabel = ({ label, isRequired = true, className }) => (
  <span className={`block text-sm font-medium text-gray-700 ${className}`}>
    {label}
    {isRequired && (
      <sup className="text-red-regular">*</sup>
    )}
  </span>
)

const ModalCreateDispatch = ({
  open,
  setOpen,
  httpRequest,
  user,
  refreshItems,
}) => {
  const [dispatchType, setDispatchType] = useState('');
  const [teamType, setTeamType] = useState('');
  const [truckNumber, setTruckNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [notes, setNotes] = useState('');
  const [secondDriver, setSecondDriver] = useState('');
  
  const { data: dispatchRules } = useLoadDispatchRules(httpRequest);
  const [trailerType, setTrailerType] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRules, setSelectedRules] = useState([]);

  //helps to force render on async select
  const [, setTruckList] = useState([]);
  const [, setOriginList] = useState([]);
  const [, setDestinationList] = useState([]);
  const [, setDriverList] = useState([]);

  const { getCancelToken: getTruckCancelToken } = useCancelToken();
  const { getCancelToken: getOriginCancelToken } = useCancelToken();
  const { getCancelToken: getDestinationCancelToken } = useCancelToken();
  const { getCancelToken: getDriverCancelToken } = useCancelToken();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const modalRef = useRef(null);

  const { dispatches, isLoading: isDispatchesLoading } = useLoadDispatches(httpRequest);

  const loadTruckList = async (search) => {
    const response = await httpRequest({
      method: 'get',
      url: `/trucks/numbers?search=${search}`,
      cancelToken: getTruckCancelToken(),
    });
    if (response) {
      const items = _.get(response, 'data.data', []);
      const trucks = items?.map((i) => ({ value: i, label: i }));
      setTruckList(trucks);
      return trucks;
    }
    return [];
  };

  const loadOrgDestList = async (search, field) => {
    const query = queryString.stringify({
      search,
      field,
    })
    const response = await httpRequest({
      method: 'get',
      url: `/trips/org-dest?${query}`,
      cancelToken: field === 'leg_org' ? getOriginCancelToken() : getDestinationCancelToken(),
    });
    if (response) {
      const items = _.get(response, 'data.data', []);
      const formattedList = items?.map((i) => ({ value: i, label: i }));
      field === 'leg_org' ? setOriginList(formattedList) : setDestinationList(formattedList);
      return formattedList;
    }
    return [];
  };

  const loadDriverList = async (search) => {
    const query = queryString.stringify({
      search,
    })
    const response = await httpRequest({
      method: 'get',
      url: `/drivers/numbers?${query}`,
      cancelToken: getDriverCancelToken(),
    });
    if (response) {
      const items = _.get(response, 'data.data', []);
      const formattedList = items?.map((i) => ({ value: i, label: i }));
      setDriverList(formattedList)
      return formattedList;
    }
    return [];
  };

  const debouncedTruckList = _.debounce(async (search, callback) => {
    if (search?.length >= 3) {
      const data = await loadTruckList(search);
      return callback(data)
    }
    setTruckList([]);
    return callback([]);
  }, 500)

  const debouncedOrgDestList = _.debounce(async (search, callback, field) => {
    if (search?.length >= 3) {
      const data = await loadOrgDestList(search, field);
      return callback(data);
    }
    field === 'leg_org' ? setOriginList([]) : setDestinationList([]);
    return callback([]);
  }, 500)

  const debouncedDriverList = _.debounce(async (search, callback) => {
    if (search?.length >= 3) {
      const data = await loadDriverList(search);
      return callback(data);
    }
    setDriverList([]);
    return callback([]);
  }, 500)

  const isValid = () => {
    const validatedFields = {
      truckNumber: checkIsRequired(truckNumber, 'Truck Number'),
      origin: checkIsRequired(origin, 'Origin'),
      destination: checkIsRequired(destination, 'Destination'),
      secondDriver: teamType === 'TEAM' ? checkIsRequired(secondDriver, 'Second Driver') : '',
      dispatchType: checkIsRequired(dispatchType, 'Dispatch Type'),
      trailerType: checkIsRequired(trailerType, 'Trailer Type'),
      teamType: checkIsRequired(teamType, 'Team Type'),
    }
    const messages = {};
    Object.values(validatedFields).forEach((message, index) => {
      if (message) {
        messages[index] = message
      }
    })
    if (!_.isEmpty(messages)) {
      setErrors({ errors: messages });
      return false;
    }
    return true;
  }

  const onSubmitForm = async () => {
    setErrors({});

    if (!isValid()) {
      scrollToTop(modalRef)
      return null;
    }
    setIsLoading(true);
    
    const data = new FormData();
    data.append('truck_no', truckNumber);
    data.append('org', origin);
    data.append('dest', destination);
    data.append('driver_1', user?.driver?.driver_no);
    if (teamType === 'TEAM') data.append('driver_2', secondDriver);
    data.append('notes', notes);
    data.append('type', dispatchType);
    data.append('truck_type', trailerType);
    data.append('team_type', teamType);

    if (selectedRules?.length > 0) {
      selectedRules.forEach((rule, index) => {
        const { id, entity } = rule;
        const value = entity === 1 ? rule?.passOrFail : rule?.photo;
        data.append(`type_data[${index}][item_id]`, id);

        if (entity === 1) {
          data.append(`type_data[${index}][pass]`, value === "pass" ? 1 : 0);
        } else if (entity === 2) {
          value?.forEach((item) => {
            data.append(`type_data[${index}][photos][]`, item);
          })
        }
      })
    }

    const response = await httpRequest({
      method: 'post',
      url: '/dispatches',
      data,
    }).catch((error) => {
      setErrors(error?.response?.data);
      scrollToTop(modalRef);
      setIsLoading(false);
    })

    if (response) {
      setOpen(false);
      refreshItems();
    }
  }

  useEffect(() => {
    setTrailerType((prev) => {
      if (!prev) {
        return dispatchRules[0]?.id || "";
      }
      return prev;
    })
  }, [dispatchRules])

  useEffect(() => {
    if (dispatches.length > 0) {
      const { driver_2, truck_no, truck_type, type, dest, team_type } = dispatches[0];
      setSecondDriver(driver_2 || '');
      setTruckNumber(truck_no || '');
      setTrailerType(truck_type ? Number(truck_type) : '');
      setDispatchType(type ? String(type) : '');
      setOrigin(dest || '');
      setTeamType(team_type ? String(team_type) : '');
    }
  }, [dispatches])

  useEffect(() => {
    setSelectedRules([]);
  }, [trailerType])

  const trailerRules = useMemo(() => {
    if (dispatchRules?.length > 0) {
      return dispatchRules.find((item) => item?.id === trailerType)?.items;
    }
    return [];
  }, [trailerType, dispatchRules]);

  const onSelectRule = (rule, key, value) => {
    setSelectedRules((prev) => {
      const currentItemIndex = prev?.findIndex((item) => +item?.id === +rule?.id);
      const prevRules = _.isEmpty(prev) ? [] : _.cloneDeep(prev);
      if (currentItemIndex !== -1) {
        prevRules[currentItemIndex] = ({ ...rule, [key]: value });
        return prevRules;
      }
      return [...prevRules, { ...rule, [key]: value }];
    })
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title="New Dispatch"
      ref={modalRef}
    >
      <PageLoadingIndicator loading={isDispatchesLoading} />
      {!_.isEmpty(errors) && (
        <ValidationsErrors errors={errors} />
      )}
      {currentStep === 1 && (
        <FirstStep
          truckNumber={truckNumber}
          debouncedTruckList={debouncedTruckList}
          setTruckNumber={setTruckNumber}
          origin={origin}
          debouncedOrgDestList={debouncedOrgDestList}
          setOrigin={setOrigin}
          destination={destination}
          setDestination={setDestination}
          dispatchRules={dispatchRules}
          setTrailerType={setTrailerType}
          trailerType={trailerType}
          secondDriver={secondDriver}
          debouncedDriverList={debouncedDriverList}
          setSecondDriver={setSecondDriver}
          notes={notes}
          setNotes={setNotes}
          setDispatchType={setDispatchType}
          setTeamType={setTeamType}
          teamType={teamType}
          dispatchType={dispatchType}
        />
      )}

      {currentStep === 2 && (
        <SecondStep
          rules={trailerRules}
          selectedRules={selectedRules}
          onSelectRule={onSelectRule}
        />
      )}

      <FormHandlersWrapper className="justify-center mt-5">
        {trailerRules?.length > 0 && currentStep === 2 && (
          <DefaultButton
            label="Back"
            isLoading={isLoading}
            onClick={() => setCurrentStep(1)}
          />
        )}

        {(
          (trailerRules?.length === 0) ||
          (trailerRules?.length > 0 && currentStep === 2)
        ) && (
          <DefaultButton label="Save" onClick={onSubmitForm} isLoading={isLoading} />
        )}

        {trailerRules?.length > 0 && currentStep === 1 && (
          <DefaultButton
            label="Next"
            isLoading={isLoading}
            onClick={() => {
              if (!isValid()) {
                scrollToTop(modalRef)
              } else {
                setCurrentStep(2); setErrors({});
              }
            }}
          />
        )}
      </FormHandlersWrapper>
    </ModalWrapper>
  )
}

ModalCreateDispatch.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  refreshItems: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

ModalCreateDispatch.defaultProps = {
  meta: {},
};

export default _.flowRight([withUser, withAPI])(ModalCreateDispatch);