import { useCancelToken } from "hooks";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import qs from 'query-string';

const useLoadGroupedFAQs = (httpRequest, search) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken();

  const loadGroupedFAQs = useCallback(async () => {
    setIsLoading(true);

    const query = qs.stringify({
      search,
    }, { skipEmptyString: true })

    const response = await httpRequest({
      method: 'get',
      url: `/faq/grouped?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
      return data;
    }
  }, [httpRequest, getCancelToken, search])

  useEffect(() => {
    loadGroupedFAQs();
  }, [loadGroupedFAQs])

  return ({ data, isLoading, loadGroupedFAQs, setData });
}

export default useLoadGroupedFAQs;