import { useState } from 'react'
import { ActionsMethods } from 'utils';

const useCreateUpdateDelete = (httpRequest, {action, data, refreshData, url, onSuccess }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [errors, setErrors] = useState({});

  const onCreateEditDeleteAction = async () => {
    setIsUpdated(false);
    setIsLoading(true);
    setErrors({});
    
    const response = await httpRequest({
      method: ActionsMethods[action],
      url,
      data,
    }).catch((error) => {
      setErrors(error?.response?.data)
    }).finally(() => {
      setIsLoading(false);
    });

    if (response) {
      setIsUpdated(true);
      onSuccess?.(response)
      refreshData?.();
    }
  }

  return { onCreateEditDeleteAction, isLoading, isUpdated, errors }
}

export default useCreateUpdateDelete