import axios from 'axios';
import { useCancelToken } from 'hooks';
import _ from 'lodash';
import { useCallback, useState } from 'react'
import qs from 'query-string';

const useLoadTruckData = (httpRequest, { truckId, search } = {}) => {
  const [data, setData] = useState([]);
  const { getCancelToken } = useCancelToken();
  const [isLoading, setIsLoading] = useState(false);

  const loadTruck = useCallback(async (params, withCancelToken = true) => {
    setIsLoading(true);

    const query = qs.stringify({
      search,
      ...params,
    }, { skipEmptyString: true, skipNull: null, arrayFormat: 'bracket' });

    const formattedQuery = truckId ? `/${truckId}` : `?${query}`;

    const response = await httpRequest({
      method: 'get',
      url: `/trucks${formattedQuery}`,
      cancelToken: withCancelToken ? getCancelToken() : null,
    }).catch((error) => {
      setIsLoading(axios.isCancel(error))
    });

    if (response) {
      const items = _.get(response, 'data.data', []);
      setData(items);
      setIsLoading(false);
      return items;
    }
    return [];

  }, [getCancelToken, truckId, httpRequest, search]);
  
  return ({ loadTruck, data, isLoading, setData, setIsLoading });
}

export default useLoadTruckData