import { useMemo } from "react";

const useDispatchFiles = (dispatch) => {
  const dispatchFiles = useMemo(() => {
    const files = [];
    dispatch?.type_items?.forEach((item) => {
      if (item?.photos?.length > 0) {
        const photosWithName = item?.photos?.map((photoObj) => ({...photoObj, name: item?.name || ''}))
        files.push(...photosWithName);
      }
    })
    return files;
  }, [dispatch])

  return dispatchFiles;
}

export default useDispatchFiles;