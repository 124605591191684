import React from 'react'

const ReleaseData = ({ version }) => {
  return (
    <>
      <div>
        Web:
      </div>
      <div>
        Release date: {process.env.REACT_APP_RELEASE_DATE}
      </div>
      <div>
        Release number: {process.env.REACT_APP_RELEASE_NR}
      </div>
      <div className="mb-2">
        Release versions: {process.env.REACT_APP_RELEASE_VER}
      </div>
      <div>
        Back:
      </div>
      <div>
        Release date: {version?.release_date}
      </div>
      <div>
        Release number: {version?.release_nr}
      </div>
      <div>
        Release versions: {version?.release_ver}
      </div>
    </>
  )
}

export default ReleaseData