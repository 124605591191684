import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";
import { formatPaginationMeta } from "utils";

const useLoadRules = (httpRequest, page = 1) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const { getCancelToken } = useCancelToken()

  const loadRules = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/dvir-rules?page=${page}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      const pagination = _.get(response, 'data.meta', {});
      setIsLoading(false);
      setData(data);
      setPagination(formatPaginationMeta(pagination));
    }
  }, [httpRequest, page, getCancelToken])

  useEffect(() => {
    loadRules();
  }, [loadRules])

  return ({ data, isLoading, loadRules, pagination });
}

export default useLoadRules;