import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";

const useLoadCompanies = (httpRequest, { companyId }) => {
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadCompanies = useCallback(async () => {
    if (!companyId) return;
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/companies/${companyId}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', {});
      setIsLoading(false);
      setCompany(data);
    }
  }, [httpRequest, getCancelToken, companyId])

  return ({ company, isLoading, loadCompanies });
}

export default useLoadCompanies;