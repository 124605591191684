import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import ModalWrapper from './modal-wrapper'
import L from 'leaflet';

const markerIcon = L.icon({
  iconUrl: '/assets/marker.png',
  iconSize: [30, 45],
  iconAnchor: [15, 45],
});

const LocationMap = ({ open, setOpen, location }) => {
  return (
    <ModalWrapper open={open} setOpen={setOpen} title={`Latitude: ${location[0]}, Longitude: ${location[1]}`}>
      <MapContainer
        style={{ flex: 1, height: '70vh' }}
        center={location}
        zoom={13}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
          OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={location} icon={markerIcon} />
      </MapContainer>
    </ModalWrapper>
  )
}

export default LocationMap