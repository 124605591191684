import { DVIRActions } from "../dvir-ca/dvir";
import { useCancelToken } from "hooks";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

const useLoadDVIRTruckChecks = (httpRequest, action) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadDVIRTruckChecks = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/truck-checks/dvir?${action === DVIRActions.truckIssues ? 'type=3' : ''}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken, action])

  useEffect(() => {
    loadDVIRTruckChecks();
  }, [loadDVIRTruckChecks])

  return ({ data, isLoading, loadDVIRTruckChecks, setData });
}

export default useLoadDVIRTruckChecks;