import React from 'react';
import _ from 'lodash';
import { hasPermission } from 'utils';

function withProjectVersion(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isVersionsLoading: false,
        version: {},
      };
    }

    async componentDidMount () {
      const {permissions} = this.props;
      if (hasPermission(['AnyResource'], permissions)) {
        await this.loadProjectVersions();
      }
    }

    loadProjectVersions = async () => {
      const { httpRequest } = this.props;

      this.setState({ isVersionsLoading: true });

      const response = await httpRequest({
        method: 'get',
        url: '/health/check',
      }).finally(() => this.setState({ isVersionsLoading: false }));

      if (response) {
        const data = _.get(response, 'data.version', {});
        this.setState({ version: data });
      }
    }


    render() {
      const { isVersionsLoading, version } = this.state;
      const rest = {
        isVersionsLoading,
        version,
      };
      return <WrappedComponent {...this.props} {...rest} />;
    }
  };
}

export default withProjectVersion;
