import React from 'react';
import PropTypes from 'prop-types';
import FAQInformationTooltip from 'modules/faqs/faq-information-tooltip';

const PageTitle = ({ title, subtitle, withFAQsPage, customFAQHash }) => (
  <div data-testid="title" className="mt-2 mb-1.5 pb-px flex flex-col w-full md:w-max">
    <h1 className="text-2xl font-semibold leading-6 text-gray-900">
      <span className={`relative ${withFAQsPage ? 'pr-5' : ''}`}>
        {title}
        {withFAQsPage && (
          <FAQInformationTooltip hash={customFAQHash || title} className="w-5 h-5 absolute bottom-0.5 right-0" />
        )}
      </span>
    </h1>
    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PageTitle.defaultProps = {
  subtitle: '',
};

export default PageTitle;
