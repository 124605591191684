import React from 'react';
import PropTypes from 'prop-types';
import DotsDropdown from 'components/dots-dropdown';
import { teamTypes } from 'components/modal/add-safety-alert';
import { getUserLabel } from 'utils';
import { Td, Tr } from 'components/table';
import { getFormattedFullDate } from 'components/dateHandle';

const statuses = {
  new: 1,
  resolved: 2,
}

const DVIRRow = ({ data, onClickView, menuItems, refreshItems, httpRequest }) => {
  const date = data?.created_at ? getFormattedFullDate(data?.created_at) : '';
  const pass = data?.dvir_items?.filter((item) => ! item?.pass).length === 0;
  const teamTypeLabel = teamTypes.find((i) => i.value === data?.user?.driver?.team_type);

  const menuItem = {
    permissionCheck: ['CompaniesResources', 'DVIR'],
    label: data?.status?.status === 1 ? 'Mark as Resolved' : 'Mark as Unresolved',
    condition: ({ dvir_items }) => {
      return dvir_items?.filter((item) => !item?.pass)?.length !== 0;
    },
    callback: async () => {
      await onChangeStatus();
    },
  }

  const onChangeStatus = async () => {
    const response = await httpRequest({
      url: `/truck-checks/${data?.id}/status`,
      method: 'post',
      data: { status: data?.status?.status === statuses.new ? statuses.resolved : statuses.new  },
    })

    if (response) {
      refreshItems();
    }
  }

  return (
    <Tr className={`text-sm ${pass ? '' : 'text-red-600 font-semibold'}`}>
      <Td>{data?.truck_no}</Td>
      <Td>{data?.user?.name}</Td>
      <Td>{date}</Td>
      <Td>{data?.type?.label}</Td>
      <Td>{teamTypeLabel?.label || ''}</Td>
      <Td>{data?.user?.driver?.hub?.name}</Td>
      <Td>{pass ? "Pass" : `Fail (${data?.status?.label})`}</Td>
      <Td>{data?.status_by_user ? getUserLabel(data?.status_by_user) : ''}</Td>
      <Td as="btnView" onClick={() => onClickView(data)} />
      <Td className="px-2 py-3 text-right">
        <DotsDropdown menuItems={[...menuItems, menuItem]} id={data} />
      </Td>
    </Tr>
  )
}

DVIRRow.propTypes = {
  data: PropTypes.object.isRequired,
  onClickView: PropTypes.func.isRequired,
}

export default DVIRRow