import _ from 'lodash';
import { useEffect } from 'react';

const useDynamicallyTextareaHeight = (ref, styleObj) => {
  useEffect(() => {
    if (ref) {
      if (!_.isEmpty(styleObj)) {
        Object.entries(styleObj).forEach(([key, value]) => {
          ref.current.style[key] = value
        })
      }

      ref.current.style.height = "0px";
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = scrollHeight + "px";
    }
  }, [ref, styleObj])

  return styleObj || {};
}

export default useDynamicallyTextareaHeight