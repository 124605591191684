import { useCancelToken } from 'hooks';
import _ from 'lodash';
import { useCallback, useState } from 'react'

const useLoadNotifications = (httpRequest) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken();

  const loadNotifications = useCallback(async () => {
    setIsLoading(true);

    const response = await httpRequest({
      method: 'get',
      url: 'safety-alerts/my?unread=1',
      cancelToken: getCancelToken(),
    }).finally(() => setIsLoading(false));

    if (response) {
      const notifications = _.get(response, 'data.data', []);
      setNotifications(notifications);
    }
  }, [httpRequest, getCancelToken])

  return ({ notifications, isLoading, loadNotifications, setNotifications });
}

export default useLoadNotifications;