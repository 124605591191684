import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import ValidationError from '../../components/notifications/validation-errors';
import Errors from '../../components/notifications/errors';

import Logo from '../../components/logo';
import getAPIClient from '../../services/api';

class ResetPasswordForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      error: null,
      errors: [],
      isLoading: false,
    };
  }

  onInputChanged = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    const apiClient = getAPIClient();
    const {
      email, password, passwordConfirmation,
    } = this.state;
    const parsedQS = queryString.parse(window.location.search);
    this.setState({
      isLoading: true,
    });
    const response = await apiClient.post('reset/password', {
      token: parsedQS.token || '',
      email,
      password,
      password_confirmation: passwordConfirmation,
    }).catch((error) => {
      this.setState({
        errors: error?.response?.data || [],
        isLoading: false,
      });
    });

    if (response) {
      const { data: { message, status: isOK } } = response;
      if (!isOK) {
        this.setState({
          error: message,
          isLoading: false,
        });
      } else {
        history.push({
          pathname: '/',
          flash: {
            type: 'success',
            message,
          },
        });
      }
    }
  }

  render() {
    const {
      email, password, passwordConfirmation, error, isLoading, errors,
    } = this.state;
    const shouldDisableButton = _.isEmpty(email) || _.isEmpty(password) || _.isEmpty(passwordConfirmation) || isLoading;
    return (
      <div className="min-h-full h-full">
        <div className="min-h-full h-full flex lg:grid lg:grid-cols-2">
          <div className="flex-1 flex flex-col justify-center py-10 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full lg:w-96">
              <div>
                <Logo />
                <br />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Reset Password</h2>
              </div>

              {
                (!_.isEmpty(errors) && (
                  <ValidationError errors={errors} />
                )) || (error
                && (<Errors errors={[error]} />))
              }

              <div className="mt-8 relative">
                {isLoading && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="animate-spin border-b-2 rounded-full h-10 w-10 border-gray-900" />
                  </div>
                )}
                <div className={`${isLoading ? 'opacity-50' : ''} mt-6`}>
                  <form action="#" method="POST" className="space-y-6" onSubmit={this.onSubmit}>
                    <div>
                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1
                    focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                          Enter your email
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                        placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Your email"
                          onChange={this.onInputChanged}
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1
                    focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="passwordNew" className="block text-xs font-medium text-gray-900">
                          Enter new password
                        </label>
                        <input
                          id="passwordNew"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                        placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Add new password"
                          onChange={this.onInputChanged}
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1
                    focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="passwordConfirmation" className="block text-xs font-medium text-gray-900">
                          Repeat password
                        </label>
                        <input
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                        placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Repeat new password"
                          onChange={this.onInputChanged}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">
                        or
                        {' '}
                        <Link to="/" className="font-medium text-green-regular hover:text-green-hover">Sign In</Link>
                      </p>
                    </div>
                    <div>
                      <button
                        disabled={shouldDisableButton}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                      shadow-sm text-sm font-medium text-white bg-green-regular hover:bg-green-hover
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-regular"
                      >
                        Send recovery link
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 py-8 px-12 w-full text-center
            justify-center bg-green-light"
          >
            <div className="flex items-center flex-col">
              <img
                className="inset-0 w-full object-cover mx-auto login-img"
                src="/assets/login-image.jpg"
                alt=""
              />
              <Link
                to="/"
                className="bg-white color-green-regular hover:text-green-hover
                rounded-md px-6 py-3 inline-block"
              >
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ResetPasswordForm;
