import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link, Redirect } from 'react-router-dom';

import withUser from '../../hocs/with-user';

const Welcome = ({ isUserAuthenticated }) => {
  if (isUserAuthenticated) return <Redirect to="/home" />;
  return (
    <div className="min-h-full h-full">

      <div className="min-h-full h-full w-full flex flex-col">
        <div className="w-full flex justify-end p-3">
          <Link
            to="/login"
            className="flex justify-center py-2 px-4 border border-transparent
                      rounded-md shadow-sm text-sm font-medium text-white bg-green-regular
                      hover:bg-green-hover focus:outline-none focus:ring-2 focus:ring-offset-2
                      focus:ring-green-regular"
          >
            Login
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-center relative">
          <div className="mt-6 text-3xl font-extrabold text-gray-900 text-center">
            Welcome to GroundTeq
          </div>
          <div className="absolute bottom-4">
            <Link to="/privacy-policy" className="mr-2 underline">
              Privacy policy
            </Link>
            <Link to="/terms-of-use" className="underline">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

Welcome.propTypes = {
  isUserAuthenticated: PropTypes.bool.isRequired,
};

export default compose(withUser)(Welcome);
