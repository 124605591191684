import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { PreviewLayout } from 'components/layouts'
import React from 'react'

const ImagePreview = ({
  open,
  setOpen,
  url,
  onSetNextFile,
  onSetPrevFile,
  withNavigationButton = false,
}) => {


  return (
    <PreviewLayout open={open} setOpen={setOpen}>
      <div
        className="w-full h-full flex bg-black-600 bg-opacity-75"
        onClick={() => { if (!withNavigationButton) setOpen(false) }}
      >
        {withNavigationButton && (
          <button onClick={(e) => {e.stopPropagation(); onSetPrevFile()}} className="h-full w-28 flex justify-center items-center">
            <ChevronLeftIcon className="w-10 h-10 text-white" />
          </button>
        )}
        <div className="h-full sm:w-full relative">
          <img
            src={url}
            onClick={() => setOpen(false)}
            className="w-full h-full block mx-auto object-contain"
            alt=""
          />
        </div>
        {withNavigationButton && (
          <button onClick={(e) => {e.stopPropagation(); onSetNextFile()}} className="h-full w-28 flex justify-center items-center">
            <ChevronRightIcon className="w-10 h-10 text-white" />
          </button>
        )}
      </div>
    </PreviewLayout>
  )
}

export default ImagePreview