import { useState } from "react";

const useDownloadFile = (httpRequest) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const downloadFile = async ({ url, fileName, contentType = 'text/csv', extension = ".csv", responseType = "json", openInNewTab = false }) => {
    setIsLoading(true);

    const response = await httpRequest({ method: 'get', url, responseType })
      .catch((error) => setErrors(error?.response?.data))
      .finally(() => setIsLoading(false))
    if (response) {
      const data = new Blob([response.data], { type: contentType });
      const formattedName = `${fileName}${extension}`;
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.setAttribute('download', formattedName);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      if (openInNewTab) window.open(url)
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }
  return { downloadFile, isLoading, errors }
}

export default useDownloadFile;