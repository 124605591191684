import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect } from "react";
import { useCallback, useState } from "react";

const useLoadDispatchRules = (httpRequest) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadDispatchRules = useCallback(async () => {
    setIsLoading(true);

    const response = await httpRequest({
      method: 'get',
      url: '/dispatch-rule-types',
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken])

  useEffect(() => {
    loadDispatchRules();
  }, [loadDispatchRules])

  return ({ data, isLoading, loadDispatchRules });
}

export default useLoadDispatchRules;