import React, { useEffect, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { PageWrapper } from 'components/page';
import { TableWrapper } from 'components/table';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { PageLoadingIndicator } from 'components/loading-indicator';

const UserChangeParentCompany = ({ httpRequest }) => {
  const location = useLocation();
  const history = useHistory();
  const token = QueryString.parse(location.search)?.change_token;
  const [isLoading, setIsLoading] = useState(false);

  const pushUser = useCallback((state) => {
    localStorage.clear();
    history.push('/login', state);
  }, [history])

  const onConfirmToken = useCallback(async () => {
    setIsLoading(true);
    await httpRequest({
      method: 'post',
      url: '/users/check-duplicate/confirm',
      data: { change_token: token },
      noToken: true,
    }).then(() => {
      pushUser({ success: 'The user was successfully migrated to another company' });
    })
    .catch((error) => {
      pushUser({ error: error.response.data });
    })
    .finally(() => setIsLoading(false))
  }, [httpRequest, token, pushUser])

  useEffect(() => {
    onConfirmToken();
  }, [location, onConfirmToken]);

  return (
    <div className="pl-5 w-full h-full">
      <PageWrapper>
        <TableWrapper>
          <PageLoadingIndicator loading={isLoading} />
        </TableWrapper>
      </PageWrapper>
    </div>
  )
}

export default _.flowRight([withAPI])(UserChangeParentCompany);