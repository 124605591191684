import DotsDropdown from 'components/dots-dropdown'
import { withUser } from 'hocs'
import _ from 'lodash'
import WithPermission from 'modules/with-permission'
import React, { useEffect, useRef } from 'react'

const FaqSection = ({ section, menuItems, permissions, onClickDetails, setSectionRefs }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    setSectionRefs((prev) => prev.concat(wrapperRef));
  }, [setSectionRefs])

  return (
    <>
    <div
      ref={wrapperRef}
      id={`#${section?.label}`}
      className="text-lg font-bold border-b border-gray-400 text-green-regular mx-10 lg:mt-0 mt-5"
    >
      {section?.label}
    </div>
    <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 mx-10">
      {section?.faqs?.map((faq) => (
        <div
          style={{ height: "196px" }}
          key={faq?.id}
          className="relative flex overflow-visible rounded-2xl border border-green-regular cursor-pointer"
          onClick={() => onClickDetails(faq)}
          data-testid="section-subitem"
        >
          <div className="flex flex-col h-full w-full overflow-hidden">
            <h1
              style={{ backgroundColor: "rgba(219, 249, 205, 1)" }}
              className="text-base font-bold px-6 py-3.5 text-green-regular w-full rounded-t-2xl"
            >
              {faq?.title}
            </h1>
            <div className="mx-7 mt-2 mb-3 overflow-hidden flex flex-col">
              <div className="flex flex-col flex-wrap wysiwyg_html" dangerouslySetInnerHTML={{__html: faq?.description}} />
            </div>
          </div>
          <div className="absolute bottom-1 right-2" onClick={(e) => e.stopPropagation()}>
            <WithPermission permissionsCheck={["AnyResource"]} permissions={permissions}>
              <DotsDropdown id={faq} menuItems={menuItems} />
            </WithPermission>
            <WithPermission permissionsCheck={["CompaniesResources"]} permissions={permissions}>
              <button data-testid="more-info-btn" type="button" onClick={() => onClickDetails(faq)} className="text-green-regular underline">
                More info
              </button>
            </WithPermission>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}

export default _.flowRight([withUser])(FaqSection)