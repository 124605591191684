import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { compose } from 'recompose';
import _ from 'lodash';

const SelectContainer = ({ children, ...other }) => {
  const [items, setItems] = useState(children);

  useEffect(() => {
    const { isLimit } = other?.selectProps?.selectProps || {};
    const [elements = [], elem] = children || [];
    if (_.isArray(elements)) {
      const AllElements = elements?.find((item) => item?.key?.split('-')[1] === '');
      if (AllElements) {
        setItems([[AllElements], elem]);
      } else {
        const updElements = elements?.slice(0, 3);
        setItems([[isLimit ? elements : updElements], elem]);
      }
    } else {
      setItems([[elements], elem]);
    }
  }, [children, other?.selectProps?.selectProps]);

  return (
    <>
      <components.ValueContainer {...other}>
        {items}
      </components.ValueContainer>
    </>
  );
};

SelectContainer.propTypes = {
  children: PropTypes.array,
};

SelectContainer.defaultProps = {
  children: [],
};

export default compose(
)(SelectContainer);
