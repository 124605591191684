import { FormTextarea } from 'components/form';
import React from 'react'
import { FormLabel } from '../modalCreateDispatch';
import AsyncSelect from 'react-select/async';
import RegularDropdown from 'components/regular-dropdown';
import RadioGroup from 'components/radio-group';

const dispatchTypes = [
  { id: 'linehaul', label: 'linehaul'},
  { id: 'spot', label: 'spot'},
];

const teamTypes = [
  { id: 'SOLO', label: 'Solo'},
  { id: 'TEAM', label: 'Team'},
];

const FirstStep = ({
  truckNumber,
  debouncedTruckList,
  setTruckNumber,
  origin,
  debouncedOrgDestList,
  setOrigin,
  destination,
  setDestination,
  dispatchRules,
  setTrailerType,
  trailerType,
  secondDriver,
  debouncedDriverList,
  setSecondDriver,
  notes,
  setNotes,
  setDispatchType,
  setTeamType,
  teamType,
  dispatchType,
}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-5">
      <div className="col-span-1">
        <FormLabel label="Truck number" />
        <AsyncSelect
          value={truckNumber ? ({ value: truckNumber, label: truckNumber }) : ''}
          loadOptions={debouncedTruckList}
          isClearable
          onInputChange={(e, action) => {
            if (action.action === 'menu-close' && action.prevInputValue) {
              setTruckNumber(action.prevInputValue);
            }
          }}
          onChange={(e) => setTruckNumber(e?.value || '')}
          maxMenuHeight={120}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
          block w-full sm:text-sm border-gray-300 rounded-md"
          name="truck_no"
          classNamePrefix="select"
          placeholder="Truck Number"
        />
      </div>
      <div className="col-span-1">
        <FormLabel label="Origin" />
        <AsyncSelect
          value={origin ? ({ value: origin, label: origin }) : ''}
          loadOptions={(search, callback) => debouncedOrgDestList(search, callback, 'leg_org')}
          isClearable
          maxMenuHeight={120}
          onInputChange={(e, action) => {
            if (action.action === 'menu-close' && action.prevInputValue) {
              setOrigin(action.prevInputValue);
            }
          }}
          onChange={(e) => setOrigin(e?.value || '')}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
          block w-full sm:text-sm border-gray-300 rounded-md"
          name="origin"
          classNamePrefix="select"
          placeholder="Origin"
        />
      </div>
      <div className="col-span-1">
        <FormLabel label="Destination" />
        <AsyncSelect
          value={destination ? ({ value: destination, label: destination }) : ''}
          loadOptions={(search, callback) => debouncedOrgDestList(search, callback, 'leg_dest')}
          isClearable
          maxMenuHeight={120}
          onInputChange={(e, action) => {
            if (action.action === 'menu-close' && action.prevInputValue) {
              setDestination(action.prevInputValue);
            }
          }}
          onChange={(e) => setDestination(e?.value || '')}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
          block w-full sm:text-sm border-gray-300 rounded-md"
          name="destination"
          classNamePrefix="select"
          placeholder="Destination"
        />
      </div>
      <div className="col-span-1">
        <FormLabel label="Trailer type" />
        <RegularDropdown
          items={dispatchRules}
          onChange={setTrailerType}
          selectedValue={trailerType}
          placeholder="Select trailer type"
          customMargin="ml-0"
          isFull
        />
      </div>
      {teamType === 'TEAM' && (
        <div className="col-span-1">
          <FormLabel label="Drv #2" />
          <AsyncSelect
            value={secondDriver ? ({ value: secondDriver, label: secondDriver }) : ''}
            loadOptions={(search, callback) => debouncedDriverList(search, callback)}
            isClearable
            maxMenuHeight={120}
            onInputChange={(e, action) => {
              if (action.action === 'menu-close' && action.prevInputValue) {
                setSecondDriver(action.prevInputValue);
              }
            }}
            onChange={(e) => setSecondDriver(e?.value || '')}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
            block w-full sm:text-sm border-gray-300 rounded-md"
            name="second driver"
            classNamePrefix="select"
            placeholder="Second Driver"
          />
        </div>
      )}
      <div className="col-span-2">
        <FormTextarea
          label="Notes"
          value={notes}
          handleValue={setNotes}
        />
      </div>
      </div>
      <div className="flex items-center justify-start w-full">
        <div className="flex flex-col justify gap-2">
          <span>Dispatch type</span>
          <RadioGroup
            onChange={(val) => setDispatchType(val)} items={dispatchTypes}
            className="flex flex-col gap-2"
            defaultSelected={dispatchType}
          />
        </div>
        <div className="flex flex-col gap-2 mx-auto">
          <span>Team type</span>
          <RadioGroup
            onChange={(val) => setTeamType(val)} items={teamTypes}
            className="flex flex-col gap-2"
            defaultSelected={teamType}
          />
        </div>
      </div>
    </>
  )
}

export default FirstStep