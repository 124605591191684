import _ from 'lodash'
import React from 'react'
import { DVIRSubItemView } from '..'
import PropTypes from 'prop-types';

const SecondStep = ({ selectedItem, onSelectSubItem, passedItems, action }) => {

  const findPassedItems = (id) => {
    const currentItem = passedItems?.find((item) => item?.id === id)
    return currentItem ? currentItem : {};
  }

  return (
    <div className="flex flex-col">
      {_.isArray(selectedItem?.items) && selectedItem?.items?.map((item) => (
        <DVIRSubItemView
          key={item?.id}
          item={item}
          passValue={findPassedItems(item?.id)}
          onSelectSubItem={onSelectSubItem}
          action={action}
        />
      ))}
    </div>
  )
}

SecondStep.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  onSelectSubItem: PropTypes.func.isRequired,
  passedItems: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
}

export default SecondStep;