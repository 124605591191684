import { FormHandlersWrapper, FormSave } from 'components/form';
import ModalWrapper from 'components/modal/modal-wrapper';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

const ClockOutConfirmDate = ({ open, setOpen, setDate, selectedDate, onConfirm, isLoading }) => {
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title="Select Date"
      modalOverflow="overflow-visible"
    >
      <div className="flex flex-col gap-2">
        <ReactDatePicker
          selected={selectedDate}
          showTimeInput
          timeInputLabel="Time:"
          placeholderText="Select date"
          onChange={setDate}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
          block w-full sm:text-sm border-gray-300 rounded-md"
          dateFormat="yyyy-MM-dd h:mm aa"
        />
        <FormHandlersWrapper>
          <FormSave onClick={onConfirm} isLoading={isLoading} />
        </FormHandlersWrapper>
      </div>
    </ModalWrapper>
  )
}

export default ClockOutConfirmDate