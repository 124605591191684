import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormHandlersWrapper, FormInput, FormSave } from 'components/form';
import ModalWrapper from 'components/modal/modal-wrapper';
import { ValidationsErrors } from 'components/notifications';
import RegularDropdown from 'components/regular-dropdown';
import { withAPI } from 'hocs';

const CreateEditCategory = ({ httpRequest, action, open, setOpen, category, refreshData, companies }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && action === 'Create') {
      setName('');
      setSelectedCompany(null);
    } else if (open && action === 'Edit') {
      const { name, company_id } = category;
      setName(name);
      setSelectedCompany(company_id);
    }

    setErrors({});
  }, [open, action, category])

  const onSave = async () => {
    setIsLoading(true);
    setErrors({});

    let company_id = "";

    if (companies?.length === 1) {
      company_id = companies?.[0]?.id || ''
    } else { company_id = selectedCompany }

    const data = {
      name,
      company_id,
    }

    let id = ""
    if (action === 'Edit') {
      id = category?.id;
    }

    const response = await httpRequest({
      method: action === 'Create' ? 'post' : 'patch',
      url: `/dvir-categories/${id}`,
      data,
    })
      .catch((error) => setErrors(error?.response?.data))
      .finally(() => setIsLoading(false));

    if (response) {
      setOpen(false);
      refreshData();
    }
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={`${action} DVIR Category`}
      customWidth="sm:max-w-xl sm:w-full overflow-visible"
      >
      {!_.isEmpty(errors) && (<ValidationsErrors errors={errors} />)}
      <div className="flex flex-col gap-2">
        <FormInput
          label="Name"
          value={name}
          handleValue={(id, val) => setName(val)}
          placeholder="Name"
        />
        {companies?.length > 1 && (
          <RegularDropdown
            items={companies}
            onChange={setSelectedCompany}
            selectedValue={selectedCompany}
            placeholder="Select Company"
            customMargin="ml-0"
            isFull
          />
        )}
        <FormHandlersWrapper>
          <FormSave isLoading={isLoading} onClick={onSave} />
        </FormHandlersWrapper>
      </div>
    </ModalWrapper>
  )
}

export default _.flowRight([withAPI])(CreateEditCategory);