import React, { useEffect } from 'react'

const RTRadio = ({ section, item, onChange, value = null, wrapperClassName = "col-span-6 justify-between grid grid-cols-6 my-3 items-end" }) => {

  const [plainValue, setPlainValue] = React.useState(value)

  useEffect(() => {
      onChange(section.id, item.id, plainValue)
  }, [plainValue, item.id, onChange, section.id])

  return (
    <div className={wrapperClassName}>
      <p className='col-span-3 border-b mr-4'>{item.question}</p>
      <div className='col-span-3 mr-4'>
        {item.options.map((option, index) => (
          <div key={section.id + '-' + item.id + '-' + index} className="flex items-center">
            <label
              htmlFor={section.id + '-' + item.id + '-' + index}
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              <input
                checked={option.id === parseInt(plainValue)}
                name={section.id + '-' + item.id + '-' + index}
                id={section.id + '-' + item.id + '-' + index}
                type="radio"
                onChange={(e) => setPlainValue(option.id)}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              <span className="ml-3">{option.name}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RTRadio