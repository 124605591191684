import React from 'react';
import { PageWrapper } from 'components/page';
import { TableWrapper } from 'components/table';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { useEffect } from 'react';


const VacationOpenStatus = ({ httpRequest }) => {
  const { secret, status, email } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const setStatus = async () => {
      setIsLoading(true);
      await httpRequest({
        method: 'patch',
        url: `vacations/${secret}/open-status?email=${email}`,
        data: {status},
      }).then(() => setMessage(`Vacation has been ${status}. Now you can safely close this page.`))
      .catch((error) => {
        setIsError(true);
        setMessage(error.response.data.message || 'Something went wrong, please try again later.')
      })
      .finally(() => {
        setIsLoading(false);
      })
    }
    setStatus();
  }, [httpRequest, secret, status, email])

  return (
    <div className="pl-5 w-full h-full">
      <PageWrapper>
        <TableWrapper>
          <PageLoadingIndicator loading={isLoading} />
          {!isLoading && <p>{message}</p>}
          {isError && (
            <p>To view all vacations requests, <a style={{color: 'blue'}} href='/approvals/vacations'>click here</a></p>
          )}
        </TableWrapper>
      </PageWrapper>
    </div>
  )
}

export default _.flowRight([withAPI])(VacationOpenStatus);