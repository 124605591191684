import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

const maxIteration = 15;
const scrollWidthOffset = 18;

const MainHint = ({ hint, condition, styles, ...props }) => {
  const ref = useRef(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  useEffect(() => {
    if (condition){
      let parent = ref.current?.offsetParent;
      _.find(({length: maxIteration}), () => {
        const classList = parent?.classList;
        const hasOverflow = _.some(classList, (el) => _.includes(el, 'overflow'));
        if (!hasOverflow) parent = parent?.offsetParent;
        return hasOverflow;
      })

      const elementBounding = ref.current?.getBoundingClientRect();
      if (elementBounding?.right >= parent?.getBoundingClientRect()?.right) {
        const offset = parent?.getBoundingClientRect()?.right - elementBounding?.right - scrollWidthOffset ?? 0
        setOffsetLeft(offset);
      }
    } else {
      setOffsetLeft(0)
    }
  }, [condition])

  return condition ? (
    <div
      className="text-xs absolute left-0 z-10 py-2 px-4 bg-gray-100 break-words
      border border-black-100 rounded-2xl text-left font-medium
      block whitespace-pre-wrap"
      style={{ ...styles, [offsetLeft ? 'left' : '']: `calc(${offsetLeft}px + ${styles?.left ?? '0px'})` }}
      {...props}
      ref={ref}
    >
      {hint}
    </div>
  ) : null
}

export default MainHint