import React from 'react'
import { PageWrapper } from '.';
import { PageLoadingIndicator } from 'components/loading-indicator';

const PageLoading = ({ isLoading = false }) => (
  <PageWrapper>
    <PageLoadingIndicator loading={isLoading} />
  </PageWrapper>
);

export default PageLoading;