import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";
import { formatMultiSelectedList } from "utils/formatted-query";
import qs from 'query-string';

const useLoadPayrollCompanyDates = (httpRequest, { companies } = {}) => {
  const [dates, setDates] = useState([]);
  const [formattedDates, setFormattedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken();

  const loadDates = useCallback(async () => {
    if (!companies || companies?.length === 0) return;
    setIsLoading(true);
    const formattedCompanies = formatMultiSelectedList(companies);
    const query = qs.stringify({
      companies: formattedCompanies,
    }, { arrayFormat: 'bracket', skipNull: true });

    const response = await httpRequest({
      method: 'get',
      url: `/payrolls/${formattedCompanies[0]}/dates?${query}`,
      cancelToken: getCancelToken(),
    }).finally(() => setIsLoading(false));

    if (response) {
      const dates = _.get(response, 'data.data', []);
      const formattedDates = _.map(dates, ({ name, key }) => ({ label: name, value: key }));
      setFormattedDates(formattedDates);
      setDates(dates);
    }
  }, [httpRequest, companies, getCancelToken])

  return ({ dates, isLoading, loadDates, formattedDates });
}

export default useLoadPayrollCompanyDates