import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DocumentIcon, XCircleIcon } from '@heroicons/react/outline';
import { withRouter } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { withAPI, withUserInfo } from 'hocs';
import { SuccessMessage, ValidationsErrors } from 'components/notifications';
import handleDroppedFileInput, { getAcceptExtensions, viewExtensions } from 'components/drag-and-dpop';
import { hasPermission, scrollToTop } from 'utils';
import Upload from 'components/upload';
import ModalDeleteItem from './delete-item';
import ModalFilePreview from './file-preview';
import ModalWrapper from './modal-wrapper';
import WithPermission from 'modules/with-permission';
import RegularDropdown from 'components/regular-dropdown';
import { FormLabel } from 'modules/dispatches/modalCreateDispatch';
import { FormCancel, FormHandlersWrapper, FormSave } from 'components/form';
import { FAQActions } from 'modules/faqs/knowledge-base';

class ModalFAQ extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      files: [],
      currentFiles: [],

      errors: {},
      categories: [],
      selectedCategory: '',
      itemDeleteModal: false,
      preViewFilesOpen: false,
      itemId: '',
      deleteUrl: 'faq-files',
      fileUrl: '',
      isLoading: false,
      itemCreatedOk: false,
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const {
      action, faq, companies, permissions,
    } = this.props;

    const defaultCompanyId = companies.length > 0 ? companies[0]?.id : 0;

    if (hasPermission(['AnyResource'], permissions)) {
      this.loadFAQsMeta().then(() => {
        if (action === FAQActions.edit) this.setState({ selectedCategory: String(faq?.category_id) });
      })
    }

    if (action === 'Edit') {
      const {
        company_id, description, order, title,
      } = faq || {};

      const updatedValues = {
        company_id: companies.filter((i) => (i.id === company_id))?.pop()?.id || defaultCompanyId,
        order,
        title,
        description,
      };
      const { files } = faq;
      this.setState({ values: updatedValues, currentFiles: files });
    } else {
      this.setState({ values: { company_id: defaultCompanyId } });
    }
  }

  handleValue = (field, value) => {
    const { values } = this.state;
    const updatedValues = { ...values, [field]: value };
    this.setState({ values: updatedValues });
  }

  handleNumericValue = (field, value) => {
    const { values } = this.state;
    const numericValue = value !== null ? _.toNumber(value) : NaN;
    if (!Number.isNaN(numericValue)) {
      const updatedValues = { ...values, [field]: numericValue };
      this.setState({ values: updatedValues });
    }
  }

  deleteHandle = (id) => {
    this.setState({ itemDeleteModal: true, itemId: id });
  }

  onModalDeleteOpen = (open) => {
    if (!open) {
      const { refreshItems, faq } = this.props;
      refreshItems().then((items) => {
        const updatedFiles = items?.filter((item) => item.id === faq.id);
        this.setState({ currentFiles: updatedFiles[0]?.files.map((file) => file) });
      });
    }
    this.setState({ itemDeleteModal: open });
  }

  onSubmit = async () => {
    const {
      httpRequest, refreshItems, setOpen, action, faq_id, permissions,
    } = this.props;
    const { values, files, selectedCategory } = this.state;

    this.setState({
      itemCreatedOk: false,
      isLoading: true,
    });

    const formData = new FormData();
    if (files?.length > 0) {
      files.forEach((file) => formData.append('files[]', file));
    }
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    if (hasPermission(['AnyResource'], permissions)) {
      formData.delete('company_id');
      formData.append('category_id', selectedCategory);
    }

    if (action === 'Edit') {
      formData.append('_method', 'PATCH');
    }

    const safetyPosted = await httpRequest({
      method: 'post',
      url: action === 'Edit' ? `faq/${faq_id}` : 'faq',
      data: formData,
    }).catch((error) => {
      scrollToTop(this.modalRef);
      this.setState({
        errors: error?.response?.data,
        itemCreatedOk: false,
        isLoading: false,
      });
    });

    if (safetyPosted) {
      refreshItems();
      setOpen(false);
    }
  }

  loadFAQsMeta = async () => {
    const { httpRequest } = this.props;

    const response = await httpRequest({
      method: 'get',
      url: '/faq/meta',
    })

    if (response) {
      const meta = _.get(response, 'data.data', {});
      this.setState({ categories: this.getFormattedCategories(meta?.categories) });
    }
  }

  getFormattedCategories = (categories) => {
    if (!_.isEmpty(categories)) {
      return Object.keys(categories)?.map((key) => ({ id: key, name: categories?.[key]?.label }));
    }
    return [];
  }

  onDocumentClick = (url) => {
    this.setState({ fileUrl: url, preViewFilesOpen: true });
  }

  render() {
    const {
      open, setOpen, action, companies, refreshItems,
    } = this.props;

    const {
      errors, itemCreatedOk, values, isLoading, files, itemDeleteModal, itemId, deleteUrl, currentFiles,
      preViewFilesOpen, categories, selectedCategory,
      fileUrl,
    } = this.state;

    const {
      company_id, order, title, description,
    } = values;

    return (
      <ModalWrapper title="FAQ information" open={open} setOpen={setOpen} ref={this.modalRef}>
        <div className="sm:col-span-6">
          {
            itemDeleteModal && (
              <ModalDeleteItem
                open={itemDeleteModal}
                setOpen={this.onModalDeleteOpen}
                itemId={itemId}
                deleteURL={deleteUrl}
                refreshItems={refreshItems}
                listName="file"
              />
            )
          }
          {
            preViewFilesOpen && (
              <ModalFilePreview
                open={preViewFilesOpen}
                setOpen={(opn) => this.setState({ preViewFilesOpen: opn })}
                url={fileUrl}
              />
            )
          }
          <form id="entity-form" onSubmit={(e) => { e.preventDefault(); this.onSubmit(); }}>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
              {
                !_.isEmpty(errors) && (
                  <ValidationsErrors errors={errors} addClass="sm:col-span-6" />
                )
              }
              {itemCreatedOk && (
                <SuccessMessage
                  message={`FAQ was ${action !== 'Edit' ? 'edited' : 'created'} successfully`}
                  addClass="sm:col-span-6"
                />
              )}
              <WithPermission permissionsCheck={['AnyResource']}>
                <div className="sm:col-span-3 flex flex-col justify-between">
                  <FormLabel label="Category" isRequired />
                  <RegularDropdown
                    items={categories}
                    onChange={(category) => this.setState({ selectedCategory: category })}
                    selectedValue={selectedCategory}
                    placeholder="Select category"
                    customMargin="ml-0"
                    isFull
                  />
                </div>
              </WithPermission>

              <WithPermission permissionsCheck={['CompaniesResources']}>
                <div className="sm:col-span-3">
                  <label htmlFor="company_id" className="block text-sm font-medium text-gray-700">
                    Company
                  </label>
                  <div className="mt-1">
                    <select
                      value={company_id || ''}
                      id="company_id"
                      name="company_id"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                              block w-full sm:text-sm border-gray-300 rounded-md border "
                      onChange={(e) => this.handleValue('company_id', e.target.value)}
                    >
                      {companies.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </WithPermission>

              <div className="sm:col-span-3 flex flex-col justify-between">
                <label htmlFor="order" className="block text-sm font-medium text-gray-700">
                  Order
                </label>
                <div className="mt-1">
                  <input
                    id="order"
                    name="order"
                    type="text"
                    autoComplete="order"
                    value={order || ''}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                        block w-full sm:text-sm border-gray-300 rounded-md "
                    onChange={(e) => this.handleNumericValue('order', e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-6 flex flex-col justify-between">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="title"
                    value={title || ''}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                        block w-full sm:text-sm border-gray-300 rounded-md "
                    onChange={(e) => this.handleValue('title', e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="mt-1">
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={(e) => this.handleValue('description', e)}
                  />
                </div>
              </div>

              <div
                className="sm:col-span-6"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const newFiles = handleDroppedFileInput(e?.dataTransfer?.files, 'faq', true);
                  if (newFiles) this.setState({ files: newFiles, errors: {} });
                }}
              >
                <div className="flex flex-wrap">
                  {Array.isArray(currentFiles) && currentFiles.length > 0 && (
                    currentFiles.map(({ id, url }, index) => (
                      <div
                        key={id}
                        className="cursor-pointer flex flex-col items-center justify-center mr-2"
                      >
                        <div className="flex">
                          <DocumentIcon
                            key={id}
                            className="h-6 w-6 text-black-500"
                            aria-hidden="true"
                            onClick={() => this.onDocumentClick(url)}
                          />
                          <button
                            type="button"
                            onClick={() => this.deleteHandle(id)}
                          >
                            <XCircleIcon
                              key={id}
                              className="h-6 w-6 hover:bg-red-100 rounded-full"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                        <button
                          type="button"
                          className="hover:text-indigo-500"
                          onClick={() => this.onDocumentClick(url)}
                        >
                          File
                          {index + 1}
                        </button>
                      </div>
                    )))}
                </div>
                <Upload
                  customExtensionObj={{
                    view: 'MP4, '.concat(viewExtensions('image')),
                    accept: getAcceptExtensions('image').concat(',.mp4'),
                  }}
                  handleFileInput={(fls) => this.setState({ files: Array.from(fls), errors: {} })}
                  isMultiple
                  files={files}
                />
              </div>
            </div>

            <FormHandlersWrapper className="mt-5">
              <FormCancel onCancel={() => setOpen(false)} />
              <FormSave isLoading={isLoading} />
            </FormHandlersWrapper>
          </form>
        </div>
      </ModalWrapper>
    );
  }
}

ModalFAQ.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  action: PropTypes.string,
  faq_id: PropTypes.number,
  faq: PropTypes.object,
  companies: PropTypes.array.isRequired,
  refreshItems: PropTypes.func,
  httpRequest: PropTypes.func.isRequired,
};

ModalFAQ.defaultProps = {
  open: false,
  setOpen: () => { },
  refreshItems: () => { },
  action: 'Create',
  faq_id: 0,
  faq: {},
};

export default _.flowRight([
  withAPI,
  withRouter,
  withUserInfo,
])(ModalFAQ);
