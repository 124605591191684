import WithHoverHint from 'components/hints/with-hover-hint';
import _ from 'lodash';
import React from 'react';

export const stylesTdTemplate = {
  button: 'whitespace-nowrap underline hover:opacity-50 transition-opacity',
  td: 'px-2 py-3',
  btnView: 'text-indigo-600 hover:text-indigo-900 text-sm',
}

const ButtonTd =  React.forwardRef(({ children, parentClassName = stylesTdTemplate.td, className = stylesTdTemplate.button, ...props }, ref) => (
  <td className={parentClassName}>
    <button type="button" ref={ref} className={className} {...props}>
      {children}
    </button>
  </td>
));

const ButtonViewTd =  React.forwardRef(({ children, condition = true, className=stylesTdTemplate.btnView, label = 'View',  ...props }, ref) => {
  const passedChildren = typeof children === 'string' ? children : null;
  const text = passedChildren ?? label;

  return (
    <td className={stylesTdTemplate.td}>
      {condition && (
        <button type="button" ref={ref} className={className} {...props}>
          {text}
        </button>
      )}
    </td>
  );
});

const TdStyled = React.forwardRef(({children, className = stylesTdTemplate.td, ...props}, ref) => (
  <td ref={ref} className={className} {...props}>
    {children}
  </td>
));

const tdHovered = React.forwardRef(({children, hintStyles, condition = null, hint = null, className, withTruncate = true, size = 'md:max-w-md max-w-xs', ...props}, ref) => {

  const passedChildren = typeof children === 'string' ? children : '';
  const text = hint ?? passedChildren;
  const isVisible = condition ?? text?.length > 0

  return (
    <WithHoverHint condition={isVisible} hint={text} styles={hintStyles}>
      <td ref={ref} className={`${stylesTdTemplate.td} relative break-all`} {...props}>
        <div className={`${withTruncate ? 'truncate' : ''} ${size}`}>
          {children}
        </div>
      </td>
    </WithHoverHint>
  );
});

const components = {
  button: ButtonTd,
  td: TdStyled,
  tdHovered: tdHovered,
  btnView: ButtonViewTd,
}

const Td = React.forwardRef(({ className, children, as = 'td', ...props }, ref) => {

  const Provider = _.hasIn(components, as) ? components[as] : components.td;

  return (
    <Provider ref={ref} className={className || stylesTdTemplate[as]} {...props}>
      {children}
    </Provider>
  )
});
export default Td;