import { React, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { BtnLoadingIndicator } from 'components/loading-indicator';
import { DefaultButton } from 'components/buttons';

const ConfirmationModal = ({
  open, setOpen, listName, action, actionFunc, title, description, htmlDescription, whiteColor,
  isLoading, showCloseButton,
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed z-10 inset-0 overflow-y-auto"
      onClose={setOpen}
    >
      <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
          overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg
          sm:w-full sm:p-6"
          >
            <div>
              <button type="button" className="float-right" onClick={() => setOpen(false)}>
                <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-black-400" aria-hidden="true" />
              </button>
              <div className="text-left">
                <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-gray-900">
                  {title || `${action} ${listName}`}
                </Dialog.Title>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <div className="sm:col-span-6 flex justify-start items-center">
                {htmlDescription && <p dangerouslySetInnerHTML={{ __html: htmlDescription }}></p>}
                {!htmlDescription && <label htmlFor="name" className="block text-lg pb-2 font-medium text-gray-700">
                  {description || `Are you sure you want to ${action} this ${listName?.toLowerCase()}?`}
                </label>}
              </div>

              {action?.length > 0 && (
                <div className="sm:col-span-6">
                  <div className="flex justify-start items-center">
                    <button
                      onClick={actionFunc}
                      type="button"
                      disabled={isLoading}
                      className={`inline-flex justify-center py-2 px-4 mt-3 sm:mt-0 border border-transparent
                          shadow-sm text-sm font-medium rounded-md hover:bg-indigo-600 focus:outline-none
                          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            whiteColor
                              ? 'bg-green-regular hover:text-white focus:text-white'
                              : 'bg-white text-red-400'}`}
                    >
                      {isLoading && (
                        <BtnLoadingIndicator />
                      )}
                      {`Yes, ${action}`}
                    </button>
                  </div>
                </div>
              )}
              {showCloseButton && (
                <DefaultButton customStyles="sm:col-span-6 ml-auto" label="Ok" onClick={actionFunc} />
              )}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  listName: PropTypes.string.isRequired,
  action: PropTypes.string,
  actionFunc: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  htmlDescription: PropTypes.string,
  whiteColor: PropTypes.bool,
  isLoading: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  open: false,
  setOpen: () => {},
  title: '',
  description: '',
  htmlDescription: '',
  whiteColor: false,
  isLoading: false,
  showCloseButton: false,
};

export default ConfirmationModal;
