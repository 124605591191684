import React from 'react';
import { components } from 'react-select';
import { compose } from 'recompose';
import { XIcon } from '@heroicons/react/solid';

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <XIcon data-testid="clear-indicator" className="w-5 h-5" />
  </components.ClearIndicator>
);

export default compose(
)(ClearIndicator);
