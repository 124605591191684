import React, { useCallback } from 'react';
import qs from 'query-string';
import { PageWrapper } from 'components/page';
import { TableWrapper } from 'components/table';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { useEffect } from 'react';


const CandidatesChangeParentCo = ({ httpRequest }) => {
  const location = useLocation();
  const history = useHistory();
  const { candidate_id } = useParams();
  const token = qs.parse(location.search)?.change_token;

  const [isLoading, setIsLoading] = useState(false);

  const pushUser = useCallback((state) => {
    localStorage.clear();
    history.push('/login', state);
  }, [history])

  useEffect(() => {
    const convertCandidate = async () => {
      setIsLoading(true);
      await httpRequest({
        method: 'post',
        url: `drivers/${candidate_id}/candidate-convert-public?change_token=${token}`,
        noToken: true,
      }).then(() => {
        pushUser({ success: 'The driver was successfully migrated to another company' })
      })
      .catch((error) => {
        pushUser({ error: error.response.data })
      })
      .finally(() => {
        setIsLoading(false);
      })
    }
    convertCandidate();
  }, [httpRequest, token, candidate_id, pushUser])

  return (
    <div className="pl-5 w-full h-full">
      <PageWrapper>
        <TableWrapper>
          <PageLoadingIndicator loading={isLoading} />
        </TableWrapper>
      </PageWrapper>
    </div>
  )
}

export default _.flowRight([withAPI])(CandidatesChangeParentCo);