import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";

const useLoadDVIRTypes = (httpRequest) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadDVIRTypes = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/truck-checks/meta`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const types = _.get(response, 'data.data.types', {});
      const data = [{ id: '', name: 'All Types' }].concat(Object.keys(types).map((key) => {
        const obj = {};
        obj.id = key;
        obj.name = types[key];
        return obj;
      }));
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken])

  useEffect(() => {
    loadDVIRTypes();
  }, [loadDVIRTypes])

  return ({ data, isLoading, loadDVIRTypes });
}

export default useLoadDVIRTypes;