import React from 'react';
import PropTypes from 'prop-types';
import BtnLoadingIndicator from '../loading-indicator/btn-loading';

const FormSave = ({ isLoading, isDisabled, onClick }) => (
  <button
    type="submit"
    disabled={isLoading || isDisabled}
    className="inline-flex justify-center py-2 px-4 border border-transparent
        shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    onClick={onClick}
  >
    {isLoading && <BtnLoadingIndicator />}
    Save
  </button>
);

FormSave.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

FormSave.defaultProps = {
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
};

export default FormSave;
