import _ from "lodash";
import { useState } from "react";

const usePreviewFileNavigate = (files) => {
  const [selectedFile, setSelectedFile] = useState({});

  const onSetNextFile = () => {
    const currentIndex = _.indexOf(files, selectedFile)
    const nextFile = _.nth(files, currentIndex + 1)
    setSelectedFile(nextFile ?? _.first(files))
  }

  const onSetPrevFile = () => {
    const currentIndex = _.indexOf(files, selectedFile)
    const prevFile = _.nth(files, currentIndex - 1)
    setSelectedFile(prevFile ?? _.last(files))
  }

  return {selectedFile, onSetNextFile, onSetPrevFile, setSelectedFile};
}

export default usePreviewFileNavigate;