import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useEffect, useCallback, useState } from "react";
import queryString from 'query-string';

const formatCompanyQuery = (company) => {
  if (company) {
    if (typeof company === 'number')
      return ({ company_id: company })
    if (_.isArray(company))
      return ({ companies: company?.map((item) => item?.value)});
  }
  return "";
}

const useLoadDVIRQuestions = (httpRequest, companyId = "", isPossibleLoadWithoutCompany = true) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadDVIRQuestions = useCallback(async () => {
    if (!isPossibleLoadWithoutCompany && !companyId) return;
    if (!isPossibleLoadWithoutCompany && _.isArray(companyId) && _.isEmpty(companyId)) return;
    setIsLoading(true);
    const query = queryString.stringify({
      ...formatCompanyQuery(companyId),
    }, { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' });
    const response = await httpRequest({
      method: 'get',
      url: `/dvir-categories?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setData(data);
    }
  }, [httpRequest, getCancelToken, companyId, isPossibleLoadWithoutCompany])

  useEffect(() => {
    loadDVIRQuestions();
  }, [loadDVIRQuestions])

  return ({ data, isLoading, loadDVIRQuestions, setData });
}

export default useLoadDVIRQuestions;