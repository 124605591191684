import { getFormattedDate } from 'components/dateHandle'
import ModalWrapper from 'components/modal/modal-wrapper'
import { NoResults } from 'components/notifications'
import { TBody, Table, TableWrapper, Thead } from 'components/table'
import _ from 'lodash'
import React from 'react'

const DetailsModal = ({ open, setOpen, data }) => {
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={data?.title}
      modalOverflow="overflow-visible"
    >
      <div className="w-full">
        <TableWrapper>
          <NoResults centerContent isVisible={_.isEmpty(data?.items)} />
          <Table>
            <Thead columnList={['Driver Name', 'Date', 'Description']} />
            <TBody>
              {_.map(data?.items, (item) => (
                <tr key={item?.description}>
                  <td className='px-2 py-1'>{`${data?.user?.first_name ?? 'NA'} ${data?.user?.last_name ?? ''}`}</td>
                  <td className='px-2 py-1'>{getFormattedDate(item?.date)}</td>
                  <td className='px-2 py-1'>{item?.description}</td>
                </tr>
              ))}
            </TBody>
          </Table>
        </TableWrapper>
      </div>
    </ModalWrapper>
  )
}

export default DetailsModal