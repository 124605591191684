import _ from "lodash";
import { useCallback, useState } from "react";

const useLoadVendors = (httpRequest) => {
  const [vendors, setVendors] = useState([]);

  const loadVendors = useCallback(async () => {
    const response = await httpRequest({
      method: 'get',
      url: 'vendors',
    });
    if (response) {
      const data = _.get(response, 'data.data', []);
      setVendors(data);
    }
  }, [httpRequest])

  return ({ vendors, loadVendors })
}

export default useLoadVendors