import React, { useMemo, useState } from 'react';
import { MainHint } from '.';

const WithHoverHint = ({
  children,
  condition = false,
  hint,
  styles = {top: '90%'},
  withRelative,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  // if there is a nesting of these components
  const id = useMemo(()  => "id" + Math.random().toString(16).slice(2) , []);

  return (
    React.cloneElement(children, {
      onMouseEnter: () =>  setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
      onMouseOver: (e) => {
        const targetId = e.target.getAttribute('hover-id');
        if (targetId !== id && targetId) setIsHovered(false)
        else setIsHovered(true);
      },
      'hover-id': id,
      className: `${children.props.className} ${withRelative ? 'relative' : ''}`,
    }, ...[
      <MainHint
        condition={isHovered && condition}
        hint={hint}
        styles={styles}
        hover-id={id}
      />, children?.props?.children || null]
    )
  );
}

export default WithHoverHint