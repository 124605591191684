import React from 'react';
import PropTypes from 'prop-types';

const PageLoadingIndicator = ({ loading, isAbsolute = true, className = 'my-10', ...props }) => (loading ? (
  <div
    data-testid="loader"
    className={`z-50 w-10 h-10 ${isAbsolute ? 'absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2' : className}`}
    {...props}
  >
    <div id="loading-center">
      <div id="loading-center-absolute">
        <div className="object" id="object_one"></div>
        <div className="object" id="object_two"></div>
        <div className="object" id="object_three"></div>
        <div className="object" id="object_four"></div>
      </div>
    </div>
  </div>
) : null);

PageLoadingIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PageLoadingIndicator;
