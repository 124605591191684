import React from 'react'
import Th from './th';
import { theadTypes } from './theadSort';

const Thead = ({ columnList, theadType, className, thClassName, ...props }) => (
  <thead className={`${theadTypes[theadType]} ${className}`} {...props}>
    <tr>
      {columnList.map((item) => (
        <Th key={item} title={item} className={thClassName} />
        ))}
    </tr>
  </thead>
);

export default Thead;