import Upload from 'components/upload';
import UploadWrapperDND from 'components/upload/upload-wrapper-dnd';
import React from 'react';
import { FormLabel } from './modalCreateDispatch';
import handleDroppedFileInput from 'components/drag-and-dpop/index';


const DispatchRuleUpload = ({ name, description, onSelect, value, isRequired = false }) => {

  const onFileDrop = (file) => {
    const newFiles = handleDroppedFileInput(file, 'image', true);
    if (newFiles) onSelect(newFiles);
  }
  return (
    <div className="mb-4">
      <FormLabel label={name} isRequired={isRequired} />
      {description?.length > 0 && description?.map((item, index) => (
        <div className="text-sm font-medium text-gray-700" key={item}>{index + 1} - {item}</div>
      ))}
      <UploadWrapperDND onDrop={onFileDrop}>
        <Upload
          files={value || null}
          handleFileInput={(e) => onSelect(Array.from(e))}
          extension="image"
          isMultiple
          uniqueId={name}
        />
      </UploadWrapperDND>
    </div>
  )
}

export default DispatchRuleUpload