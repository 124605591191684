import React from 'react';
import PropTypes from 'prop-types';

const UploadWrapperDND = ({ customClass, onDrop, children }) => (
  <div
    className={customClass}
    data-testid="upload-dnd"
    onDragOver={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
    onDrop={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onDrop(e?.dataTransfer?.files);
    }}
  >
    {children}
  </div>
);

UploadWrapperDND.propTypes = {
  customClass: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

UploadWrapperDND.defaultProps = {
  customClass: 'md:col-span-3 sm:col-span-6',
};

export default UploadWrapperDND;
