import { AndroidStore, IOSStore } from 'components/icons';
import React from 'react'

const appStoreHref = "https://apps.apple.com/us/app/groundteq/id6445990603";
const googlePlayHref = "https://play.google.com/store/apps/details?id=com.groundteq.android";

const DownloadApp = () => {
  return (
    <div className="flex justify-center">
      <a href={googlePlayHref} target="_blank" rel="noreferrer">
        <AndroidStore className="w-40 h-10" />
      </a>
      <a href={appStoreHref} target="_blank" rel="noreferrer">
        <IOSStore className="w-40 h-10" />
      </a>
    </div>
  )
}

export default DownloadApp;