import { withAPI, withEcho } from 'hocs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { NotificationListBar } from '.';
import { useLoadNotifications } from '../hooks';

const NotificationIndicator = ({ echoClient, httpRequest, userId }) => {
  const { notifications, loadNotifications, setNotifications } = useLoadNotifications(httpRequest);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications])


  const loadAlerts = useCallback(async (data) => {
    if (data) {
      const alert = _.get(data, 'alert', {});
      if (!_.isEmpty(alert)) {
        setNotifications((prev) => [alert].concat(prev));
      }
    }
  }, [])

  useEffect(() => {
    if (userId) {
      echoClient
        .channel(`safety.alert.${userId}`)
        .listen('.safety.alert', loadAlerts);
  
      return () => {
        echoClient.leaveChannel(`safety.alert.${userId}`);
      };
    }
  }, [echoClient, loadAlerts, userId])

  return (
    <>
      <NotificationListBar
        open={isNotificationsOpen}
        setOpen={setIsNotificationsOpen}
        notifications={notifications}
        httpRequest={httpRequest}
        setNotifications={setNotifications}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsNotificationsOpen(true);
        }}
        style={{minWidth: '18px', minHeight: '18px'}}
        className="absolute right-4 py-0.5 px-1 top-1 bg-green-regular text-white rounded-full
        flex items-center justify-center leading-none"
      >
        {notifications?.length ?? 0}
      </div>
    </>
  )
}

export default _.flowRight([withEcho, withAPI])(NotificationIndicator);