import React from 'react';
import PropTypes from 'prop-types';
import BtnLoadingIndicator from '../loading-indicator/btn-loading';
import { isNotMobile } from 'utils';

const styles = 'inline-flex justify-center py-2 px-4 border'
  + ' border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600'
  + ' hover:bg-indigo-600 lg:hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2'
  + ' focus:ring-offset-2 h-max relative';

const DefaultButton = ({
  disabled, isLoading, label, onClick, customStyles,
  isTooltip, tooltipText, onMouseEnter, onMouseLeave,
}) => (
  <button
    disabled={disabled || isLoading}
    type="button"
    className={`${styles} ${customStyles} ${disabled ? 'opacity-50' : ''}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {isLoading && <BtnLoadingIndicator />}
    {label}
    {isNotMobile() && isTooltip && (
      <div
        className="text-xs absolute z-10 left-1/2 top-3 py-2 px-4 bg-gray-100
        border border-black-100 rounded-2xl text-left block text-gray-800
        font-medium transform -translate-x-1/2 translate-y-1/4 break-words w-full"
      >
        {tooltipText}
      </div>
    )}
  </button>
);

DefaultButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  customStyles: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

DefaultButton.defaultProps = {
  disabled: false,
  isLoading: false,
  customStyles: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default DefaultButton;
