import React from 'react';
import PropTypes from 'prop-types';

const Th = ({ title, customWidth, className = "px-2 py-3 text-left text-xs font-bold text-gray-500 tracking-wider", ...props }) => {
  const styles = { width: `${customWidth}px` };
  return (
    <th
      scope="col"
      style={customWidth ? styles : {}}
      className={className}
      {...props}
    >
      {title}
    </th>
  );
}

Th.propTypes = {
  title: PropTypes.string.isRequired,
  customWidth: PropTypes.number,
};

export default Th;
