import { getFormattedDate } from 'components/dateHandle';
import DotsDropdown from 'components/dots-dropdown';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { SubTableDropdown, Thead } from 'components/table'
import { withAPI } from 'hocs';
import _ from 'lodash';
import React from 'react'
import { useState } from 'react'

const columnList = ['Name', 'Description', 'Date']

const DVIRQuestionDropDown = ({ categoryId, httpRequest, menuItems }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadDVIRItems = async () => {
    if (categoryId && !isLoading && data.length === 0) {
      setIsLoading(true)
      const response = await httpRequest({
        method: 'get',
        url: `/dvir-items?category_id=${categoryId}`,
      }).finally(() => setIsLoading(false))

      if (response) {
        const data = _.get(response, 'data.data', []);
        setData(data);
      }
    }
  }

  return (
    <SubTableDropdown id={categoryId} onExtend={loadDVIRItems}>
      <PageLoadingIndicator loading={isLoading} />
      <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
        {data?.length > 0 && (
          <Thead columnList={columnList} />
        )}
        <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
          {data?.length > 0 && data.map((item) => (
            <tr key={item?.id} className="text-sm">
              <td className="pl-2 py-3 text-xs">{item?.name}</td>
              <td className="pl-2 py-3 text-xs">{item?.description}</td>
              <td className="pl-2 py-3 text-xs">{getFormattedDate(item?.created_at)}</td>
              {item?.is_custom ? (
                <td className="text-right pl-2 py-3 text-xs">
                  <DotsDropdown
                    id={item}
                    menuItems={menuItems}
                  />
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </SubTableDropdown>
  )
}

export default _.flowRight([withAPI])(DVIRQuestionDropDown);