import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  ChevronLeftIcon, ChevronRightIcon,
} from '@heroicons/react/solid';
import RegularDropdown from '../regular-dropdown';

const API_PER_PAGE = 15;

export function getCurrentPageNumber({
  match,
}) {
  const { params: { page } } = match;
  return !page ? 1 : _.toNumber(page);
}

const parsePageFrom = (url) => {
  if (url === null) return 1;
  const parsedQS = new URL(url).searchParams;
  return _.toNumber(parsedQS.get('page'));
};

const Pagination = ({
  onChange,
  totalPages,
  totalRecords,
  perPage,
  from,
  to,
  currentPage,
  paginationLinks,
  noPaginationLinks,
  perPageList,
  labelsList,
  setPerPage,
  isBordered,
  isShortPageView,
}) => {
  const [selectedPerPage, setSelectedPerPage] = useState(perPageList.filter((i) => i.default)[0]?.id);

  const shouldRender = (perPage ? totalRecords > perPage : totalRecords > API_PER_PAGE) || perPageList?.length;
  const canRender = (totalPages >= currentPage);
  if (!shouldRender || !canRender) return null;
  const prevClasses = 'relative inline-flex items-center px-2 h-full rounded-l-md border border-gray-300 bg-white '
  + 'text-sm font-medium text-gray-500 hover:bg-gray-50';
  const regularClasses = 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex '
  + 'relative items-center px-4 py-2 border text-sm font-medium';
  const regularActiveClasses = 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative '
  + 'inline-flex items-center px-4 py-2 border text-sm font-medium';
  const nextClasses = 'relative inline-flex items-center px-2 h-full rounded-r-md border border-gray-300 '
  + 'bg-white text-sm font-medium text-gray-500 hover:bg-gray-50';

  return (
    <>

      <div className={`bg-white px-4 py-3 flex items-center
        justify-between ${isBordered ? 'border-t' : ''} border-gray-200 sm:px-6`}
      >
        <div className="flex-1 flex justify-between sm:hidden">
          {totalPages > 1 && (
            <>
              <button
                type="button"
                disabled={currentPage <= 1}
                onClick={() => onChange(currentPage - 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium
                rounded-md text-${currentPage <= 1 ? 'gray' : 'green'}-700 bg-white hover:bg-gray-50`}
              >
                Previous
              </button>
              <button
                type="button"
                disabled={currentPage >= totalPages}
                onClick={() => onChange(currentPage + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium
                rounded-md text-${currentPage >= totalPages ? 'gray' : 'green'}-700 bg-white hover:bg-gray-50 ml-3`}
              >
                Next
              </button>
            </>
          )}
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalRecords !== 1 && (
              <p className="text-sm text-gray-700 font-medium whitespace-nowrap mr-2">
                {from}
                {' '}
                to
                {' '}
                {to}
                {' '}
                of
                {' '}
                {totalRecords}
                {' '}
                results
              </p>
            )}
          </div>
          <div className="flex gap-5">
            {perPageList?.length > 0 && (
              <div className="flex gap-2 items-center">
                <p className="text-sm text-gray-700 whitespace-nowrap">Rows per page:</p>
                <RegularDropdown
                  items={perPageList}
                  onChange={(val) => {
                    setSelectedPerPage(val);
                    setPerPage(val);
                  }}
                  selectedValue={selectedPerPage}
                  isForcedBottom={false}
                />
              </div>
            )}
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              {noPaginationLinks && totalPages > 1 && (
                <>
                  {totalPages > 1 && (
                    <span>
                      <button
                        disabled={currentPage <= 1}
                        onClick={() => onChange(currentPage - 1)}
                        type="button"
                        className={prevClasses}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </span>
                  )}
                  {labelsList?.length > 0
                    && (
                      labelsList.map((label) => (
                        <span>
                          <button
                            onClick={() => { if (label !== currentPage && label !== '...') onChange(label); }}
                            type="button"
                            className={label === currentPage ? regularActiveClasses : regularClasses}
                          >
                            {label}
                          </button>
                        </span>
                      ))
                    )}
                    {labelsList?.length === 0 && !isShortPageView && (
                      Array(totalPages)?.fill(totalPages)?.map((item, index) => (
                        <span>
                          <button
                            onClick={() => { if (index !== currentPage - 1) onChange(index + 1); }}
                            type="button"
                            className={index === currentPage - 1 ? regularActiveClasses : regularClasses}
                          >
                            {index + 1}
                          </button>
                        </span>
                      ))
                    )}
                    {totalPages > 0 && isShortPageView && (
                      <span>
                        <button
                          type="button"
                          className={regularActiveClasses}
                        >
                          {currentPage}
                        </button>
                      </span>
                    )}
                  {totalPages > 1 && (
                    <span>
                      <button
                        disabled={currentPage >= totalPages}
                        onClick={() => onChange(currentPage + 1)}
                        type="button"
                        className={nextClasses}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </span>
                  )}
                </>
              )}
              {selectedPerPage !== '' && paginationLinks.map((link, index) => (
                <span key={link.label}>
                  {
                    (index === 0 && (
                    <button
                      onClick={() => (link.url !== null ? onChange(parsePageFrom(link.url)) : null)}
                      type="button"
                      className={prevClasses}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    )) || (index === paginationLinks.length - 1 && (
                    <button
                      onClick={() => (link.url !== null ? onChange(parsePageFrom(link.url)) : null)}
                      type="button"
                      className={nextClasses}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    )) || (
                    <button
                      onClick={() => (link.url !== null ? onChange(parsePageFrom(link.url)) : null)}
                      type="button"
                      className={link.active ? regularActiveClasses : regularClasses}
                    >
                      {link.label}
                    </button>
                    )
                  }
                </span>
              ))}
            </nav>
          </div>
        </div>
      </div>

    </>
  );
};

Pagination.propTypes = {
  onChange: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
  perPage: PropTypes.number,
  from: PropTypes.number,
  to: PropTypes.number,
  currentPage: PropTypes.number,
  paginationLinks: PropTypes.array,
  noPaginationLinks: PropTypes.bool,
  perPageList: PropTypes.array,
  labelsList: PropTypes.array,
  setPerPage: PropTypes.func,
  isBordered: PropTypes.bool,
  isShortPageView: PropTypes.bool,
};

Pagination.defaultProps = {
  totalPages: 1,
  totalRecords: 1,
  perPage: API_PER_PAGE,
  from: 1,
  to: 1,
  currentPage: 1,
  paginationLinks: [],
  noPaginationLinks: false,
  perPageList: [],
  labelsList: [],
  setPerPage: () => {},
  onChange: () => {},
  isBordered: true,
  isShortPageView: false,
};

export default compose(
)(Pagination);
