import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SubTableDropdown = ({ id, onExtend, onCollapse, children, labelStyle, isOpen = false }) => {
  const [checked, setChecked] = useState(isOpen);

  const onCheck = () => {
    setChecked((prev) => {
      prev ? onCollapse() : onExtend();
      return (!prev)
    })
  }

  return (
    <tr className="sub-table-level w-full">
      <td colSpan="20" className="p-0">
        <div className="accordion flex flex-col justify-center">
          <input
            type="checkbox"
            name="panel"
            id={`panel-${id}`}
            className="hidden"
            checked={checked}
            onChange={onCheck}
          />
          <label
            htmlFor={`panel-${id}`}
            className={`table-accordion-arrow relative block w-3/4 ${checked ? 'top-4 left-0.5' : 'top-1.5'}`}
            style={labelStyle}
          >
            &nbsp;
          </label>
          <div className="accordion__content">
            <div className="accordion__body relative">
              {checked && (children)}
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
};

SubTableDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  onExtend: PropTypes.func,
  onCollapse: PropTypes.func,
};

SubTableDropdown.defaultProps = {
  onExtend: () => {},
  onCollapse: () => {},
};

export default SubTableDropdown;
