import _ from "lodash";
import { useCallback, useState } from "react";
import queryString from 'query-string';
import { useCancelToken } from "hooks";

export const orgDestField = {
  org: 'leg_org',
  dest: 'leg_dest',
}

const useLoadOrgDest = (httpRequest) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setOriginList] = useState([]);
  const [, setDestinationList] = useState([]);
  const { getCancelToken } = useCancelToken();

  const loadList = useCallback(async (search, field) => {
    setIsLoading(true);

    const query = queryString.stringify({ search, field });

    const response = await httpRequest({
      method: 'get',
      url: `/trips/org-dest?${query}`,
      cancelToken: getCancelToken(),
    }).finally(() => setIsLoading(false));
    if (response) {
      const items = _.get(response, 'data.data', []);
      const formattedList = items?.map((i) => ({ value: i, label: i }));
      field === orgDestField.org ? setOriginList(formattedList) : setDestinationList(formattedList);
      return formattedList;
    }
    return [];
  }, [httpRequest, getCancelToken])

  return ({ isLoading, loadList, setOriginList, setDestinationList });
}

export default useLoadOrgDest;