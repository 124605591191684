import React from 'react';

import PropTypes from 'prop-types';

import { CheckCircleIcon } from '@heroicons/react/solid';

const SuccessMessage = ({ message, addClass }) => (
  <div className={`rounded-md bg-green-50 p-4 mt-4 ${addClass}`} data-testid="success-message">
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-green-800">
          {message}
        </h3>
      </div>
    </div>
  </div>
);

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
  addClass: PropTypes.string,
};

SuccessMessage.defaultProps = {
  addClass: '',
};

export default SuccessMessage;
