import { FormLabel } from 'modules/dispatches/modalCreateDispatch';
import React from 'react'

const FormInputWrapper = React.forwardRef(({
  label = '',
  isRequired = false,
  colspan = 3,
  className = 'mt-1',
  children,
  ...props
}, ref) => {
  return (
    <div ref={ref} className={`col-span-${colspan}`} data-testid="form-input-wrapper">
       <FormLabel label={label} isRequired={isRequired} />
        <div className={className} {...props}>
          {children}
        </div>
    </div>
  )
});

export default FormInputWrapper;