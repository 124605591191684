import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";
import qs from 'query-string';

const useLoadPayrollDates = (httpRequest, { driverId } = {}) => {
  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadPayrollDates = useCallback(async () => {
    setIsLoading(true);

    const query = qs.stringify({
      driver_id: driverId,
    }, { skipEmptyString: true, skipNull: true });

    const response = await httpRequest({
      method: 'get',
      url: `payrolls/dates?${query}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      setIsLoading(false);
      setDates(data);
    }
  }, [httpRequest, getCancelToken, driverId])

  return ({ dates, isLoading, loadPayrollDates });
}

export default useLoadPayrollDates