import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";

const useLoadPayrollAnalysis = (httpRequest, { driverId, payrollDate }) => {
  const [payrollAnalysis, setPayrollAnalysis] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getCancelToken } = useCancelToken()

  const loadPayrollAnalysis = useCallback(async () => {
    if (!driverId || !payrollDate) return;
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `payrolls/driver-analysis/driver?driver_id=${driverId}&date=${payrollDate}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error));
    });

    if (response) {
      const data = _.get(response, 'data.data', []);
      const {
        dates, payroll, trips, spot_trips_pay_as_linehaul = false,
      } = data;

      const { linehaul_trips, spot_trips, total_trips } = trips || {};
      const { linehaul, spot } = total_trips || {};
      
      setPayrollAnalysis({
        dates,
        payroll,
        linehaul_trips,
        totalLinehaul: linehaul,
        spot_trips,
        totalSpot: spot,
        spot_trips_pay_as_linehaul
      })
      setIsLoading(false);
    }
  }, [httpRequest, getCancelToken, driverId, payrollDate])

  return ({ payrollAnalysis, isLoading, loadPayrollAnalysis });
}

export default useLoadPayrollAnalysis