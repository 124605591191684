import { PlayIcon } from '@heroicons/react/outline';
import React, { useState, useRef } from 'react'

const VideoPlayer = ({ className = "w-full h-full rounded-xl object-fit", src }) => {
  const videoRef = useRef(null);
  const [isStoped, setIsStoped] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const playVideo = () => {
    videoRef.current.play();
    setIsStoped(false);
  }

  return (
    <>
      {isStoped && (
        <PlayIcon
          onClick={playVideo}
          className="w-10 h-10 absolute transform -translate-x-1/2 -translate-y-1/2
          top-1/2 left-1/2 text-white hover:text-opacity-60 transition-all
          hover:scale-125 bg-black-800 bg-opacity-50 z-40"
        />
      )}
      <video
        style={{ maxHeight: '40vh', minHeight: '40vh' }}
        ref={videoRef}
        onPlay={() => setIsStoped(false)}
        onPause={() => setIsStoped(true)}
        onClick={() => { if (!isDataLoaded) playVideo() }}
        onLoadedData={() => setIsDataLoaded(true)}
        controls
        preload="none"
        poster="/assets/logo-company.png"
        className={className}
      >
        <source src={src} type="video/mp4" />
      </video>
    </>
  );
}

export default VideoPlayer