import axios from "axios";
import { useCancelToken } from "hooks";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

const useLoadTruckList = (httpRequest, truckNo = "") => {
  const [data, setData] = useState([]);
  const { getCancelToken } = useCancelToken();
  const [isLoading, setIsLoading] = useState(false);

  const loadTruckList = useCallback(async (search) => {
    if ((!search || search?.length < 3) && (!truckNo || truckNo?.length < 3)) return;

    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/trucks/numbers?search=${search || truckNo}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error))
    });

    if (response) {
      const items = _.get(response, 'data.data', []);
      setData(items);
      setIsLoading(false);
      return items;
    }
    return [];

  }, [getCancelToken, truckNo, httpRequest]);
  
  useEffect(() => {
    loadTruckList();
  }, [loadTruckList])
  
  return ({ loadData: loadTruckList, data, isLoading, setData });
}

export default useLoadTruckList;