import { useCallback, useState } from 'react';
import qs from 'qs';
import _ from 'lodash';

const useLoadOwnLocations = (
  httpRequest,
    {
      teamType,
      companies,
    },
  ) => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadLocations = useCallback(async () => {
    setIsLoading(true);

    const query = qs.stringify({
      in_my_companies: 1,
      team_type: teamType,
      with_own_drivers: 1,
      status: 'ACTIVE',
      companies: companies?.map((item) => item?.value),
    }, { arrayFormat: 'index', skipEmptyString: true, skipNull: true })

    const response = await httpRequest({
      method: 'get',
      url: `hubs?${query}`,
    }).finally(() => setIsLoading(false));
    if (response) {
      const data = _.get(response, 'data.data', []);
      const concatLocations = [{ value: '', label: 'All Locations' }]
        .concat(data?.map((i) => ({ value: i.id, label: i.name })));
        setLocations(concatLocations);
        return concatLocations;
    }

    
  }, [teamType, companies, httpRequest])

  return ({ isLoading, locations, loadLocations });

}

export default useLoadOwnLocations;