const selectorStyles = {
  multiValue: (provided) => ({
    ...provided,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start !important',
    minWidth: 'max-content',
    maxWidth: 'max-content',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderRadius: '4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '0px 5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start !important',
  }),
  input: (provided) => ({
    ...provided,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'none',
    borderWidth: '0px',
    boxShadow: 0,
    '&:hover': {
      borderColor: 0,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    color: '#767676',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    color: '#767676',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '5px',
    borderWidth: '0px',
    backgroundColor: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'max-content',
    right: 0,
    padding: '20px',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '',
  }),
};

export const defaultStyles = {
  multiValue: (provided) => ({
    ...provided,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start !important',
    minWidth: 'max-content',
    maxWidth: 'max-content',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderRadius: '4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '0px 5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start !important',
  }),
  input: (provided) => ({
    ...provided,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'none',
    borderWidth: '0px',
    boxShadow: 0,
    '&:hover': {
      borderColor: 0,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 'max-content',
    maxWidth: '400px',
    right: 0,
    padding: '20px',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '5px',
    borderWidth: '0px',
    backgroundColor: 'white',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    color: '#767676',
    padding: '4px',
    marginRight: '6px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    color: '#767676',
    padding: '4px',
  }),
};

export default selectorStyles;