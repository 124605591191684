import React, { useEffect } from 'react'

const RTynna = ({ section, item, onChange, value = null, wrapperClassName = "col-span-6 justify-between grid grid-cols-6 my-3 items-end" }) => {

  const options = ['Y', 'N', 'NA'];

  const [plainValue, setPlainValue] = React.useState(value)

  useEffect(() => {
      onChange(section.id, item.id, plainValue)
  }, [plainValue, item.id, onChange, section.id])

  useEffect(() => {
    setPlainValue(value);
  }, [value]);
  
  return (
    <div className={wrapperClassName}>
      <p className='col-span-5 border-b mr-4 whitespace-pre-line'>{item.question}</p>
      <div className='col-span-1 grid grid-cols-3'>
        {options.map((option, index) => (
          <div key={section.id + '-' + item.id + '-' + index} className="flex items-center">
            <label
              htmlFor={section.id + '-' + item.id + '-' + index}
              className="block text-sm font-medium text-gray-700"
            >
              <input
                checked={option === plainValue}
                name={section.id + '-' + item.id + '-' + index}
                id={section.id + '-' + item.id + '-' + index}
                type="radio"
                onChange={(e) => setPlainValue(option)}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mb-1" />
              <span className="ml-1 text-sm md:absolute">{option}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RTynna