import React from 'react';
import PropTypes from 'prop-types';
import { DownloadIcon } from '@heroicons/react/outline';
import { BtnLoadingIndicator } from 'components/loading-indicator';

const ExportButton = ({ onClick, label, isLoading,}) => (
  <button
    onClick={onClick}
    type="button"
    disabled={isLoading}
    className="inline-flex items-center px-4 py-2 border border-gray-300
          shadow-sm text-sm font-medium rounded-md text-green-regular bg-white
          hover:bg-gray-50 focus:outline-none w-full md:w-max justify-center"
  >
    {isLoading && <BtnLoadingIndicator />}
    <DownloadIcon className="h-5 w-5 mr-1 text-green-regular" aria-hidden="true" />
    {' '}
    {label}
  </button>
);

ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
};

ExportButton.defaultProps = {
  label: 'Export',
  isLoading: false,
};

export default ExportButton;
