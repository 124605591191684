import axios from "axios";
import { useCancelToken, useDownloadFile } from "hooks";
import _ from "lodash";
import { useCallback, useState } from "react";
import { formatPaginationMeta } from "utils";
import queryString from 'query-string';

const useLoadTruckChecks = (
  httpRequest,
    {
      page = 1,
      type = '',
      status = '',
      search,
      companies,
      callback = null,
      teamType = null,
      locations,
      from = null,
      to = null,
    }
  ) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const { getCancelToken } = useCancelToken();
  const { downloadFile } = useDownloadFile(httpRequest);

  const loadTruckChecks = useCallback(async (withExport = false) => {
    setIsLoading(true);
    const query = queryString.stringify({
      page,
      companies: companies?.map((item) => item?.value),
      search,
      type,
      status,
      team_type: teamType,
      hub_id: locations?.map((item) => item?.value),
      from,
      to,
      export: Number(withExport),
    }, { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' });

    if (withExport) {
      await downloadFile({
        url: `/truck-checks?${query}`,
        fileName: 'DVIR',
      }).finally(() => {
        setIsLoading(false);
      })
    } else {
      const response = await httpRequest({
        method: 'get',
        url: `/truck-checks?${query}`,
        cancelToken: getCancelToken(),
      }).catch((error) => {
        setIsLoading(axios.isCancel(error));
      });
      if (response) {
        const data = _.get(response, 'data.data', []);
        const pagination = _.get(response, 'data.meta', {});
        setIsLoading(false);
        setData(data);
        setPagination(formatPaginationMeta(pagination, true));
        callback(data);
      }
    }
  // eslint-disable-next-line
  }, [httpRequest, page, getCancelToken, companies, search, type, status, teamType, locations, from, to])

  return ({ data, isLoading, loadTruckChecks, pagination });
}

export default useLoadTruckChecks;