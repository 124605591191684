import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const PageLink = ({ label, to, children, ...props }) => {
  return (
    <Link
      to={to}
      className="inline-flex items-center px-4 py-2 border border-transparent
      shadow-sm text-sm font-medium rounded-md text-white bg-green-regular
      hover:bg-green-hover focus:outline-none
      justify-center"
      {...props}
    >
      {children ?? label}
    </Link>
  )
}

export default PageLink