import { PageLoadingIndicator } from 'components/loading-indicator';
import ModalFilePreview from 'components/modal/file-preview';
import ModalWrapper from 'components/modal/modal-wrapper';
import { ValidationsErrors } from 'components/notifications';
import { withAPI } from 'hocs';
import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import { useLoadFAQ } from '../hooks';
import qs from 'query-string';
import { createIframe, isVideoExt } from 'utils';
import { VideoPlayer } from 'components/video-player';

const FAQDetailModal = ({ open, setOpen, faqId, httpRequest }) => {
  const { isLoading, errors, data } = useLoadFAQ(httpRequest, faqId);
  const [filePreviewOpen, setFilePreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const descriptionsWithoutLinks = useMemo(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(data?.description, 'text/html');
    const linkList = [...htmlDoc.getElementsByTagName('a')];
    linkList?.forEach(item => {
      const videoQuery = qs.parseUrl(item?.href)?.query?.v;
      const url = qs.parseUrl(item?.href)?.url;
      if (videoQuery && url?.includes('youtube')) {
        const iframe = createIframe({ className: "my-2 rounded-xl", src: `https://www.youtube.com/embed/${videoQuery}` });
        // replace youtube link to the iframe
        item?.after(iframe);
        if (item?.innerHTML?.includes('https://www.youtube.com/')) {
          item?.remove();
        }
      }
    });
    return new XMLSerializer().serializeToString(htmlDoc)
  }, [data])

  return (
    <ModalWrapper open={open} setOpen={setOpen} title={data?.title ?? ""}>
      {!_.isEmpty(errors) && (
        <ValidationsErrors errors={errors} />
      )}
      {filePreviewOpen && (
        <ModalFilePreview
          open={filePreviewOpen}
          setOpen={setFilePreviewOpen}
          url={selectedFile?.url}
          withNavigationButtons={data?.files?.length > 1}
          withFilesPreview
          files={data?.files}
        />
      )}
      <PageLoadingIndicator loading={isLoading} />
      {!isLoading && (
        <div className="my-4 wysiwyg_html"  dangerouslySetInnerHTML={{ __html: descriptionsWithoutLinks }} />
      )}
      <div className="flex gap-4 mt-2 flex-col">
        {data?.files?.map((item) => (
          <button
            type="button"
            onClick={() => { if (!isVideoExt(item.path)) {setSelectedFile(item); setFilePreviewOpen(true)}}}
            className="w-full h-full relative"
            key={item?.url}
          >
            {isVideoExt(item?.path) ? (
              <VideoPlayer src={item?.url} />
            ) : (<img style={{ maxHeight: '500px' }} className="w-full rounded-xl object-contain" src={item?.url} alt="FAQ" />)}
          </button>
        ))}
      </div>
    </ModalWrapper>
  )
}

export default _.flowRight([withAPI])(FAQDetailModal);