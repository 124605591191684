import { PageLoadingIndicator } from 'components/loading-indicator';
import { SubTableDropdown } from 'components/table'
import { withAPI } from 'hocs';
import _ from 'lodash';
import FormCheckBtn from 'modules/companies/form-components/form-check-btn';
import React from 'react'
import { useState } from 'react'

const DVIRRulesDropdown = ({ categoryId, httpRequest, rules, onChangeRules, action = 'View' }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadDVIRItems = async () => {
    if (categoryId && !isLoading && data.length === 0) {
      setIsLoading(true)
      const response = await httpRequest({
        method: 'get',
        url: `/dvir-items?category_id=${categoryId}`,
      }).finally(() => setIsLoading(false))

      if (response) {
        const data = _.get(response, 'data.data', []);
        setData(data);
      }
    }
  }

  const isMandatory = (item, rules) => {
    if (_.isArray(rules) && rules.length > 0) {
      const rule = rules.find((i) => +i?.dvir_item_id === +item?.id);
      return rule?.required;
    }
    return false;
  }

  return (
    <SubTableDropdown id={categoryId} onExtend={loadDVIRItems}>
      <PageLoadingIndicator loading={isLoading} />
      <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
        {data?.length > 0 && (
          <thead>
            <tr>
              <th className="text-left pl-2 text-xs font-bold text-gray-500 tracking-wider">
                Name
              </th>
              <th className="text-center text-xs font-bold text-gray-500 tracking-wider">
                Mandatory
              </th>
            </tr>
          </thead>
        )}
        <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
          {data?.length > 0 && data.map((item) => (
            <tr key={item?.id} className="text-sm">
              <td className="py-1 pl-2 text-xs">{item?.name}</td>
              <td className="text-xs text-center">
                <button type="button" onClick={() => onChangeRules(item)} disabled={action === 'View'}>
                  <FormCheckBtn color={isMandatory(item, rules) ? 'green' : 'gray'} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </SubTableDropdown>
  )
}

export default _.flowRight([withAPI])(DVIRRulesDropdown);