import { DefaultButton } from 'components/buttons';
import { FormHandlersWrapper } from 'components/form';
import ModalWrapper from 'components/modal/modal-wrapper';
import { ValidationsErrors } from 'components/notifications';
import { withAPI } from 'hocs';
import useLoadTruckList from 'hooks/api/useLoadTruckList';
import _ from 'lodash';
import { useLoadDispatches } from 'modules/dispatches/hooks';
import { useLoadDVIRTruckChecks } from 'modules/dvir/hooks';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { scrollToTop } from 'utils';
import { DVIRActions } from '../dvir';
import { FirstStep, SecondStep } from '../dvir-create-steps';
import PropTypes from 'prop-types';

const getSectionNames = (sections, separator = ', ') => sections?.map((item) => item.name).join(separator);

export const DVIRValidation = {
  isPhotoEmpty: (photos) => !photos || photos?.length === 0,
  isInvalidPreTrip: function({ action, mandatory, photos }) {
    return action === DVIRActions.preTrip && mandatory ? this.isPhotoEmpty(photos) : false;
  },
  isInvalidTruckIssues: function({ action, notes, photos }) {
    return action === DVIRActions.truckIssues ? (notes?.length === 0 || this.isPhotoEmpty(photos)) : false;
  },
  isInvalidPrePostTrip: function({ action, passOrFail, notes, photos }) {
    return action !== DVIRActions.truckIssues ? (passOrFail === 'fail' && (notes?.length === 0 || this.isPhotoEmpty(photos))) : false;
  }
}

const DVIRCreate = ({ open, setOpen, httpRequest, action, meta, refreshData }) => {
  const modalRef = useRef(null);
  const { dispatches, isLoading: isDispatchesLoading } = useLoadDispatches(httpRequest);
  const { setData: setTruckList, loadData: loadTruckList } = useLoadTruckList(httpRequest);
  const { data: DVIRRules } = useLoadDVIRTruckChecks(httpRequest, action);

  const [truckNumber, setTruckNumber] = useState('');
  const [selectedDVIRItem, setSelectedDVIRItem] = useState({});
  const [passedItems, setPassedItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const actionMetaKey = useMemo(() => {
    if (!_.isEmpty(meta)) {
      const metaKey = Object.keys(meta)?.find((key) => meta?.[key]?.name === action);
      return metaKey ? metaKey : "";
    }
    return "";
  }, [action, meta])

  useEffect(() => {
    if (dispatches?.length > 0) {
      const { truck_no } = dispatches[0];
      setTruckNumber(truck_no);
    }
  }, [dispatches])

  const onSelectPassedItems = (subItem, photos = null, notes = "", passOrFail = "") => {
    setPassedItems((prev) => {
      const passedItems = _.cloneDeep(prev);
      const currentItemIndex = passedItems?.findIndex((item) => item?.id === subItem?.id);

      if (currentItemIndex !== -1) {
        passedItems[currentItemIndex] = ({ ...subItem, photos, notes, passOrFail });
        return passedItems;
      }

      return [...passedItems, {...subItem, photos, notes, passOrFail}];
    })
  }

  const getListFailItemsWithoutNotesFile = () => {
    const listItemWithoutNotesFile = [];
    passedItems?.forEach((item) => {
      const { passOrFail, notes, photos } = item;
      if (
        DVIRValidation.isInvalidPrePostTrip({ action, passOrFail, notes, photos }) ||
        DVIRValidation.isInvalidTruckIssues({ action, notes, photos })
      ) {
        listItemWithoutNotesFile.push(item);
      }
    })
    return listItemWithoutNotesFile;
  }

  const getListMandatoryWithoutPhoto = () => {
    if (action === DVIRActions.preTrip) {
      const list = [];
      passedItems?.forEach((item) => {
        const { mandatory, photos } = item;
        if (DVIRValidation.isInvalidPreTrip({ action, mandatory, photos })) {
          list.push(item);
        }
      })
      return list;
    }
    return [];
  }

  const onClickNextButton = () => {
    setErrors({});
    const listFailItems = getListFailItemsWithoutNotesFile();
    if (listFailItems.length > 0) {
      setErrors({ message: `Please attach a photo and leave a note for (${getSectionNames(listFailItems)}).` })
      scrollToTop(modalRef);
      return;
    }
    const listMandatoryWithoutPhoto = getListMandatoryWithoutPhoto();
    if (listMandatoryWithoutPhoto.length > 0) {
      setErrors({ message: `Please attach a photo for (${getSectionNames(listMandatoryWithoutPhoto)}).` })
      scrollToTop(modalRef);
      return;
    }

    if (!selectedDVIRItem?.isLastItem) {
      const nextIndex = selectedDVIRItem?.itemIndex + 1;
      const nextItem = DVIRRules?.[nextIndex];
      if (nextItem && !_.isEmpty(nextItem)) {
        scrollToTop(modalRef)
        setSelectedDVIRItem({
          ...nextItem,
          itemIndex: nextIndex,
          isLastItem: nextIndex + 1 === DVIRRules?.length,
        })
      }
    } else {
      setSelectedDVIRItem({});
    }
  }

  const setFormData = ({ latitude, longitude }) => {
    const formData = new FormData();
    formData.append('type', actionMetaKey);
    formData.append('truck_no', truckNumber);
    if (latitude && longitude) {
      formData.append('lat', latitude);
      formData.append('lng', longitude);
    }
    passedItems?.forEach((passedItem, index) => {
      formData.append(`dvir[${index}][item_id]`, passedItem?.id);
      formData.append(`dvir[${index}][pass]`, +(passedItem?.passOrFail === 'pass'));
      formData.append(`dvir[${index}][notes]`, passedItem?.notes);
      if (_.isArray(passedItem?.photos)) {
        passedItem?.photos?.forEach((photo) => {
          formData.append(`dvir[${index}][photos][]`, photo);
        })
      }
    })
    return formData;
  }

  const isValid = () => {

    const errors = [];

    const totalRulesItems = DVIRRules?.reduce((curr, item) => curr + item?.items?.length , 0);
    if (totalRulesItems !== passedItems?.length && (action !== DVIRActions.truckIssues)) {
      errors.push('Please complete all fields.');
    }

    const emptyRequiredFields = passedItems
      ?.filter((item) => item?.mandatory && (item?.photos?.length === 0 || !item?.photos));

    if (emptyRequiredFields?.length > 0 && DVIRActions.preTrip === action) {
      const fields = emptyRequiredFields.map((item) => item?.name);
      errors.push(`Please complete mandatory fields. (${fields?.join(', ')})`);
    }

    if (action === DVIRActions.truckIssues) {
      if (passedItems?.length === 0) {
        errors.push('Please select at least one field');
      }
    }

    const listFailItems = getListFailItemsWithoutNotesFile();
    if (listFailItems.length > 0) {
      errors.push(`Please attach a photo and leave a note for (${getSectionNames(listFailItems)}).`)
    }

    if (errors.length > 0) {
      const messages = {};
      errors.forEach((item, index) => {
        messages[index] = item;
      })
      setErrors({ errors: messages });
      return false;
    }

    return true;
  }

  const getCoordinate = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition((success, error) => {
        if (success) {
          const coords = success.coords;
          onSave(coords);
        };
        if (error) {
          const message = _.get(error, 'positionError.message', '') ;
          if (message) setErrors({ message })
          setIsLoading(false);
        }
      }, () => {
        onSave({});
      }, { timeout: 10000, enableHighAccuracy: true });
    }
  }

  const onSave = async (coords) => {

    setErrors({});

    if (!isValid()) {
      scrollToTop(modalRef);
      setIsLoading(false);
      return;
    }
    const formData = setFormData(coords);
    setIsLoading(true);

    const response = await httpRequest({
      url: '/truck-checks',
      method: 'post',
      data: formData,
    })
      .catch((error) => {
        scrollToTop(modalRef);
        setErrors(error?.response?.data);
      })
      .finally(() => setIsLoading(false));
    
    if (response) {
      await refreshData();
      setOpen(false);
    }
  }

  const onClickSelectAll = () => {
    const passedItems = [];

    DVIRRules?.forEach((item) => {
      item?.items?.forEach((subItem) => {
        passedItems.push({ ...subItem, notes: "", photos: [], passOrFail: "pass" });
      })
    });
    setPassedItems(passedItems);
  }

  const onClickBackButton = () => {
    setSelectedDVIRItem({});
    setErrors({});
    const filteredPassedItems = passedItems
    // Filtering for non-empty failures
      ?.filter((item) => !(item?.passOrFail === 'fail' && item?.notes?.length === 0 && (!item?.photos || item?.photos?.length === 0)));
    setPassedItems(filteredPassedItems);
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={_.isEmpty(selectedDVIRItem) ? action : selectedDVIRItem?.name}
      ref={modalRef}
    >
      {!_.isEmpty(errors) && (
        <ValidationsErrors errors={errors} />
      )}
      {_.isEmpty(selectedDVIRItem) && (
        <FirstStep
          truckNumber={truckNumber}
          setTruckList={setTruckList}
          loadTruckList={loadTruckList}
          setTruckNumber={setTruckNumber}
          DVIRRules={DVIRRules}
          onSelectDVIRItem={setSelectedDVIRItem}
          passedItems={passedItems}
        />
      )}
      {!_.isEmpty(selectedDVIRItem) && (
        <SecondStep selectedItem={selectedDVIRItem} onSelectSubItem={onSelectPassedItems} passedItems={passedItems} action={action} />
      )}
      <FormHandlersWrapper className="mt-2">
        {!_.isEmpty(selectedDVIRItem) && (
          <>
            <DefaultButton label="Back" onClick={onClickBackButton} />
            <DefaultButton label="Next" onClick={onClickNextButton} />
          </>
        )}
        {_.isEmpty(selectedDVIRItem) && (
          <>
            {action === DVIRActions.postTrip && (
              <DefaultButton label="Select all as passed" onClick={onClickSelectAll} isLoading={isDispatchesLoading || isLoading} />
            )}
            <DefaultButton label="Save" onClick={getCoordinate} isLoading={isDispatchesLoading || isLoading} />
          </>
        )}
      </FormHandlersWrapper>
    </ModalWrapper>
  )
}

DVIRCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  meta: PropTypes.array,
}

DVIRCreate.defaultProps = {
  meta: [],
}

export default _.flowRight([withAPI])(DVIRCreate);