import React from 'react';
import PropTypes from 'prop-types';

const PageWrapperDataFilter = ({ children }) => {
  return (
  React.Children.toArray(children).length > 0 ?
    <div
      className="border border-black-100 bg-black-200 rounded-xl lg:mb-6 px-2 py-2
          block md:flex sm:items-center justify-between"
    >
      <div className="flex flex-1 justify-center sm:justify-end
        flex-row flex-wrap items-center gap-2"
      >
        {children}
      </div>
    </div>
  : null
)};

PageWrapperDataFilter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default PageWrapperDataFilter;
